<template>
  <div>
    <DemoStars />
    <div>
      <h1
        class="text-h5 moinblue--text mb-4"
        v-html="$t(`demo.${step}.loading.title`)"
      />
      <div v-html="$t(`demo.${step}.loading.body`)"></div>
    </div>
    <DemoCompanyUrl />
  </div>
</template>
<script>
import DemoStars from '@/components/common/DemoStars.vue';
import DemoCompanyUrl from '@/components/demo/components/DemoCompanyUrl.vue';
import { mapGetters, mapMutations } from 'vuex';
import { stepSlugs } from '@/components/demo/stepSlugs';
export default {
  name: 'DemoStepLoad',
  components: {
    DemoStars,
    DemoCompanyUrl,
  },
  computed: {
    ...mapGetters('demo', ['loading', 'step', 'questions', 'features', 'agentIntroduction']),
  },
  methods: {
    ...mapMutations('demo', ['nextStep']),
    /**
     * Check if we can move on to the next step.
     * If we can, it will move us one step forward.
     */
    moveOnIfWeCan() {
      /**
       * We always re-fetch on "Next", but API uses a cache in case there are no changes.
       * All we need to do is to halt skipping the loading steps when it already has something
       * populated until the old value can be overwritten.
       */
      if (this.loading) return;

      switch(this.step) {
        // For this step we are waiting for all the `questions` to be populated.
        case stepSlugs.stepQuestionsLoading: {
          if (!this.questions.length) return;
          return this.nextStep();
        }

        // For this step, we want both the introduction and the features to be reason
        case stepSlugs.stepIntentsLoading: {
          if (!this.agentIntroduction) return;
          if (!this.features) return;
          return this.nextStep();
        }

        // More steps here.

        default:
          console.error(`Unknown loading step '${this.step}'`);
      }
    }
  },
  /**
   * Watch all the properties we're expecting to wait for completion of.
   */
  watch: {
    questions() { this.moveOnIfWeCan() },
    features() { this.moveOnIfWeCan() },
    agentIntroduction() { this.moveOnIfWeCan() },
  },
  mounted() { this.moveOnIfWeCan() }
};
</script>
