<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DemoStep from '@/components/demo/DemoStep.vue';
import DemoBtns from '@/components/demo/components/DemoBtns.vue';

export default {
  name: 'DemoStepEditPersona',
  components: { DemoBtns, DemoStep },
  data() {
    return {
      prompt: '',
    }
  },
  computed: {
    ...mapGetters('demo', ['persona']),
  },
  methods: {
    ...mapMutations('demo', ['previousStep', 'nextStep', 'setValue']),
    ...mapActions('demo', ['handleCreateAgentIntroduction']),
    async next() {
      // Don't await, run in the background as we continue to next step
      await this.handleCreateAgentIntroduction({
        language: this.$vuetify.lang.current,
        personaTitle: this.$t(`demo.personas.${this.persona.id}.name`),
      });

      const language = this.$vuetify.lang.current;
      this.$store.dispatch('demo/handleGenerateFeatures', { language });

      this.nextStep();
    },
  },
  watch: {
    /**
     * If this text is edited by the user, or set since it was all
     * empty during init, then update the Vuex state as well.
     * @param {string} newText
     */
    prompt(newText) {
      this.setValue({
        key: 'persona',
        value: {
          ...this.persona,
          prompt: newText,
        },
      });
    }
  },
  mounted() {
    /**
     * If a prompt is set in the Vuex state, populate that into the v-model editor.
     * If it didn't set anything, set it from the UI strings. This will trigger
     * watcher to also update the Vuex state.
     */
    if (this.persona.prompt) this.prompt = this.persona.prompt;
    if (!this.prompt) {
      this.prompt = this.$t(`demo.personas.${this.persona.id}.prompt`);
    }
  }
};
</script>

<template>
  <DemoStep>
    <form class="d-flex gap-2">
      <img
        :src="$t(`demo.personas.${persona.id}.avatar`)"
        :alt="`Illustration of ${$t(`demo.personas.${persona.id}.name`)}`"
        class="persona-avatar flex-shrink-0"
      />

      <div class="full-width">
        <h3
          class="text-h4 primary--text mb-2"
          :id="`persona-title-${persona.id}`"
        >
          {{ $t(`demo.personas.${persona.id}.name`) }}
        </h3>

        <v-textarea
          v-model="prompt"
          outlined
          hide-details
          :label="$t('demo.personas.prompt')"
          :placeholder="$t(`demo.personas.${this.persona.id}.prompt`)"
          auto-grow
        />
      </div>
    </form>
    <DemoBtns
      @onBack="previousStep"
      @onNext="next"
      should-lock
    />
  </DemoStep>
</template>

<style scoped lang="scss">
.persona-avatar {
  width: 6rem;
  height: 6rem;
  border-radius: 4rem;
  object-fit: cover;
  background-color: lightgrey;
  overflow: hidden;
  transition: all .2s ease-in-out;
  box-shadow: 0 0 0 0 var(--v-secondary-base);
}
</style>