<script>
import { mapGetters, mapMutations } from 'vuex';
import BtnSquare from '@/components/common/BtnSquare.vue';
import { stepsInOrder } from '@/components/demo/stepSlugs';

const errorCodes = {
  // Codes and names are taken from API -> AppErrors
  RateLimited: 1007,
  FailedToParseLLMGeneratedJSON: 50_000+1,
  ExpectedResourceNotFound: 5_000+5,
  WebScrapingFailed: 40_000+1,
};

export default {
  name: 'DemoStepError',
  components: { BtnSquare },
  computed: {
    ...mapGetters('demo', ['error']),
    displayErrorMessage() {
      const key = `demo.demoError.codes.${this.error.code}`;
      const translation = this.$t(key);

      if (translation === key) return this.error.message;
      return translation;
    }
  },
  methods: {
    ...mapMutations('demo', ['previousStep', 'setError', 'setValue']),
    tryAgain() {
      // Keep copy for backwards navigation
      const step = this.error.step;

      // Clear the errors
      this.setError({
        code: 0,
        message: '',
        step: '',
      });
      this.setValue({
        key: 'hasError',
        value: false,
      });

      /**
       * We need to go backwards to until we reach the
       * step user was at when they triggered a fetch that failed.
       * That way, when you click "next" there, it will
       * basically retry the thing that caused the failure.
       */
      let index = stepsInOrder.indexOf(step);

      // Double-check that it is not a loading screen.
      if (stepsInOrder[index]?.toLowerCase()?.includes('loading')) index += -1;

      this.setValue({
        key: 'step',
        value: stepsInOrder[Math.max(index, 0)],
      });
    }
  },
};
</script>

<template>
  <div class="pa-10 d-flex flex-column gap-4">
    <div class="d-flex gap-2 items-center">
      <v-icon color="error" class="pr-2">mdi-alert</v-icon>
      <h2>{{ $t('demo.demoError.title') }}</h2>
    </div>

    <p class="text-body-1 mt-4">
      {{ displayErrorMessage }}

      <br>
      <span class="text-sm-body-2 text--secondary text--lighten-3">
        {{ error.code }}
      </span>
    </p>

    <BtnSquare
      preset="default"
      @click="tryAgain"
    >
      {{ $t('demo.demoError.buttonLabel') }}
    </BtnSquare>
  </div>
</template>
