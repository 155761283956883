<script>
import BtnSquare from '@/components/common/BtnSquare.vue';
import WelcomeInfoTeaser from '@/components/stats/components/WelcomeInfoTeaser.vue';
import HubDialog from '@/components/hub/HubDialog.vue';
import { mapGetters } from 'vuex';
import NotificationService from '@/services/notifications';
import Rag_v2 from '@/services/rag_v2';

export default {
  name: 'WelcomeLicense',
  components: { WelcomeInfoTeaser, BtnSquare, HubDialog },
  data() {
    return {
      icons: {
        professional: 'workspace_premium',
        premium: 'verified',
        essential: 'verified',
        demo: 'mdi-id-card',
        none: 'close'
      },
      usedRagCharacterCount: 0,
      contactForm: {
        shown: false,
        content: '',
        status: {
          success: true,
          message: null,
        },
        topics: {
          intents: false,
          languages: false,
          channels: false,
          upgrade: false,
          ragCharacterCount: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('intents', ['intents']),
    ...mapGetters('bots', ['currentBotId', 'getBot', 'currentLicence', 'getBotSettings']),
    package() {
      const id = this.currentLicence.package ?? 'none';

      return {
        id,
        icon: this.icons[id],
        name: id.charAt(0).toUpperCase() + id.slice(1),
      };
    },
    intentQuota() {
      const used = this.intents?.length ?? 0;
      const available = this.getBotSettings(this.currentBotId)?.quota?.intents ?? 0;
      return [
        used,
        available,
      ];
    },
    channelQuota() {
      const used = this.getBot(this.currentBotId)?.channels?.length ?? 0
      const available = this.getBotSettings(this.currentBotId)?.quota?.channels ?? 0;
      return [
        used,
        available,
      ];
    },
    languageQuota() {
      const used = this.getBot(this.currentBotId)?.languages?.length ?? 0;
      const available = this.getBotSettings(this.currentBotId)?.quota?.languages?.length ?? 0;
      return [
        used,
        available,
      ];
    },
    characterCountQuota() {
      const available = this.getBotSettings(this.currentBotId)?.quota?.ragCharacterCount ?? 0;
      return [
        this.usedRagCharacterCount,
        available,
      ];
    }
  },
  methods: {
    /**
     * Submits a contact form request for the quota adjustment
     * @returns {Promise<void>}
     */
    async sendForm() {
      if (!this.contactForm.content) return;

      try {
        const result = await NotificationService.notifyCSM({
          uniqueBotId: this.currentBotId,
          type: 'quotaAdjustment',
          comment: this.contactForm.content,
          quotas: this.contactForm.topics,
        });

        if (!result.success) throw new Error(result.message || this.$t('welcome.adjustPackage.failure'));
        this.contactForm.status.message = this.$t('welcome.adjustPackage.success');
        this.contactForm.status.success = true;

        // Reset form if success, otherwise keep the content
        this.contactForm.content = '';
        for (let key in this.contactForm.topics) this.contactForm.topics[key] = false;
      } catch (e) {
        console.error(e);
        this.contactForm.status.success = false;
        this.contactForm.status.message = this.$t('welcome.adjustPackage.failure');
      } finally {
        this.contactForm.shown = false;
      }
    },
    openForm(preChecked) {
      for (const key in this.contactForm.topics) this.contactForm.topics[key] = false;
      this.contactForm.topics[preChecked] = true;
      this.contactForm.shown = true;
    },
    /**
     * Returns display string for numbers
     * @param {number} value
     * @param {?("used"|"available")} kind
     * @returns {string}
     */
    displayNumber(value, kind) {
      if (kind === 'used') return value.toLocaleString(undefined, { notation: 'compact', minimumFractionDigits: 0 });
      if (kind === 'available') return value.toLocaleString(undefined, { notation: 'compact', minimumFractionDigits: 0, maximumFractionDigits: 0 });
      return value.toLocaleString();
    }
  },
  async mounted() {
    const r = await Rag_v2.getRagResourcesCharacterCount(this.currentBotId);
    this.usedRagCharacterCount = r.total ?? 0;
  }
};
</script>

<template>
  <div>
    <!-- Title / Header -->
    <div
      class="mb-4 d-flex align-center primary--text text--lighten-2 justify-space-between"
    >
      <div class="d-flex">
        <v-icon color="primary lighten-2" left>mdi-information-outline</v-icon>
        <h2 class="text-h6">{{ $t('welcome.stats.quotaTitle') }}</h2>

        <!-- Form submission status -->
        <p
          v-if="contactForm.status.message"
          :class="contactForm.status.success ? 'success--text' : 'error--text'"
          class="ml-4"
        >
          <v-icon :color="contactForm.status.success ? 'success' : 'error'">
            {{ contactForm.status.success ? 'check' : 'warning' }}
          </v-icon>
          {{ contactForm.status.message }}
        </p>
      </div>

      <BtnSquare v-if="false" icon="mdi-cog" to="/settings/license" outlined />
    </div>

    <!-- Main content -->
    <div class="d-flex gap-4 mb-4">
      <!-- Active package -->
      <WelcomeInfoTeaser
        class="column"
        :title="$t('welcome.stats.activePackage')"
        :icon="package.icon"
        :value="package.name"
        :loading="false"
      >
        <BtnSquare
          class="grow-0 mx-auto px-2"
          color="gray"
          exact
          outlined
          with-slot
          small
          @click="() => openForm('upgrade')"
        >
          {{ $t('welcome.buttons.changePackage') }}
        </BtnSquare>
      </WelcomeInfoTeaser>

      <v-divider vertical />

      <!-- Intents -->
      <WelcomeInfoTeaser
        class="column"
        :title="$t('welcome.stats.intents')"
        :loading="false"
        icon="topic"
        :value="`${intentQuota[0]}/${intentQuota[1]}`"
        :critical-value="intentQuota[0] >= intentQuota[1]"
      >
        <BtnSquare
          class="grow-0 mx-auto px-2"
          color="gray"
          exact
          outlined
          with-slot
          small
          @click="() => openForm('intents')"
        >
          {{ $t('welcome.buttons.increaseIntents') }}
        </BtnSquare>
      </WelcomeInfoTeaser>

      <!-- Channels -->
      <WelcomeInfoTeaser
        class="column"
        :title="$t('welcome.stats.channels')"
        :loading="false"
        icon="filter_alt"
        :value="`${channelQuota[0]}/${channelQuota[1]}`"
        :critical-value="channelQuota[0] >= channelQuota[1]"
      >
        <BtnSquare
          class="grow-0 mx-auto px-2"
          color="gray"
          exact
          outlined
          with-slot
          small
          @click="() => openForm('channels')"
        >
          {{ $t('welcome.buttons.addChannels') }}
        </BtnSquare>
      </WelcomeInfoTeaser>

      <!-- Languages -->
      <WelcomeInfoTeaser
        class="column"
        :title="$t('welcome.stats.languages')"
        :loading="false"
        icon="language"
        :value="`${languageQuota[0]}/${languageQuota[1]}`"
        :critical-value="languageQuota[0] >= languageQuota[1]"
      >
        <BtnSquare
          class="grow-0 mx-auto px-2"
          color="gray"
          exact
          outlined
          with-slot
          small
          @click="() => openForm('languages')"
        >
          {{ $t('welcome.buttons.addLanguages') }}
        </BtnSquare>
      </WelcomeInfoTeaser>

      <!-- RAG Resource Character Count -->
      <WelcomeInfoTeaser
        v-if="user.features.includes('ragCharacterCount')"
        class="column"
        :title="$t('welcome.stats.characterCount')"
        :loading="false"
        icon="abc"
        :value="`${displayNumber(characterCountQuota[0], 'used')}/${displayNumber(characterCountQuota[1], 'available')}`"
        :critical-value="characterCountQuota[0] >= characterCountQuota[1]"
      >
        <BtnSquare
          class="grow-0 mx-auto px-2"
          color="gray"
          exact
          outlined
          with-slot
          small
          @click="() => openForm('ragCharacterCount')"
        >
          {{ $t('welcome.buttons.addCharacterCount') }}
        </BtnSquare>
      </WelcomeInfoTeaser>
    </div>

    <HubDialog
      v-model="contactForm.shown"
      :disabled="!contactForm.content.length"
      :title="$t('welcome.adjustPackage.title')"
      :primary-label="$t('common.send')"
      async-dialog
      @onSecondary="contactForm.shown = false"
      @onPrimary="sendForm"
    >
      <p>
        {{ $t('welcome.adjustPackage.description') }}
      </p>
      <div class="d-flex flex-col gap-2 mb-4">
        <v-checkbox
          :label="$t('welcome.adjustPackage.upgrade')"
          class="mt-0"
          v-model="contactForm.topics.upgrade"
          hide-details
        />
        <v-checkbox
          :label="$t('welcome.stats.intents')"
          class="mt-0"
          v-model="contactForm.topics.intents"
          hide-details
        />
        <v-checkbox
          :label="$t('welcome.stats.channels')"
          class="mt-0"
          v-model="contactForm.topics.channels"
          hide-details
        />
        <v-checkbox
          :label="$t('welcome.stats.languages')"
          class="mt-0"
          v-model="contactForm.topics.languages"
          hide-details
        />
        <v-checkbox
          v-if="user.features.includes('ragCharacterCount')"
          :label="$t('welcome.stats.characterCount')"
          class="mt-0"
          v-model="contactForm.topics.ragCharacterCount"
          hide-details
        />
      </div>
      <v-textarea
        v-model="contactForm.content"
        :placeholder="$t('welcome.adjustPackage.placeholder')"
        filled
        outlined
        auto-grow
        autofocus
        hide-details
        :maxlength="4096"
      />
    </HubDialog>
  </div>
</template>

<style scoped lang="scss">
.column {
  flex-basis: 25%;
  text-align: center;
}
</style>