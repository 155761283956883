<template>
  <div>
    <InfoBoxOutline
      class="mb-8"
      v-if="isDemoLicence"
      block
      :title="$t('intents.inReview.hintNoLiveStateDemo.title')"
      :body="$t('intents.inReview.hintNoLiveStateDemo.body')"
      icon="hourglass_top"
    />
    <h2 class="text-h6 primary--text mb-3">
      <v-icon left class="mr-2 pb-2" color="primary lighten-3">
        mdi-file-document-edit-outline
      </v-icon>
      <span>{{ $t('intentNew.newWithoutTemplate.titleNewIntent') }}</span>
    </h2>

    <v-slide-x-transition group hide-on-leave>
      <IntentNewStepName
        v-if="activeStep === 1"
        key="step-1"
        @onDisableChange="setDisabled"
      />
      <IntentNewStepSamples
        v-else-if="activeStep === 2"
        key="step-2"
        @onDisableChange="setDisabled"
      />
      <IntentNewStepSummary v-else-if="activeStep === 3" key="step-3" />
    </v-slide-x-transition>

    <v-row align="center" justify="space-between" class="mt-4">
      <v-col>
        <v-btn
          v-if="activeStep === 1"
          text
          color="primary lighten-2"
          @click="openCancelDialog"
        >
          {{ $t('editor.common.discard') }}
          <v-icon right>delete_outline</v-icon>
        </v-btn>
        <v-btn v-else color="primary lighten-2" text @click="stepBack">
          <v-icon left size="24">mdi-arrow-left</v-icon>
          {{ $t('common.back') }}
        </v-btn>
      </v-col>

      <v-col v-if="activeStep < stepsCount" class="text-right">
        <v-tooltip left :disabled="!disabledMessage">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                color="primary lighten-3"
                text
                @click="stepNext"
                :disabled="isDisabled"
              >
                {{ $t('common.next') }}
                <v-icon right size="24">mdi-arrow-right</v-icon>
              </v-btn>
            </span>
          </template>
          <span>{{ disabledMessage }}</span>
        </v-tooltip>
      </v-col>

      <v-col v-else class="text-right">
        <v-btn
          color="secondary darken-2"
          depressed
          @click="openCreateDialog"
          :disabled="isLoading"
        >
          {{ $t('intentNew.newWithoutTemplate.addIntent') }}
          <v-icon right>mdi-plus-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" v-if="showError">
        <v-alert outlined color="red darken-2">
          <div class="text-body-2 error--text">
            {{ $t('intentNew.stepSummary.errorMessageName') }}
          </div>
        </v-alert>
      </v-col>
    </v-row>

    <HubDialog
      v-model="createDialogIsOpen"
      :primary-label="$t('common.save')"
      primary-icon="save"
      :secondary-label="$t('common.cancel')"
      secondary-icon="close"
      :title="
        $t('intentNew.dialogNewWithoutTemplate.title', {
          displayName: newIntent.displayName,
        })
      "
      modal
      asyncDialog
      :disabled="isLoading"
      @onPrimary="createIntent"
      @onSecondary="closeCreateDialog"
    >
      {{
        $t('intentNew.dialogNewWithoutTemplate.body', {
          displayName: newIntent.displayName,
        })
      }}
    </HubDialog>

    <HubDialog
      v-model="cancelDialogIsOpen"
      :primary-label="$t('intentNew.dialogIntentCancel.btnPrimaryLabel')"
      primary-icon="delete_outline"
      :title="$t('intentNew.dialogIntentCancel.title')"
      modal
      @onPrimary="cancelCreation"
    >
      {{ $t('intentNew.dialogIntentCancel.body') }}
    </HubDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IntentNewStepName from './IntentNewStepName.vue';
import IntentNewStepSamples from './IntentNewStepSamples.vue';
import IntentNewStepSummary from './IntentNewStepSummary.vue';
import HubDialog from '@/components/hub/HubDialog.vue';
import { TrackingService } from '@/services/tracking';
import InfoBoxOutline from '@/components/common/InfoBoxOutline.vue';

export default {
  name: 'IntentNewWithoutTemplate',
  components: {
    IntentNewStepName,
    IntentNewStepSamples,
    IntentNewStepSummary,
    HubDialog,
    InfoBoxOutline,
  },
  data() {
    return {
      isDisabled: true,
      isLoading: false,
      showError: false,
      disabledMessage: '',
      stepsCount: 3,
      createDialogIsOpen: false,
      cancelDialogIsOpen: false,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId', 'currentLicence']),
    ...mapGetters('intentNew', ['newIntent', 'activeStep']),
    isDemoLicence() {
      return this.currLicence?.package === 'demo';
    },
  },
  methods: {
    cancelCreation() {
      this.trackIt('without-template--cancel');
      this.$store.commit('intentNew/clearSearch');
    },
    openCreateDialog() {
      this.trackIt('without-template--dialog-open');
      this.createDialogIsOpen = true;
    },
    closeCreateDialog() {
      this.trackIt('without-template--dialog-close');
      this.createDialogIsOpen = false;
    },
    openCancelDialog() {
      this.trackIt('without-template--dialog-to-cancel-open');
      this.cancelDialogIsOpen = true;
    },
    closeCancelDialog() {
      this.trackIt('without-template--dialog-to-cancel-close');
      this.cancelDialogIsOpen = false;
    },
    stepBack() {
      this.trackIt('without-template--step-back');

      this.showError = false;
      this.$store.commit('intentNew/setActiveStep', this.activeStep - 1);
    },
    stepNext() {
      this.trackIt('without-template--step-next');
      this.$store.commit('intentNew/setActiveStep', this.activeStep + 1);
    },
    async createIntent() {
      this.trackIt('without-template--create-intent');
      this.isLoading = true;
      const { success } = await this.$store.dispatch('intentNew/createNewIntent', {
        botId: this.currentBotId,
      });
      this.isLoading = false;
      if (!success) {
        this.showError = true;
        this.trackIt('without-template--error-name-exists');
      }
    },
    setDisabled(payload) {
      this.isDisabled = payload.isDisabled;
      this.disabledMessage = payload.message;
    },
    trackIt(type = '--without-template--undefined') {
      TrackingService.trackNewIntent({
        botId: this.currentBotId,
        type,
        source: 'IntentNewWithoutTemplate',
        payload: {
          activeStep: this.activeStep,
          newIntent: this.newIntent,
        },
      });
    },
  },
};
</script>
