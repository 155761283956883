<script>
import DemoStep from '@/components/demo/DemoStep.vue';
import DemoBtns from '@/components/demo/components/DemoBtns.vue';
import DemoQuestion from '@/components/demo/components/DemoQuestion.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'DemoStepPersona',
  components: {
    DemoQuestion, DemoBtns,
    DemoStep,
  },
  data() {
    return {
      selected: 'customerSupport',
    }
  },
  computed: {
    ...mapGetters('demo', ['persona', 'personaIds']),
  },
  methods: {
    ...mapMutations('demo', ['previousStep', 'nextStep', 'setValue']),
  },
  mounted() {
    // If already set, select it...
    if (this.persona.id) {
      return this.selected = this.persona.id;
    }

    // ... else, set default selected persona to the first radio item, if there's not one selected already
    this.setValue({
      key: 'persona',
      value: {...this.persona, id: this.persona.id || this.selected},
    });
  },
  watch: {
    selected(newIdentifier) {
      // Update the persona identifier accordingly
      this.setValue({
        key: 'persona',
        value: {...this.persona, id: newIdentifier},
      });
    }
  }
};
</script>

<template>
  <DemoStep>
    <form class="persona-grid">
      <label
        v-for="persona in personaIds"
        :key="persona"
        class="persona-card pa-4 d-flex gap-2 items-start"
        :class="{
          'active-card': selected === persona,
        }"
        :aria-labelledby="`persona-title-${persona}`"
      >
        <input
          v-model="selected"
          :value="persona"
          type="radio"
          name="botPersona"
          :id="`input-${persona}`"
        >

        <img
          :src="$t(`demo.personas.${persona}.avatar`)"
          :alt="`Illustration of ${$t(`demo.personas.${persona}.name`)}`"
          class="persona-avatar flex-shrink-0"
        />

        <div class="d-flex flex-column">
          <h3
            class="text-h4 primary--text"
            :id="`persona-title-${persona}`"
          >
            {{ $t(`demo.personas.${persona}.name`) }}
          </h3>
          <p>{{  $t(`demo.personas.${persona}.description`) }}</p>
        </div>
      </label>
    </form>

    <DemoBtns @onBack="previousStep" @onNext="nextStep" />
  </DemoStep>
</template>

<style scoped lang="scss">
/* Mobile: Single-column, multiple rows */
.persona-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 1rem;
}

.persona-card {
  border-radius: .5em;
  border: solid thin gray;
  cursor: pointer;
}
.persona-card:not(.active-card):hover {
  border-color: var(--v-secondary-base);
  box-shadow: 0 0 0 1px var(--v-secondary-base);
  transition: all .2s ease-in-out;
}

.persona-avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;
  object-fit: cover;
  overflow: hidden;
  transition: all .2s ease-in-out;
}

.active-card {
  border-color: var(--v-secondary-base);
  box-shadow: 0 0 0 2px var(--v-secondary-base);
  transition: all .2s ease-in-out;
}

/* Make grid 2-column on bigger screen */
@media (min-width: 600px) {
  .persona-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>