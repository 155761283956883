import { render, staticRenderFns } from "./DropDownMenu.vue?vue&type=template&id=8ef63568&scoped=true"
import script from "./DropDownMenu.vue?vue&type=script&lang=js"
export * from "./DropDownMenu.vue?vue&type=script&lang=js"
import style0 from "./DropDownMenu.vue?vue&type=style&index=0&id=8ef63568&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ef63568",
  null
  
)

export default component.exports