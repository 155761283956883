<script>
export default {
  name: 'WidgetAIMessage',
  props: {
    // If defined: use the avatar at the URL
    "avatar": {
      type: String,
    },
    // Whether to show the little speech arrow
    "arrow": {
      type: Boolean,
      default: false,
    },
    "text": {
      type: String,
      default: "Es gibt keine Altersbeschränkung und es ist für alle Altersgruppen geeignet.",
    },
    // Whether AI indicator is enabled. Should be true only for the last in a sequence
    "enabled": {
      type: Boolean,
      default: true,
    },
    "generated": {
      type: String,
      default: "Generated",
    },
    "sources": {
      type: String,
      default: '',
    },
    "pages": {
      type: String,
      default: "Pages",
    },
    "disclaimer": {
      type: String,
      default: () => "KI generierte Texte können Fehler enthalten. Überprüfe wichtige Informationen.",
    },
    "resourcesUsed": {
      type: Array,
      default: () => [
        {
          source: null,
          type: 'PDF',
          pages: [1, 2, 3],

          /**
           * The `_sample` properties are only for the default values.
           * Do not include them if you pass your own `resourcesUsed`.
           */
          _sample: 0,
        },
        {
          source: null,
          type: 'WEBPAGE',
          _sample: 1,
        },
        {
          source: null,
          type: 'WEBPAGE',
          _sample: 2,
        },
      ]
    },
    // Optional: Control the color of the chat bubble
    "bgColor": {
      type: String,
      default: null,
    },
    // Optional: Control the color of the chat bubble text
    "txtColor": {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      isOpen: false,
    }
  },
  created() {
    this.sources = this.$tc('editor.widget.ragResponse.sourceLabel', this.resourcesUsed.length);
    this.generated = this.$t('editor.widget.ragResponse.generated');
  },
  computed: {
    rounding() {
      if (this.enabled) return 'border-radius: 0 6px 0 0';
      if (this.arrow || this.avatar) return 'border-radius: 0 6px 6px 6px';
      return 'border-radius: 6px';
    },
    styles() {
      const styles = [];
      if (this.bgColor) styles.push(`background-color: ${this.bgColor}`);
      if (this.txtColor) styles.push(`color: ${this.txtColor}`);
      return styles.join(";")
    },
  },
  methods: {
    handleToggle() {
      this.isOpen = this.$refs.details.open;
    },
    sourceLabel(source) {
      if (source.type === 'PDF') {
        if (source.pages) {
          return `PDF, ${this.pages} ${source.pages.join(', ')}`;
        }
        return 'PDF';
      }

      if ('_sample' in source) {
        if (source._sample === 1) return `https://example.com/1/2f41aa8441b17`;
        if (source._sample === 2) return `https://example.com/general-information/faq/before-you-go`;
        return `https://example.com/${source._sample}/${Math.random().toString(16).slice(2)}`;
      }

      return source?.source;
    },
  },
  watch: {
    /**
     * Ensure user sees the field they're editing by opening the summary container
     */
    pages() {
      if (this.isOpen) return;
      this.isOpen = true;
      return this.$refs.details.open = true;
    }
  }
};
</script>

<template>
  <div class="bot-message-wrapper" :class="{'with-avatar': !!avatar}">
    <div v-if="avatar" class="avatar-container">
      <img
        :src="avatar"
        alt="Avatar"
        class="bot-avatar"
      >
    </div>
    <svg
      v-if="arrow || !!avatar"
      width="10"
      height="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="0,0 100,100 100,0" :fill="bgColor ? bgColor : 'white'"/>
    </svg>

    <div class="d-flex flex-column">
      <div
        class="bot-message"
        :class="{ 'with-arrow': arrow || !!avatar }"
        :style="`${rounding};${styles}`"
      >
        {{ text }}
      </div>

      <!-- Lower half with AI indicator -->
      <details
        v-if="enabled"
        ref="details"
        class="ai-indicator rounded-bottom"
        :style="styles"
        @toggle="handleToggle"
      >
        <summary class="ai-generated">
        <span class="ai-badge-container">
          <span class="ai-badge">AI</span>
          {{ generated }}
        </span>

          <div class="expand">
            {{ resourcesUsed.length }} {{ sources }}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 20"
              xmlns="http://www.w3.org/2000/svg"
              :style="`transform: rotate(${isOpen ? '0' : '-90'}deg);`"
            >
              <path
                d="M14.7846 7.57617L10.7862 11.5659L6.78784 7.57617L5.55957 8.80444L10.7862 14.0311L16.0129 8.80444L14.7846 7.57617Z"
                :fill="txtColor ? txtColor : 'black'"
              />
            </svg>
          </div>
        </summary>

        <ol>
          <li v-for="source in resourcesUsed" :key="source.source">
            <a rel="noopener">
              {{ sourceLabel(source) }}
            </a>
          </li>
        </ol>
      </details>

      <!-- Disclaimer about accuracy of AI -->
      <p v-if="enabled" class="ai-disclaimer">
        {{ disclaimer }}
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
svg {
  flex-shrink: 0;
}

.bot-message-wrapper {
  padding-left: calc(35px + 12px);

  /* From the widget itself */
  margin-bottom: 7px;
  display: flex;
  width: 100%;
  padding-right: 35px;
  position: relative;
}
.avatar-container {

  /* From the widget itself */
  position: absolute;
  top: -5px;
  left: 0;

  width: 44px;
  height: 44px;
  min-width: 44px;
  min-height: 44px;

  img {
    border-radius: 44px;
    border: solid 2px white;

    /* From the widget itself */
    background: #d3d3d3;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    object-position: center center;
    align-self: flex-end;
    transform: scale(1.1);
    transition: transform .15s ease-in-out;
  }
}
.bot-message {
  line-height: 1.25em;

  /* From the widget itself */
  white-space: pre-line;
  display: inline-flex;
  padding: 10px 12px;
  font-size: 1.5em;
  font-weight: 400;
  background: white;
  color: #333333;
  border: 1px solid;
  border-color: var(--moin-message-bot-bordercolor, transparent);

  margin-left: 10px;
  font-family: "Rubik",Helvetica,Arial,sans-serif;

  &.with-arrow {
    margin-left: 0;
  }
}


.ai-disclaimer {
  line-height: 120%;
  font-size: 12px;
  color: gray;
  margin: 0.5em 0;
  padding: 0;
  text-align: center;
}

.expand,
.ai-badge-container {
  display: flex;
  gap: 0.5em;
  align-items: center;
  text-overflow: ellipsis;
  white-space: initial;
  overflow: hidden;
  min-width: auto;
  word-wrap: anywhere;
}

.ai-generated {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
}

.ai-indicator .ai-generated {
  cursor: pointer;
}

.rounded-bottom {
  border-radius: 0 0 8px 8px;
}

.ai-indicator {
  margin-top: 1px;
  background: #fff;
  color: #000;
  padding: 7px 12px 7px 12px;
  font-size: 12px;
}

ol {
  list-style: decimal;
  padding: 8px 12px 8px calc(12px + 1em);
  margin: 0;
  overflow-wrap: break-word;
  text-align: start;
  text-decoration: underline;
}

.ai-badge {
  color: #fff;
  background: #000;

  font-weight: 600;
  font-size: 10px;
  font-style: normal;
  line-height: 100%;

  border-radius: 3px;
  display: flex;
  padding: 2px 3px 2px 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  word-wrap: break-word;
}
</style>