import DemoService from '@/services/demo';
import { stepsCount, stepsInOrder, stepSlugs } from '@/components/demo/stepSlugs';
import { getFingerprintVisitorId } from '@/utils/fingerprint';
import { nextTick } from 'vue';

const state = {
  userId: '',
  step: stepSlugs.stepCompany,
  companyUrl: '',
  stepsAmount: stepsCount,
  loading: false,
  questions: [],
  isQuestionsDirty: true,
  intents: [],
  features: null,
  contact: {
    company: '',
    name: '',
    email: '',
    phone: '',
    privacyAccepted: false,
    marketing: false,
  },
  // Array of lowercase service names/identifiers the site uses/we suggest
  technologies: [],
  persona: {
    // The type of persona selected
    id: '',
    // The description prompt for the selected persona, in the current language
    prompt: '',
  },
  agentIntroduction: '',
  fingerprintId: null,
  queryParams: {},
  hasError: false,
  error: {
    code: 0,      // Error code from the API. Can be used to override message with localization
    message: '',  // Error text from the API
    step: '',     // The step in which the error occurred
  },
  event: null, //String if set E.g. 'CCW 2023'
};

const getters = {
  step: (state) => state.step,
  hasError: (state) => state.hasError,
  stepsAmount: (state) => state.stepsAmount,
  loading: (state) => state.loading,
  test: (state) => state.test,
  contact: (state) => state.contact,
  companyUrl: (state) => state.companyUrl,
  questions: (state) => {
    // If no persona selected, return all
    if (!state.persona.id) return state.questions;
    // ... else return all for the selected persona
    return state.questions.filter(q => q.persona.id === state.persona.id);
  },
  isQuestionsDirty: (state) => state.isQuestionsDirty,
  intents: (state) => state.intents,
  fingerprintId: (state) => state.fingerprintId,
  userId: (state) => state.userId,
  queryParams: (state) => state.queryParams,
  event: (state) => state.event,
  features: (state) => state.features,
  technologies: (state) => state.technologies,
  agentIntroduction: (state) => state.agentIntroduction,
  persona: (state) => state.persona,
  error: (state) => state.error,
  personaIds: () => [
    'customerSupport',
    'preSalesConsultant',
    'salesRepresentative',
    'technicalSupport',
    'onboardingSpecialist',
    'customerSuccessManager',
  ],
  /**
   * Get the URL of a logo/icon for a service/feature
   * @returns {(identifier: string) => string}
   */
  getLogo: () => (identifier) => {
    return `/brand-logos/${identifier}.svg`;
  }
};

const actions = {
  async initializeUserIds({ state, rootGetters }) {
    const localUserId = DemoService.getLocalUserId();
    state.fingerprintId = await getFingerprintVisitorId();

    if (localUserId && localUserId !== 'null') {
      return;
    }

    const username = rootGetters['auth/user']?.username;

    const res = await DemoService.getUserIdWithFingerprintId({
      fingerprintId: username || state.fingerprintId,
    });

    const newUserId = res?.userId || null;

    state.userId = newUserId;

    DemoService.setLocalUserId(newUserId);

    return true;
  },
  /**
   * Starts generating typical questions visitors might have for the website.
   * Loads in the background and populates `questions` when ready.
   * @param state
   * @param commit
   * @param {'de'|'en'|string} language The current user's language
   * @param {{id: string, name: string, description: string}[]} personas Generate questions for these personas
   * @returns {Promise<null | {id: string, text: string, checked: boolean, persona: {id: string, name: string, description: string}}[]>}
   */
  async loadQuestionsWithCompany({ state, commit }, { language, personas }) {
    const step = state.step; // Copy: it can change while we await.

    const result = await DemoService.getQuestionsWithCompanyUsingPersonas({
      site: state.companyUrl,
      language,
      fingerprintId: state.fingerprintId,
      personas,
      userId: state.userId,
    });

    if (result.status !== 'OK') {
      commit('setError', {
        code: result.code,
        message: result.message,
        step,
      });

      if (result.message === '404') state.step = stepSlugs.stepCompany;
      state.hasError = true;
      return null;
    }

    // The questions will contain all possible questions, and is tagged with which persona it'd belong to
    state.questions = result.personas.flatMap((persona) => {
      return persona.queries.map((query) => ({
        id: query.id,
        text: query.text,
        checked: true,
        persona: {
          id: persona.persona.id,
          name: persona.persona.name,
          description: persona.persona.description,
        },
      }));
    });

    return state.questions;
  },
  /**
   * Start analyzing what technologies/services the website uses.
   * Will return with ones we support integrating with that they use, and/or a
   * generic list of suggestions.
   * @param state
   * @param commit
   * @param {string} url The company's website
   * @returns {Promise<null | {status: string, technologies: string[]}>}
   */
  async analyzeSiteTechnologies({ state, commit }, { url }) {
    const step = state.step; // Copy: it can change while we await.
    state.technologies = [];

    const res = await DemoService.analyzeSiteTechnologies({
      url,
      userId: state.userId,
    });

    if (res.status !== 'OK') {
      commit('setError', {
        code: res.code,
        message: res.message,
        step,
      });

      state.hasError = true;
      return null;
    }

    state.technologies = res.technologies;
    return res;
  },
  /**
   * @deprecated - No longer used
   */
  async handleStepCompany({ state, commit }, { language, dirty }) {
    const step = state.step; // Copy: it can change while we await.
    // Go to next step directly
    if (!dirty) {
      // TODO Bugfix here. If it failed, this should not happen.
      state.step = stepSlugs.stepQuestions;
      return;
    }

    state.loading = true;
    state.hasError = false;

    state.step = stepSlugs.stepCompanyLoading;

    const result = await DemoService.getQuestionsWithCompany({
      site: state.companyUrl,
      language,
      fingerprintId: state.fingerprintId,
      userId: state.userId,
    });

    if (result.status !== 'OK') {
      commit('setError', {
        code: result.code,
        message: result.message,
        step,
      });
      if (result.message === '404') {
        state.step = stepSlugs.stepCompany;
      }

      state.hasError = true;
      state.loading = false;
      return;
    }

    state.loading = false;
    state.step = stepSlugs.stepQuestions;
  },
  async handleGenerateFeatures({ state, getters, commit }, { language }) {
    state.features = null;

    const step = state.step; // Copy: it can change while we await.
    const excludedIds = getters.questions
      .filter(q => !q.checked && q.persona.id === state.persona.id)
      .map(q => q.id);

    const res = await DemoService.getFeaturesWithQuestions({
      excludedIds,
      personaIdentifier: state.persona.id,
      userId: state.userId,
      language,
      site: state.companyUrl,
    });

    if (res.status !== 'OK') {
      commit('setError', {
        code: res.code,
        message: res.message,
        step,
      });
      state.hasError = true;
      return;
    }

    state.features = res.features || null;
  },
  async handleStepContact({ state, commit }, { language }) {
    const step = state.step; // Copy: it can change while we await.
    state.loading = true;

    const contact = {...state.contact};
    if (state.event) {
      contact.event = state.event;
    }
    const res = await DemoService.createDemoAccount({
      contact,
      userId: state.userId,
      language,
      site: state.companyUrl,
    });
    if (res.status !== 'OK') {
      commit('setError', {
        code: res.code,
        message: res.message,
        step,
      });
      console.error('DemoService.createDemoAccount failed');
      state.loading = false;
      state.hasError = true;
      return false;
    }

    state.loading = false;
    return true;
  },
  /**
   * Creates and caches the introduction / purpose for the AI Agent
   * @param state
   * @param commit
   * @param {string} language
   * @param {string} personaTitle The display name of the persona.
   * Use the `personaId` to get the title from i18n
   * @returns {Promise<void>}
   */
  async handleCreateAgentIntroduction({ state, commit }, { language, personaTitle }) {
    const step = state.step; // Copy: it can change while we await.
    state.agentIntroduction = '';

    const res = await DemoService.createAgentIntroduction({
      language,
      agentTitle: personaTitle,
      agentDescription: state.persona.prompt,
      userId: state.userId,
      site: state.companyUrl,
    });

    if (res.status !== 'OK') {
      commit('setError', {
        code: res.code,
        message: res.message,
        step,
      });
      console.error('DemoService.createAgentIntroduction failed');
      state.hasError = true;
      return;
    }

    state.agentIntroduction = res.introduction;
  },
};

const mutations = {
  setValue(state, { key, value }) {
    state[key] = value;

    /**
     * If you change persona, it should wipe the custom instruction,
     * because that instruction is bound to the persona.
     */
    if (key === 'persona') state.agentIntroduction = '';
  },
  setQueryParams(state, params) {
    state.queryParams = params;
  },
  resetAll(state) {
    state.isQuestionsDirty = true;
    state.questions = [];
    state.intents = [];
    state.features = null;
    state.contact = {
      company: '',
      name: '',
      email: '',
      phone: '',
      privacyAccepted: false,
      marketing: false,
    };
    state.technologies = [];
    state.agentIntroduction = '';
    state.persona = {
      id: '',
      prompt: '',
    };
  },
  nextStep(state) {
    // NextTick: Allow async methods to set the current step in a variable before we change it
    nextTick(() => {
      const currentStepIndex = stepsInOrder.indexOf(state.step);
      state.step = stepsInOrder[currentStepIndex + 1];
    });
  },
  previousStep(state) {
    const currentStepIndex = stepsInOrder.indexOf(state.step);

    /**
     * Special handling:
     * If it is a loading screen, we'd skip right back to where we
     * came from. So, if the slug includes "loading", skip one more backwards.
     */
    let stepCount = 1;
    if (stepsInOrder[currentStepIndex - 1].toLowerCase().includes('loading')) {
      stepCount = 2;
    }

    state.step = stepsInOrder[currentStepIndex - stepCount];
  },
  toggleQuestion(state, question) {
    const index = state.questions.indexOf(question);
    if (index === -1) return;

    // If disabling, you cannot turn off the last one
    const toState = !state.questions[index].checked;
    if (toState === false) {
      const qLeft = state.questions.filter(q => q.checked && state.persona.id === q.persona.id).length;
      if (qLeft === 1) return;
    }

    state.questions[index].checked = toState;
  },
  setError(state, { code, message, step }) {
    if (!step) step = state.step;

    /**
     * Race condition:
     * You do step A, which kicks off background loading.
     * Before it finishes, you reach step D, which also starts background loading.
     * Step A fails, setting the error step to A.
     * Step D fails immediately after, setting the error step to D.
     * When you use the retry on the error page, it now assumes it is step D it has to go back to!
     *
     * Solution:
     * If an error step is already set, only overwrite the error payload
     * if the step that comes in is at a lower index than the current.
     */
    if (state.error?.step) {
      const oldIndex = stepsInOrder.indexOf(state.error.step);
      const newIndex = stepsInOrder.indexOf(step);
      if (newIndex > oldIndex) return;
    }

    state.error = { code, message, step };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
