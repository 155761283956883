<script>
import HubBox from '@/components/hub/HubBox.vue';
import InfoBox from '@/components/common/InfoBox.vue';
import HubDialog from '@/components/hub/HubDialog.vue';
import BotService from '@/services/bot';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'SettingsSSO',
  components: { InfoBox, HubBox, HubDialog },
  emits: ['onChange'],
  data() {
    return {
      use2FA: true,
      provider: null,

      originalUse2FA: false,
      originalProvider: null,

      dialogIsOpen: false,
      isLoading: false,
      providers: [
        {
          value: 'microsoft',
          text: 'Microsoft',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId', 'getBotSettings']),
    isChanged() {
      return (
        this.provider !== this.originalProvider ||
        this.use2FA !== this.originalUse2FA
      );
    },
  },
  methods: {
    ...mapMutations('bots', ['setSettings']),
    /**
     * Sets the component settings
     * @param {string} provider
     * @param {boolean} sso2FA
     */
    setLocalState(provider, sso2FA) {
      this.originalProvider = provider;
      this.provider = provider;
      this.originalUse2FA = sso2FA;
      this.use2FA = sso2FA;
    },
    /**
     * Sets the Vuex state settings
     * @param {string} provider
     * @param {boolean} sso2FA
     */
    setStoredState(provider, sso2FA) {
      const settings = this.getBotSettings(this.currentBotId);
      if (!settings.sso) settings.sso = {};

      settings.sso.provider = provider;
      settings.sso.sso2FA = sso2FA;

      this.setSettings({
        botId: this.currentBotId,
        settings,
      });
    },
    /**
     * Push changes to backend
     * @returns {Promise<void>}
     */
    async saveSettings() {
      this.isLoading = true;

      // Sets account flags - only needed if 2FA setting changed
      if (this.originalUse2FA !== this.use2FA) {
        await BotService.set2FAActivation({
          botId: this.currentBotId,
          activate: this.use2FA,
          forSSO: true,
        });
      }

      // Sets bot settings
      await BotService.setSSOSettings({
        botId: this.currentBotId,
        provider: this.provider,
        sso2FA: this.use2FA,
      });

      // Update component and Vuex
      this.setLocalState(this.provider, this.use2FA);
      this.setStoredState(this.provider, this.use2FA);

      this.$emit('onChange');

      this.dialogIsOpen = false;
      this.isLoading = false;
    },
    async turnOffSSO() {
      this.isLoading = true;
      this.use2FA = false;

      // If SSO 2FA was enabled, first enable normal 2FA
      if (this.originalUse2FA) {
        await BotService.set2FAActivation({
          botId: this.currentBotId,
          activate: true,
        });
      }

      this.provider = null;
      return this.saveSettings();
    },
  },
  mounted() {
    const settings = this.getBotSettings(this.currentBotId);
    this.setStoredState(
      settings.sso?.provider ?? null,
      settings.sso?.sso2FA ?? false
    );
    this.setLocalState(
      settings.sso?.provider ?? null,
      settings.sso?.sso2FA ?? false
    );
  },
};
</script>

<template>
  <div>
    <HubBox
      :title="$t('botSettings.sso.title')"
      :title-sub="$t('botSettings.sso.subTitle')"
      icon="mdi-login"
      expandedInitial
      :is-loading="isLoading"
    >
      <div class="text-body-2">
        {{ $t('botSettings.sso.description') }}
      </div>

      <!-- SSO Status indicator -->
      <div class="py-2 d-flex align-center">
        <!-- Enabled -->
        <template v-if="originalProvider">
          <v-icon class="mr-2" color="green">mdi-checkbox-marked-circle</v-icon>
          <div class="green--text">
            {{ $t('botSettings.sso.isActive', { name: originalProvider }) }}
          </div>
        </template>

        <!-- Disabled -->
        <template v-else>
          <v-icon class="mr-2" color="grey">mdi-cancel</v-icon>
          <div class="grey--text">
            {{ $t('botSettings.sso.isInactive') }}
          </div>
        </template>

      </div>

      <!-- SSO 2FA Status indicator -->
      <div class="py-2 d-flex align-center">
        <!-- Enabled -->
        <template v-if="originalUse2FA">
          <v-icon class="mr-2" color="green">mdi-checkbox-marked-circle</v-icon>
          <div class="green--text">
            {{ $t('botSettings.sso.is2FAActive') }}
          </div>
        </template>

        <!-- Disabled -->
        <template v-else>
          <v-icon class="mr-2" color="grey">mdi-cancel</v-icon>
          <div class="grey--text">
            {{ $t('botSettings.sso.is2FAInactive') }}
          </div>
        </template>
      </div>
      <InfoBox
        :text="$t('botSettings.sso.changeHint')"
      />

      <div class="d-flex gap-4 mt-6">
        <v-btn
          outlined
          color="primary lighten-2"
          @click="dialogIsOpen = true"
        >
          {{ $t('common.edit') }}
          <v-icon right>edit</v-icon>
        </v-btn>

        <v-btn
          v-if="!isLoading && provider"
          outlined
          color="error"
          :disabled="isLoading"
          @click="turnOffSSO"
        >
          {{ $t('common.deactivate') }}
          <v-icon right>power_settings_new</v-icon>
        </v-btn>
      </div>

      <HubDialog
        v-model="dialogIsOpen"
        :title="$t('botSettings.sso.dialog.title')"
        modal
        async-dialog
        primaryIcon="cloud_upload"
        :primaryLabel="$t('editor.common.deploy')"
        @onSecondary="dialogIsOpen = false"
        @onPrimary="saveSettings"
        :isLoading="isLoading"
        :disabled="!isChanged"
      >
        <p>{{ $t('botSettings.sso.dialog.description') }}</p>
        <v-select
          v-model="provider"
          :items="providers"
          :label="$t('botSettings.sso.dialog.selectProvider')"
          outlined
          dense
          clearable
          hide-details
        />
        <v-switch
          v-model="use2FA"
          :color="use2FA ? 'green' : 'grey'"
          inset
          :label="$t('botSettings.sso.dialog.use2FA')"
        />

        <InfoBox
          :text="$t(`botSettings.sso.dialog.${use2FA === originalUse2FA ? 'hintIs2FAUnchanged' : 'hintIs2FAChanged' }`)"
        />
      </HubDialog>
    </HubBox>
  </div>
</template>
