<template>
  <v-card outlined>
    <v-expansion-panels
      flat
      v-model="expanded"
      multiple
      :disabled="notExpandable"
    >
      <v-expansion-panel :key="0">
        <v-expansion-panel-header>
          <v-progress-linear
            class="loading-bar"
            v-if="isLoading"
            absolute
            indeterminate
            color="secondary darken-2"
          />
          <slot name="title">
            <div class="d-flex pt-1">
              <div
                class="d-flex align-start flex-shrink-0 flex-grow-0"
                v-if="icon"
                style="width: 28px"
              >
                <v-icon
                  :color="adminOnly ? 'admin' : 'primary lighten-3'"
                  size="20"
                  style="margin-top: -2px"
                >
                  {{ icon }}
                </v-icon>
              </div>
              <div>
                <h2 class="moin-line-height-1 text-h6 mr-2" :class="[adminOnly ? 'admin--text' : 'primary--text']">
                  {{ title }}
                </h2>
                <h3
                  v-if="titleSub"
                  class="
                    mt-2
                    moin-line-height-1
                    text-body-2
                    primary--text
                    text--lighten-2
                  "
                >
                  {{ titleSub }}
                </h3>
              </div>
            </div>
          </slot>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <slot></slot>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HubBox',
  emits: ['onExpand', 'input'],
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    notExpandable: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    titleSub: {
      type: String,
      default: '',
    },
    boxId: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    expandedInitial: {
      type: Boolean,
      default: false,
    },
    adminOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      expanded: [],
    };
  },
  created() {
    if (this.value !== null) {
      this.expanded = this.value ? [0] : [];
    } else {
      if (this.notExpandable) {
        this.expanded = [0];
        return;
      }
      if (
        this.isExpanded ||
        (!this.boxIsClosed(this.boxId) && this.expandedInitial)
      ) {
        this.expanded = [0];
      }
    }
  },
  computed: {
    ...mapGetters('hubBoxExpand', ['boxIsExpanded', 'boxIsClosed']),
    isExpanded() {
      const isExpanded = this.boxIsExpanded(this.boxId);
      return isExpanded;
    },
  },
  watch: {
    expanded(value) {
      if (!this.boxId) return;
      this.$emit('input', value.length > 0);

      if (value.length) {
        this.$store.commit('hubBoxExpand/addBoxId', this.boxId);
        this.$emit('onExpand', true);
      } else {
        if (this.expandedInitial) {
          this.$store.commit('hubBoxExpand/closeBoxId', this.boxId);
        }
        this.$store.commit('hubBoxExpand/removeBoxId', this.boxId);
        this.$emit('onExpand', false);
      }
    },
    value(val) {
      if (val !== null) {
        this.expanded = val ? [0] : [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-bar {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
