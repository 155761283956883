<template>
  <div class="moin-fade-in mt-10 d-flex justify-space-between">
    <div>
      <v-btn
        outlined
        class="mr-2"
        @click="$emit('onBack')"
        :disabled="locked"
      >
        {{ $t('common.back') }}
      </v-btn>
      <v-btn
        v-if="!hideNextBtn"
        color="secondary"
        depressed
        @click="gotoNext"
        :disabled="locked"
      >
        {{ $t('common.next') }}
      </v-btn>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DemoBtns',
  components: {},
  props: {
    hideNextBtn: Boolean,
    // Lock button until the step changes
    shouldLock: Boolean,
  },
  data() {
    return {
      // Disable the button until it reaches the next step
      locked: false,
    };
  },
  computed: {
    ...mapGetters('demo', ['step']),
  },
  methods: {
    gotoNext() {
      if (this.shouldLock) this.locked = true;
      this.$emit('onNext');
    }
  },
  watch: {
    // When step state changes, release the lock
    step() { this.locked = true },
  }
};
</script>
