<template>
  <div
    class="demo-container"
    :class="[$vuetify.breakpoint.mdAndDown && 'mx-auto', $vuetify.breakpoint.lgAndUp && 'demo-container--lg']"
  >
    <v-row>
      <v-col>
        <DemoStepError v-if="hasError" />
        <DemoStepLoad
          v-else-if="
            step === stepSlugs.stepCompanyLoading ||
            step === stepSlugs.stepIntentsLoading ||
            step === stepSlugs.stepQuestionsLoading
          "
        />
        <DemoStepPersona v-else-if="step === stepSlugs.stepPersona" />
        <DemoStepEditPersona v-else-if="step === stepSlugs.stepEditPersona" />
        <DemoStepQuestions v-else-if="step === stepSlugs.stepQuestions" />
        <DemoStepIntents v-else-if="step === stepSlugs.stepIntents" />
        <DemoStepContact v-else-if="step === stepSlugs.stepContact" />
        <DemoStepFinal v-else-if="step === 4" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DemoStepLoad from '@/components/demo/DemoStepLoad.vue';
import DemoStepper from '@/components/demo/components/DemoStepper.vue';
import DemoStepQuestions from '@/components/demo/DemoStepQuestions.vue';
import DemoStepIntents from '@/components/demo/DemoStepIntents.vue';
import DemoStepContact from '@/components/demo/DemoStepContact.vue';
import { stepSlugs } from '@/components/demo/stepSlugs';
import DemoStepPersona from '@/components/demo/DemoStepPersona.vue';
import DemoStepEditPersona from '@/components/demo/DemoStepEditPersona.vue';
import DemoStepError from '@/components/demo/DemoStepError.vue';

export default {
  name: 'DemoContent',
  components: {
    DemoStepError,
    DemoStepEditPersona,
    DemoStepPersona,
    DemoStepLoad,
    DemoStepper,
    DemoStepQuestions,
    DemoStepIntents,
    DemoStepContact,
  },
  data() {
    return {
      stepSlugs,
    };
  },
  computed: {
    ...mapGetters('demo', [
      'step',
      'hasError',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.demo-container {
  padding: $spacer * 6;
  max-width: 700px;
}
.demo-container--lg {
  margin-top: 5vh;
  margin-left: 10vw;
}
</style>
