<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <HubEditorFrame
      v-model="isSidebarOpen"
      :title="$t('botSettings.regexp.title')"
      :loading="isLoading"
      :draft="isChanged"
      saveIcon="cloud_upload"
      @onReset="reset"
      @onSave="save"
      back="/settings"
      :sidebar-items="[{ title: '', icon: 'info', name: 'info' }]"
    >
      <template v-slot="{ isLoading }">

        <v-container fluid class="white pa-6">

          <v-row class="mb-1">
            <v-col cols="12" lg="6" xl="4">
              <h2 class="text-h6">{{ $t('botSettings.regexp.title') }}</h2>
              <div class="text-body-2">{{ $t('botSettings.regexp.description') }}</div>
            </v-col>
          </v-row>

          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col>
                <v-expansion-panels v-model="panel" multiple>
                  <!-- <div v-for="(item, index) in regexpArray" :key="index"> -->

                  <template v-for="(item, index) in regexpArray">
                  <!-- <template v-for="item in regexpArray"> -->
                    <!-- RegExp element configuration -->
                    <v-expansion-panel>
                      <v-expansion-panel-header class="m-0 p-0">
                        <h3 class="text-h6 primary--text">{{  item.displayName || 'No name' }}</h3>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <!-- Each config -->
                        <v-row>
                          <v-col class="my-0 py-0">
                            <v-card-title class="pb-1 px-0">{{ $t('botSettings.regexp.displayName') }}</v-card-title>
                            <v-text-field v-model="item.displayName"
                              label="Display name"
                              outlined
                              :rules="rules.regexFieldRule()"
                              @input="handleChange"
                            />
                          </v-col>
                          <v-col class="my-0 py-0">
                            <v-card-title class="pb-1 px-0">{{ $t('botSettings.regexp.parameter') }}</v-card-title>
                            <v-text-field v-model="item.parameter"
                              label="Parameter"
                              outlined
                              :rules="rules.regexFieldRule(index, 'parameter')"
                              @input="handleChange"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="my-0 pt-0">
                            <v-card-title class="pb-1 px-0">{{ $t('botSettings.regexp.regexp') }}</v-card-title>
                            <v-text-field v-model="item.expression"
                              label="Expression"
                              outlined
                              :rules="rules.regexFieldRule(index, 'regexp')"
                              @input="handleChange"
                            />
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col class="d-flex justify-end">
                            <v-btn
                              depressed
                              small
                              color="error"
                              @click="removeField(index)"
                            >
                              {{ $t('common.delete') }}
                              <v-icon right>delete_outline</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>

                        <!-- Each config -->
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                </template>
              </v-expansion-panels>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <!-- <v-divider/> -->
        <!-- <v-container fluid class="white pa-6">
          <v-row>
            <v-col cols="12" lg="6" xl="4">
              <h2 class="text-h6">{{ $t('botSettings.regexp.accessViaSecretUrl.title') }}</h2>
              <div class="text-body-2">{{ $t('botSettings.regexp.accessViaSecretUrl.description') }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <InfoBox class="my-4" :text="$t('botSettings.regexp.accessViaSecretUrl.info')"/>
              <InfoBox icon="mdi-exclamation-thick" class="my-4" :text="$t('botSettings.regexp.accessViaSecretUrl.note')"/>
            </v-col>
          </v-row>
        </v-container> -->
        <!-- <v-divider/> -->

        <!-- Bottom part | Create button -->
        <template>
          <v-card-text class="white flex-grow-1">
            <div v-if="!regexpArray.length" class="mb-5 text-body primary--text lighten-2">
              {{ $t("botSettings.regexp.notExisted") }}
            </div>
            <div class="text-right">
              <v-btn outlined color="primary lighten-3" :loading="isLoading" @click="addField()">
                {{ $t("botSettings.regexp.new") }}
                <v-icon right>{{ iconAdd }}</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </template>

        <v-snackbar v-model="notSavedError.active" :timeout="notSavedError.timeout">
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-alert-circle</v-icon>
            <div>{{ notSavedError.message }}</div>
          </div>
        </v-snackbar>
        
      </template>
    </HubEditorFrame>
  </v-app>
</template>

<script>
import _ from 'lodash';
import { iconAdd } from '@/utils/icons';
import { mapActions, mapGetters } from 'vuex';
import { TrackingService } from '@/services/tracking';
import Appbar from '@/components/Appbar.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import HubEditorFrame from '../components/hub/HubEditorFrame.vue';

import HubMenu from '@/components/hub/HubMenu.vue';
import InfoBox from '@/components/common/InfoBox.vue';

export default {
  name: 'SettingsRegExp',
  components: {
    HubMenu,
    Appbar,
    HubEditorFrame,
    HubBoxSimple,
    BtnSquare,
    InfoBox,
  },
  props: [],
  data() {
    return {
      deleteDialogOpen: true,
      panel: [], // (Collapse open) Basic config as default

      isChanged: false,
      isInitialized: false,
      isLoading: false,
      isSidebarOpen: false,
      nextUrl: null,
      iconAdd,
      notSavedError: {
        active: false,
        message: this.$t('common.notSavedError'),
        timeout: 6000,
      },

      initialConfig: [],
      regexpArray: [],
      active: false,
      accessViaSecretAllowed: false,
      rules: {
        regexFieldRule: (index, key) => [
          (v) => !!v || this.$t('integrations.rules.requiredField'),
          (v) => {
            if (key !== 'parameter') return true; // Skip validation for other params
            const isUnique = this.regexpArray?.filter((_, i) => i !== index)?.every((obj) => obj[key] !== v ) || false;
            return isUnique || this.$t('integrations.rules.uniqueField')
          },
          (v) => {
            if (key !== 'regexp') return true; // Use validation only for 'RegExp'
            try {
              const regex = new RegExp(v);
              const groupCount = (new RegExp(v + '|')).exec('').length - 1;
              if (groupCount !== 1) {
                return this.$t('integrations.rules.oneCapturingGroup');
              }
            } catch (error) {
              return this.$t('integrations.rules.validRegexp');
            }
            return true;
          }
        ],
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('bots', ['currentBot', 'currentBotId', 'getBotSettings']),
  },
  watch: {
    active(val, oldVal) { this.handleChange(val, oldVal); },
    accessViaSecretAllowed(val, oldVal) { this.handleChange(val, oldVal); },
  },
  methods: {
    ...mapActions('bots', ['fetchRegExp', 'setRegExp']),
    /**
     * Triggers component <v-snackbar /> that shows an error for certain amount of time.
     * Then automatically sets to active = false, after provided timeout
     * @param {Object} param
     * @param {boolean} [param.active=true] - Wether to show or hide error component. Default 'true'.
     * @param {string} [param.message='notSavedError'] - Error message text. Default 'this.$t('nlp.notSavedError')'.
     * @param {number} [param.timeout=6000] - Timeout in milliseconds (how long the message should be displayed). Default '6000' ms.
     */
     _showNotSavedError({ active = true, message, timeout = 6000 } = {}) {
      this.$set(this.notSavedError, 'active', active);
      this.$set(this.notSavedError, 'message', message || this.$t('common.notSavedError'));
      this.$set(this.notSavedError, 'timeout', Number(timeout));
    },
    addField() {
      this.regexpArray.push({
        type: 'regexp',
        displayName: `RegExp_${(this.regexpArray.length || 0) + 1}`,
        expression: "",
        parameter: ""
      });

      // Add new item opened in panel
      this.panel.push(this.regexpArray?.length - 1);
      this.isChanged = true;
    },
    removeField(index) {
      console.log('Remove: ', index);
      this.regexpArray.splice(index, 1);
      this.isChanged = true;
    },
    handleChange(val, oldVal) {
      if (!this.isInitialized) return;
      if (val === oldVal) return;
      this.isChanged = true;
    },
    reset() {
      this.regexpArray =  _.cloneDeep(this.initialConfig);
      this.isChanged = false;
    },
    parseConfig() {
      const regexpArray = this.regexpArray?.map((el) => ({ 
        type: 'regexp',
        displayName: el.displayName,
        expression: el.expression,
        parameter: el.parameter
      }));
      return regexpArray;
    },

    async fetchConfig() {
      this.regexpArray = await this.fetchRegExp({ botId: this.currentBotId });
      this.initialConfig = _.cloneDeep(this.regexpArray);

      return this.regexpArray;
    },

    async save() {
      const isValid = this.$refs.form.validate();

      if (!isValid) {
        this.showNoSaveError = true;
        return false;
      } else {
        this.showNoSaveError = false;
      }

      this.isLoading = true;
      const { success, data: updatedArray, error } = await this.setRegExp({
        botId: this.currentBotId,
        regexp: this.parseConfig()
      });

      if (success && updatedArray) {
        this.regexpArray = updatedArray;
        this.isChanged = false;
      }

      if (!success) {
        this._showNotSavedError({ message: `ERROR: ` + error.message, timeout: 6_000 });
      }

      this.isLoading = false;
      // this.trackIt('save', updatedArray);
    },
    async init() {
      this.isLoading = true;
      // Add fetch from bot.nlp array | only type regexp
      const fileSettings = await this.fetchConfig();

      this.initialConfig = this.parseConfig();

      this.isLoading = false;

      this.$nextTick(() => {
        this.isInitialized = true;
      });
    },
    trackIt(type = 'view', payload) {
      const body = {
        botId: this.currentBotId,
        type: type,
        source: 'SettingsRegExp.vue',
      };
      if (payload) {
        body.payload = payload;
      }
      TrackingService.trackSettingsRegExp(body);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChanged && !this.nextUrl) {
      this.isLostDialogOpen = true;
      this.nextUrl = to;
      return next(false);
    }
    return next(true);
  },
  created() {
    this.init();
  },
};
</script>
