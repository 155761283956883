<script>
export default {
  name: 'WidgetBotMessage',
  props: {
    // If defined: use the avatar at the URL
    "avatar": {
      type: String,
    },
    // Whether to show the little speech arrow
    "arrow": {
      type: Boolean,
      default: false,
    },
    "text": {
      type: String,
      default: "Herzlich Willkommen",
    },
    // Optional: Control the color of the chat bubble
    "bgColor": {
      type: String,
      default: null,
    },
    // Optional: Control the color of the chat bubble text
    "txtColor": {
      type: String,
      default: null,
    }
  },
  computed: {
    styles() {
      const styles = [];
      if (this.bgColor) styles.push(`background-color: ${this.bgColor}`);
      if (this.txtColor) styles.push(`color: ${this.txtColor}`);
      return styles.join(";")
    },
  },
};
</script>

<template>
  <div class="bot-message-wrapper" :class="{'with-avatar': !!avatar}">
    <div v-if="avatar" class="avatar-container">
      <img
        :src="avatar"
        alt="Avatar"
        class="bot-avatar"
      >
    </div>
    <svg v-if="arrow || !!avatar" width="10" height="10" xmlns="http://www.w3.org/2000/svg">
      <polygon points="0,0 100,100 100,0" :fill="bgColor ? bgColor : 'white'"/>
    </svg>
    <span
      class="bot-message"
      :class="{ 'with-arrow': arrow || !!avatar }"
      :style="styles"
    >
      {{ text }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.bot-message-wrapper {
  padding-left: calc(35px + 12px);

  /* From the widget itself */
  margin-bottom: 7px;
  display: flex;
  width: 100%;
  padding-right: 35px;
  position: relative;
}
.avatar-container {

  /* From the widget itself */
  position: absolute;
  top: -5px;
  left: 0;

  width: 44px;
  height: 44px;
  min-width: 44px;
  min-height: 44px;

  img {
    border-radius: 44px;
    border: solid 2px white;

    /* From the widget itself */
    background: #d3d3d3;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    object-position: center center;
    align-self: flex-end;
    transform: scale(1.1);
    transition: transform .15s ease-in-out;
  }
}
.bot-message {
  line-height: 1.25em;

  /* From the widget itself */
  white-space: pre-line;
  display: inline-flex;
  padding: 10px 12px;
  font-size: 1.5em;
  font-weight: 400;
  background: white;
  color: #333333;
  border: 1px solid;
  border-color: var(--moin-message-bot-bordercolor, transparent);
  border-radius: 6px;

  margin-left: 10px;
  font-family: "Rubik",Helvetica,Arial,sans-serif;

  &.with-arrow {
    border-radius: 0 6px 6px 6px;
    margin-left: 0;
  }
}
</style>