<!--
  This component is a variation of the IconNumberTeaser component (originally based on it).

  It is used to display a Value with an Icon and a Title.
  With extra features, it can display:
  - minimum value (should be provided)
  - maximum values (should be provided)
-->

<template>
  <div :style="{ minHeight: hideCompare ? '80px' : '110px' }">

   <div class="d-flex gap-2 items-start pa-4">
     <v-icon v-if="!noIcon" color="primary lighten-4" size="32" left>{{ icon }}</v-icon>

     <div class="d-flex flex-column">
       <!-- Top -->
       <h2 v-if="title" class="primary--text pb-0" :class="[titleHeading ? 'text-h6' : 'text-body-1']">
         {{ title }}
       </h2>

       <!-- Current value -->
       <div class="pa-0 d-flex pr-2">
         <v-skeleton-loader
           v-if="loading"
           type="image"
           height="28"
           width="128"
           class="my-2"
         />

         <h3 v-else-if="noData" :class="`pt-1 grey--text text--darken-1 ${noIcon ? 'text-h1' : 'text-h4'}`">
           0
         </h3>

         <template v-else-if="valueString">
           <h3 :class="`text-truncate pt-1 text-center ${noIcon ? 'text-h2' : 'text-h4'}`">
             {{ valueString }}
           </h3>
         </template>

         <template v-else>
           <h3 v-if="isPercent && !isNaN(newValue)" :class="`pt-1 ${noIcon ? 'text-h1' : 'text-h4'}`">
             {{ formatNumberFixed(newValue) }}<span>%</span>
           </h3>
           <h3 v-else :class="`pt-1 ${noIcon ? 'text-h1' : 'text-h4'}`">
             {{ newValue }}
           </h3>
         </template>
       </div>

     </div>
   </div>

    <!-- Bottom -->
    <div v-if="!noData || loading" class="moin-bg-grey-lightest py-3 px-4">
      <div v-if="loading" class="d-flex gap-4">
        <v-skeleton-loader type="image" height="24" width="72" />
        <v-skeleton-loader type="image" height="24" width="72" />
      </div>

      <!-- Margin-left: *Optically* align text with icon -->
      <div v-else class="d-flex gap-4 items-center" style="margin-left: 3px">
        <!-- Min -->
        <div class="d-flex items-center gap-1">
          <!-- Label -->
          <span class="grey--text text-body-2 text--darken-1">
            {{ minValueText }}
          </span>

          <!-- Value -->
          <div class="text-body-2 primary--text">
            <div v-if="!noData && !hideCompare">
              <span v-if="minValue === null || minValue === undefined">~</span>
              <span v-if="isPercent && !isNaN(minValue)">{{ formatNumberFixed(minValue) }}%</span>
              <div v-else class="text-truncate">{{ minValue }}</div>
            </div>
          </div>
        </div>

        <!-- Max -->
        <div class="d-flex items-center gap-1">
          <!-- Label -->
          <span class="grey--text text-body-2 text--darken-1">
            {{ maxValueText }}
          </span>

          <!-- Value -->
          <div class="pa-0 d-flex justify-center items-end primary--text ">
            <template v-if="!noData && !hideCompare">
              <span v-if="maxValue === null || maxValue === undefined">~</span>
              <span v-if="isPercent && !isNaN(maxValue)">{{ formatNumberFixed(maxValue) }}%</span>
              <span v-else class="text-truncate">{{ maxValue }}</span>
            </template>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import NumberTeaserUtil from '@/utils/numberTeaser';
import { formatNumberFixed } from '@/utils/formatNumber';

export default {
  name: 'IconNumberTeaserMinMaxAvg',
  data() {
    return {
      formatNumberFixed
    }
  },
  props: {
    title: {
      type: String,
    },
    titleHeading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'speed',
    },
    newValue: {
      type: Number,
      default: 0,
    },
    valueString: {
      type: String,
    },
    isPercent: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    noData: {
      type: Boolean,
      default: false,
    },
    hideCompare: {
      type: Boolean,
      default: false,
    },
    minValue: {
      type: [Number, String],
      default: null,
    },
    minValueText: {
      type: String,
      default: 'Min',
    },
    maxValue: {
      type: [Number, String],
      default: null,
    },
    maxValueText: {
      type: String,
      default: 'Max',
    },
  },
  computed: {
    noIcon() { return this.icon === 'no-icon' },
    changedPercent() {
      return NumberTeaserUtil.getChangedPercent({
        newValue: this.newValue,
        isPercent: this.isPercent,
      });
    },
    changedToolTip() {
      const {
        change,
        changeString,
        beforeString,
      } = NumberTeaserUtil.getChangedStrings({
        newValue: this.newValue,
        isPercent: this.isPercent,
      });

      if (change > 0) {
        return this.$t('stats.iconNumberTeaser.tooltipMore', {
          change: changeString,
          before: beforeString,
        });
      } else {
        return this.$t('stats.iconNumberTeaser.tooltipLess', {
          change: changeString,
          before: beforeString,
        });
      }
    },
  },
};
</script>
