<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <HubContent :isSidebarOpen="isSidebarOpen">
      <template v-slot:navbar>
        <HubContentNavbar :title="$t('settingsLivechat.livechatStats.title')">
          <template v-slot:toolbar>
            <BtnSquare
              icon="view_sidebar"
              @click="() => (isSidebarOpen = !isSidebarOpen)"
            />
          </template>
        </HubContentNavbar>
      </template>
      <template v-slot:sidebar>
        <HubContentSidebarSimple
          :isOpen="isSidebarOpen"
          @onClose="() => (isSidebarOpen = false)"
        >
          <StatsOverviewSidebar />
        </HubContentSidebarSimple>
      </template>
      <template v-slot:content>
        <div class="pa-3">
          <v-card flat class="pb-6 px-2">
            <!-- Statistic -->
            <div class="d-flex flex-wrap py-2">
              <v-row>
                <v-col cols="12" md="4">
                  <v-card>
                    <IconNumberTeaserMinMaxAvg
                      :title="$t('settingsLivechat.livechatStats.totalChats')"
                      icon="mdi-message-text-outline"
                      :valueString="`${overallStatistics.totalChats}`"
                      :loading="loading"
                      :noData="overallStatistics.totalChats === 0"
                      :titleHeading="true"
                      :min-value-text="'Active'"
                      :min-value="`${overallStatistics.activeChats}`"
                      :max-value-text="'Finished'"
                      :max-value="`${overallStatistics.totalChats - overallStatistics.activeChats}`"
                    />
                  </v-card>
                  <v-card class="mt-4">
                    <IconNumberTeaserMinMaxAvg
                      :title="$t('settingsLivechat.livechatStats.avgDuration')"
                      icon="mdi-timer-outline"
                      :valueString="`${formatDuration(overallStatistics.avgDuration, { showHours: true })}`"
                      :loading="loading"
                      :noData="overallStatistics.avgDuration === 0"
                      :titleHeading="true"
                      :min-value="`${formatDuration(overallStatistics.minDuration, { showHours: true })}`"
                      :max-value="`${formatDuration(overallStatistics.maxDuration, { showHours: true })}`"
                    />
                  </v-card>
                  <v-card class="mt-4">
                    <IconNumberTeaserMinMaxAvg
                      :title="$t('settingsLivechat.livechatStats.avgWaitingTime')"
                      icon="mdi-clock-outline"
                      :valueString="`${formatDuration(overallStatistics.avgWaitingTime, { showHours: true })}`"
                      :loading="loading"
                      :noData="overallStatistics.avgWaitingTime === 0"
                      :titleHeading="true"
                      :min-value="`${formatDuration(overallStatistics.minWaitingTime, { showHours: true })}`"
                      :max-value="`${formatDuration(overallStatistics.maxWaitingTime, { showHours: true })}`"
                    />
                  </v-card>
                </v-col>

                <v-col cols="12" md="8">
                  <v-card class="pa-4 h-100" style="height: 100%;">
                    
                    <v-card-title class="text-h6 primary--text">
                      {{ $t('settingsLivechat.livechatStats.chatDistributionByForms') }}
                    </v-card-title>
                    <div v-if="chatDistributionData.length === 0" class="d-flex justify-center align-center flex-column">
                      <v-icon style="height: 48px;" color="primary lighten-4" size="32" left>mdi-chart-pie-outline</v-icon>
                    </div>
                    <PieChart v-else :data="chatDistributionData" />
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <!-- Statistic -->

            <v-divider class="my-4" />

            <v-row class="ma-0">
              <v-col cols="3">
                <v-select
                  v-model="selectedOption"
                  :items="dropdownOptions"
                  hide-details
                  item-text="label"
                  item-value="value"
                  @change="onDateRangeChange"
                  :label="$t('settingsLivechat.livechatStats.timePeriod')"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="3">
                <div class="d-flex" v-if="showDates">
                  <v-menu
                    v-model="datePickerA"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fromDate"
                        :label="$t('settingsLivechat.livechatStats.from')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fromDate"
                      @change="dateChanged"
                      :allowed-dates="allowedDates"
                      @input="datePickerA = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-model="datePickerB"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="toDate"
                        :label="$t('settingsLivechat.livechatStats.to')"
                        readonly
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="toDate"
                      @change="dateChanged"
                      :allowed-dates="allowedDates"
                      @input="datePickerB = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="6">
                  <v-text-field
                    v-model="searchText"
                    hide-details
                    :label="$t('common.search')"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <ChipFilter
                  class="mx-2 mb-4"
                  :options="computedChipFilterOptions"
                  @onChange="changeFilterTag"
                />
              </v-col>
            </v-row>

            <v-data-table
              class="mt-6"
              :headers="headers"
              :items="filteredChats"
              item-key="chatId"
              :search="searchText"
              :loading="loading"
              show-group-by
              :hide-default-footer="true"
              :items-per-page="-1"
            >
              <template v-slot:item.chatDuration="{ item }">
                {{ formatDuration(item.chatDuration) }}
              </template>
              <template v-slot:item.waitingTime="{ item }">
                {{ formatDuration(item.waitingTime) }}
              </template>
              <template v-slot:item.agentName="{ item }">
                <ProfileAvatar
                  :profilePicture="item.agentPicture"
                  placeholderIcon="mdi-face-agent"
                  small
                />
                <span class="ml-1">{{ item.agentName }}</span>
              </template>
              <template v-slot:group.header="{ items, groupBy, group, isOpen, toggle, remove }">
                <td :colspan="headers.length - 1">
                  <v-icon @click="toggle">
                    {{ isOpen ? 'mdi-menu-down' : 'mdi-menu-right' }}
                  </v-icon>
                  <ProfileAvatar
                    v-if="groupBy[0] === 'agentName'"
                    :profilePicture="items[0].agentPicture"
                    placeholderIcon="mdi-face-agent"
                    small
                    class="mr-1"
                  />
                  {{ group }} ({{ $t('settingsLivechat.livechatStats.avgChatDuration') }}: {{ averageDurationForField(group, groupBy[0]) }})
                  <v-icon @click="remove">mdi-close</v-icon>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </template>
    </HubContent>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import HubContent from '@/components/hub/HubContent.vue';
import HubContentNavbar from '@/components/hub/HubContentNavbar.vue';
import HubContentSidebarSimple from '@/components/hub/HubContentSidebarSimple.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import StatsOverview from '@/components/stats/StatsOverview.vue';
import StatsOverviewSidebar from '@/components/stats/StatsOverviewSidebar.vue';
import LivechatService from '@/services/livechat';
import ProfileAvatar from '@/components/common/ProfileAvatar.vue';
import ChipFilter from '@/components/common/ChipFilter.vue';
import moment from 'moment';
import PieChart from '@/components/common/PieChart.vue';
import IconNumberTeaser from '@/components/stats/components/IconNumberTeaser.vue';
import IconNumberTeaserMinMaxAvg from '@/components/stats/components/IconNumberTeaserMinMaxAvg.vue';

export default {
  name: 'LivechatStats',
  components: {
    HubMenu,
    Appbar,
    HubContent,
    HubContentNavbar,
    HubContentSidebarSimple,
    BtnSquare,
    StatsOverview,
    StatsOverviewSidebar,
    ProfileAvatar,
    ChipFilter,
    PieChart,
    IconNumberTeaser,
    IconNumberTeaserMinMaxAvg,
  },
  data() {
    return {
      isSidebarOpen: false,
      datePickerA: false,
      datePickerB: false,
      fromDate: null,
      toDate: moment().format('YYYY-MM-DD'),
      statsData: null,
      showDates: false,
      searchText: '',
      loading: false,
      selectedOption: 30,
      dropdownOptions: [
        { label: this.$t('settingsLivechat.livechatStats.last30Days'), value: 30 },
        { label: this.$t('settingsLivechat.livechatStats.last60Days'), value: 60 },
        { label: this.$t('settingsLivechat.livechatStats.last90Days'), value: 90 },
        { label: this.$t('settingsLivechat.livechatStats.custom'), value: 0 },
      ],
      overallStatistics: {
        activeChats: 0,
        totalChats: 0,
        // Chat Duration
        minDuration: null,
        avgDuration: 0,
        maxDuration: null,
        // Chat User waiting time
        minWaitingTime: null,
        avgWaitingTime: 0,
        maxWaitingTime: null,
        chatDistributionByForms: {}, // { formId: { count: 0, duration: {min: null,avg: 0,max: null}, waiting: {min: null,avg: 0,max: null}, } }
      },
      formByIntentObject: {},
      filterTag: []
    };
  },
  computed: {
    ...mapGetters('forms', ['forms']),
    ...mapGetters('bots', ['currentBotId', 'viewableBotId']),
    ...mapGetters('livechatManagement', ['organization']),
    ...mapGetters('livechat', ['botHasLivechat']),
    computedChipFilterOptions() {
      if (!this.organization || !this.organization.tags) return [];
      return this.organization.tags.map(tag => ({
        name: tag.name,
        value: tag.name,
        icon: 'mdi-tag',
        isActive: false
      }));
    },
    headers() {
      return [
        { text: this.$t('settingsLivechat.livechatStats.chatId'), value: 'chatId', groupable: false },
        { text: this.$t('settingsLivechat.livechatStats.date'), value: 'chatDate', groupable: false, },
        { text: `${this.$t('settingsLivechat.livechatStats.duration')} (${this.$t('settingsLivechat.livechatStats.avg')}: ${this.averageChatDuration})`, value: 'chatDuration', groupable: false },
        { text: this.$t('settingsLivechat.livechatStats.waitingTime'), value: 'waitingTime', groupable: false },
        { text: this.$t('settingsLivechat.livechatStats.agent'), value: 'agentName' },
        { text: this.$t('settingsLivechat.livechatStats.formId'), value: 'chatFormId' },
      ];
    },
    chats() {
      if (!this.statsData) return [];
      return this.statsData.map(chat => ({
        chatId: chat.uuid,
        chatDuration: chat.statistics?.duration ?? 0,
        chatDate: moment(chat.created_at).format('YYYY-MM-DD').toString(),
        waitingTime: chat.statistics?.assignedAt ? moment(chat.statistics?.assignedAt).diff(moment(chat.created_at), 'seconds') : 'N/A',
        agentName: chat.agent?.name ?? 'N/A',
        agentPicture: chat.agent?.profilePicture,
        chatFormId: chat.currentBox || 'N/A',
        tags: chat.tags || []
      }));
    },
    filteredChats() {
      let chats = this.chats;
      if (this.filterTag.length > 0) {
        chats = chats.filter(chat => chat.tags && chat.tags.some(t => this.filterTag.includes(t)));
      }
      return chats;
    },
    averageChatDuration() {
      const totalDuration = this.chats.reduce((acc, chat) => acc + chat.chatDuration, 0);
      const averageSeconds = (totalDuration / this.chats.length) || 0;
      const averageMinutes = Math.floor(averageSeconds / 60);
      const averageSecondsRemaining = Math.floor(averageSeconds % 60);
      return `${averageMinutes}m${averageSecondsRemaining}s`;
    },
    chatDistributionData() {
      const data = Object.entries(this.overallStatistics.chatDistributionByForms).map(([key, data]) => {
        const form = this.formByIntentObject[key];
        return {
          label: form?.displayName || key,
          value: data.count,
        };
      });

      return data;
    },
  },
  async mounted() {
    if (this.botHasLivechat) {
      this.setDatesFromOption();
      if (this.organization) {
        this.loadStats();
      } else {
        await this.loadOrganization();
        await this.loadStats();
      }
    }
  },
  methods: {
    async init() {
      this.formByIntentObject = this.forms.reduce((acc, form) => {
        acc[form.intent] = form;
        return acc;
      }, {});
    },
    async loadOrganization() {
      await this.$store.dispatch('livechatManagement/init', this.currentBotId);

    },
    async loadStats() {
      if (!this.organization) await this.loadOrganization();

      this.loading = true;
      this.statsData = await LivechatService.getStatsForOrganization(
        this.organization.uuid,
        this.fromDate, 
        moment(this.toDate).add(1, 'day').format('YYYY-MM-DD'), // add 1 day to include the selected date
        this.viewableBotId || this.currentBotId,
      );
      this.aggregateStats();
      this.loading = false;
    },
    resetCalculated() {
      this.overallStatistics = {
        totalChats: 0,
        minDuration: null,
        avgDuration: 0,
        maxDuration: null,
        minWaitingTime: null,
        avgWaitingTime: 0,
        maxWaitingTime: null,
        chatDistributionByForms: {},
      };
    },
    aggregateStats() {
      if (!this.statsData) return;
      this.resetCalculated();

      // ! TODO: Discuss how we should treat Livechats that are still running
      // Shall we skip them from statistic, but show in table?
      // ! Important, because will show not proper statistics
      // LiveChats that are still open does not have .duration, and also waitingTime will rise (if user is waiting)
      // LiveChats that are still 'running' or 'waiting' for agent

      this.overallStatistics.totalChats = this.statsData.length || 0;
      this.overallStatistics.avgDuration = this.overallStatistics.totalDuration / (this.overallStatistics.totalChats || 1);

      let minDuration = null, maxDuration = null, totalDuration = 0, minWaitingTime = null, maxWaitingTime = null, totalWaitingTime = 0, activeChats = 0;

      this.statsData.forEach(chat => {
        const duration = chat.statistics?.duration || 'N/A'; // With 'N/A' it shows proper Minimum

        // Skip from Statistics if Chat is still running
        if (duration === 'N/A') return activeChats += 1;

        const waitingTime = chat.statistics?.assignedAt
          ? moment(chat.statistics?.assignedAt).diff(moment(chat.created_at), 'seconds')
          : 'N/A';

        if (minDuration === null || duration < minDuration) minDuration = duration;
        if (maxDuration === null || duration > maxDuration) maxDuration = duration;
        totalDuration += duration;

        if (minWaitingTime === null || waitingTime < minWaitingTime) minWaitingTime = waitingTime;
        if (maxWaitingTime === null || waitingTime > maxWaitingTime) maxWaitingTime = waitingTime;
        totalWaitingTime += waitingTime;

        const formId = chat.currentBox || 'undefined';

        if (!this.overallStatistics.chatDistributionByForms[formId]) {
          this.overallStatistics.chatDistributionByForms[formId] = {
            count: 0,
            duration: { min: null, avg: 0, max: null },
            waiting: { min: null, avg: 0, max: null }
          };
        }
        this.overallStatistics.chatDistributionByForms[formId].count = this.overallStatistics.chatDistributionByForms[formId]?.count ? this.overallStatistics.chatDistributionByForms[formId]?.count + 1 : 1;
      });

      this.overallStatistics.activeChats = activeChats;
      const totalFinishedChats = this.overallStatistics.totalChats - activeChats;

      this.overallStatistics.minDuration = minDuration;
      this.overallStatistics.maxDuration = maxDuration;
      this.overallStatistics.avgDuration = Math.round(totalDuration / (totalFinishedChats || 1));

      this.overallStatistics.minWaitingTime = minWaitingTime;
      this.overallStatistics.maxWaitingTime = maxWaitingTime;
      this.overallStatistics.avgWaitingTime = Math.round(totalWaitingTime / (totalFinishedChats || 1));
    },
    allowedDates: val => moment(val).isBefore(moment()),
    dateChanged() {
      this.loadStats();
    },
    onDateRangeChange() {
      if (this.selectedOption === 0) {
        this.showDates = true;
      } else {
        this.showDates = false;
        this.setDatesFromOption();
        this.loadStats();
      }
    },
    setDatesFromOption() {
      if (this.selectedOption === 0) return;
      const fromDate = moment().subtract(this.selectedOption, 'days').format('YYYY-MM-DD');
      this.fromDate = fromDate;
    },
    formatDuration(seconds, { showHours = false, zeroHours = false } = {}) {
      if (typeof seconds !== 'number') return 'N/A';

      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      const strings = [`${remainingSeconds.toString().padStart(2, '0')}s`];

      const min = showHours ? minutes : hours * 60 + minutes;
      strings.unshift(`${min}m`);

      if (showHours && (hours > 0 || zeroHours)) {
        strings.unshift(`${hours}h`);
      }

      const resultedString = strings.join(' ');
      return resultedString;
    },
    averageDurationForField(name, field) {
      const filteredChats = this.chats.filter(chat => chat[field] === name);
      const totalDuration = filteredChats.reduce((acc, chat) => acc + chat.chatDuration, 0);
      const averageSeconds = totalDuration / filteredChats.length;
      const averageMinutes = Math.floor(averageSeconds / 60);
      const averageSecondsRemaining = Math.floor(averageSeconds % 60);
      return `${averageMinutes}m${averageSecondsRemaining}s`;
    },
    changeFilterTag(indexList) {
      const filterValues = [];
      this.computedChipFilterOptions.forEach((item, index) => {
        item.isActive = false;
        if (indexList.includes(index)) {
          item.isActive = true;
          filterValues.push(item.value);
        }
      });
      this.filterTag = filterValues;
    }
  },
  watch: {
    viewableBotId() {
      this.loadStats();
    },
  },
  created() {
    this.init();
  },
};
</script>
