import { render, staticRenderFns } from "./BtnSquare.vue?vue&type=template&id=9cfc7686&scoped=true"
import script from "./BtnSquare.vue?vue&type=script&lang=js"
export * from "./BtnSquare.vue?vue&type=script&lang=js"
import style0 from "./BtnSquare.vue?vue&type=style&index=0&id=9cfc7686&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cfc7686",
  null
  
)

export default component.exports