import ApiService from './api';

/**
 * @typedef {Object} FallbackError
 * @property {'ERROR'} status
 * @property {number} code
 * @property {string} message
 */
/**
 * @param error
 * @returns {FallbackError}
 */
const fallbackError = (error) => {
  console.log(error);

  if (!error?.code || !error?.message) {
    return {
      status: 'ERROR',
      code: 0,
      message: 'An unknown error occurred',
    };
  }

  return {
    status: 'ERROR',
    code: error.code,
    message: error.message,
  };
}

const DemoService = {
  getLocalUserId() {
    return localStorage.getItem('moin-user-id');
  },
  setLocalUserId(userId) {
    localStorage.setItem('moin-user-id', userId);
  },
  getUserIdWithFingerprintId: async ({ fingerprintId }) => {
    try {
      const res = await ApiService.get(
        `/schaltzentrale/demo/init/${fingerprintId}`
      );
      return res?.data;
    } catch (error) {
      console.error(error);
      return fallbackError(error);
    }
  },
  /**
   * @deprecated - No longer used (called by Demo#handleStepCompany, which is itself also deprecated)
   */
  async getQuestionsWithCompany({ site, fingerprintId, userId, language }) {
    let res = null;
    try {
      if (!site) return false;

      const body = {
        companyDescription: {
          site,
        },
        language,
        userId: this.getLocalUserId() || null,
      };

      if (process.env.NODE_ENV !== 'production') {
        body.noSlack = true;
      }

      res = await ApiService.post(
        `/schaltzentrale/demo/generateTopQueries/${fingerprintId}`,
        body
      );

      if (res.data.status === 'ERROR') {
        return { status: 'ERROR', message: res.data.message, queries: [] };
      }

      return res.data;
    } catch (error) {
      return fallbackError(error);
    }
  },
  /**
   * Generate the top questions a specific persona (e.g. Sales Representative) would get on the site.
   * Generates multiple questions for multiple personas at once.
   * @param {string} site
   * @param {string} userId
   * @param {'de'|'en'|string} language
   * @param {Array<{id: string, name: string, description: string}>} personas An array of personas.
   * The `id` is an arbitrary identifier to find what question is for which agent, e.g. `salesRepresentative`).
   * This identifier is also used in the UI translation file.
   * @returns {Promise<FallbackError | {personas: Array<{persona: {id: string, name: string, description: string}, queries: {id: string, text: string}[]}>}>}
   */
  async getQuestionsWithCompanyUsingPersonas({ site, userId, language, personas }) {
    try {
      const body = {
        companyDescription: {
          site,
        },
        language,
        userId: userId || this.getLocalUserId() || null,
        personas,
      };

      if (process.env.NODE_ENV !== 'production') {
        body.noSlack = true;
      }

      const res = await ApiService.post(
        `/schaltzentrale/demo/v2/generateTopQueries/personas`,
        body
      );

      if (res.data.status === 'ERROR') {
        return fallbackError(res.data);
      }

      return res.data;
    } catch(error) {
      return fallbackError(error);
    }
  },
  /**
   * @param {string} url
   * @param {?string} [userId]
   * @returns {Promise<FallbackError | {status: string, message: any} | {status: 'OK', technologies: string[]}>}
   */
  async analyzeSiteTechnologies({ url, userId }) {
    if (!userId) userId = this.getLocalUserId() || null;

    try {
      const res = await ApiService.post(
        `/schaltzentrale/demo/v2/analyze/technologies`,
        { url, userId },
      );

      if (res.status !== 200) {
        return { status: 'ERROR', message: res.data.message };
      }

      return res.data;
    } catch(error) {
      return fallbackError(error);
    }
  },
  /**
   * @deprecated - No longer used.
   */
  async getIntentsWithQuestions({ excludedQueries, fingerprintId, userId}) {
    try {
      const body = {
        excludedQueries,
        userId: userId || this.getLocalUserId() || null,
      };

      if (process.env.NODE_ENV !== 'production') {
        body.noSlack = true;
      }

      const res = await ApiService.post(
        `/schaltzentrale/demo/generateTopIntents/${fingerprintId}`,
        body
      );
      return res.data;
    } catch (error) {
      return fallbackError(error);
    }
  },
  async getFeaturesWithQuestions({ excludedIds, personaIdentifier, userId, site, language = 'de' }) {
    try {
      const body = {
        excludedIds,
        personaIdentifier,
        userId: userId || this.getLocalUserId() || null,
        language,
        site,
      };

      if (process.env.NODE_ENV !== 'production') {
        body.noSlack = true;
      }

      const res = await ApiService.post(
        `/schaltzentrale/demo/v2/generateTopFeatures`,
        body
      );
      return res.data;
    } catch (error) {
      return fallbackError(error);
    }
  },
  async createDemoAccount({ contact, userId, language = 'de', site }) {
    try {
      if (process.env.NODE_ENV !== 'production') {
        contact.sendToHubspot = false;
      }

      const body = {
        contact: {
          ...contact,
          language,
          marketing: contact.marketing ? 'Ja' : 'Nein',
        },
        userId: userId || this.getLocalUserId() || null,
        site,
      };

      if (process.env.NODE_ENV !== 'production') {
        body.noSlack = true;
      }

      const res = await ApiService.post(
        `/schaltzentrale/demo/v2/submit`,
        body
      );

      return res.data;
    } catch (error) {
      return fallbackError(error);
    }
  },
  async getAnswerWithQuestion({ question, site, userId, fingerprintId }) {
    try {
      const body = {
        question,
        site,
        userId: userId || this.getLocalUserId() || null,
      };

      const res = await ApiService.post(
        `/schaltzentrale/demo/answer/${fingerprintId}`,
        body
      );

      return res.data;
    } catch (error) {
      return fallbackError(error);
    }
  },
  async getDemoRequests({ dateRange = null }) {
    let dates = '';

    if (dateRange) {
      dates = `?since=${dateRange[0].format(
        'YYYY-MM-DD'
      )}&to=${dateRange[1].format('YYYY-MM-DD')}`;
    }

    try {
      const res = await ApiService.get('/schaltzentrale/demo-hub' + dates);
      return res.data;
    } catch (error) {
      return { error };
    }
  },
  async addCommentToDemoRequest(botId, { comment }) {
    try {
      const response = await ApiService.post(
        `/schaltzentrale/demo-hub/comment/${botId}`,
        {
          comment,
        }
      );
      return response?.data;
    } catch (error) {
      return fallbackError(error);
    }
  },
  async deleteDemoRequest(demoId) {
    try {
      const response = await ApiService.delete(
        `/schaltzentrale/demo-hub/${demoId}`
      );
      return response?.data;
    } catch (error) {
      return fallbackError(error);
    }
  },
  /**
   * Generates a chatbot introduction message given a persona, and some company information that is stored in the DB on this user.
   * @param {?string} data.userId Temporary user ID created for the visitor, or the current logged in user
   * @param {string} data.language Language to use
   * @param {string} data.agentTitle The title of the agent, e.g. "Customer Success Manager"
   * @param {string} data.agentDescription What the agent does. Either the default prompt or a customized one if user edited it
   * @param {string} data.site The website the user is currently doing a demo for
   * @returns {Promise<FallbackError | {introduction: string | null, status: 'OK' | 'ERROR'}>}
   */
  async createAgentIntroduction(data) {
    if (!data.userId) data.userId = this.getLocalUserId() || null;

    if (process.env.NODE_ENV !== 'production') {
      data.noSlack = true;
    }

    try {
      const response = await ApiService.post(
        `/schaltzentrale/demo/v2/generateAgentIntroduction`,
        data,
      );
      return response?.data;
    } catch (error) {
      return fallbackError(error);
    }
  }
};

export default DemoService;
