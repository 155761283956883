<template>
  <DemoStep>
    <div>
      <DemoQuestion
        v-for="item in questions"
        :key="item.id"
        :question="item"
        class="mb-2"
        @onToggle="toggleQuestion(item)"
        @onAnswer="openAnswerDialog(item)"
      />
      <DemoBtns @onBack="previousStep" @onNext="next()" />
      <DemoAnswerDialog
        :open="isAnswerOpen"
        :question="questionForDialog"
        @onClose="isAnswerOpen = false"
      />
    </div>
  </DemoStep>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import DemoStep from '@/components/demo/DemoStep.vue';
import DemoQuestion from '@/components/demo/components/DemoQuestion.vue';
import DemoBtns from '@/components/demo/components/DemoBtns.vue';
import DemoAnswerDialog from '@/components/demo/components/DemoAnswerDialog.vue';

export default {
  name: 'DemoStepQuestions',
  components: { DemoStep, DemoQuestion, DemoBtns, DemoAnswerDialog },
  data() {
    return {
      isAnswerOpen: false,
      questionForDialog: '',
    };
  },
  computed: {
    ...mapGetters('demo', ['questions', 'isQuestionsDirty', 'hasError']),
  },
  methods: {
    ...mapMutations('demo', ['previousStep', 'nextStep', 'toggleQuestion']),
    async next() {
      // Continue to next step
      this.nextStep();
    },
    openAnswerDialog(item) {
      this.questionForDialog = item.text;
      this.isAnswerOpen = true;
    },
  },
};
</script>
