<script>
import DropDownItem from '@/components/common/dropdown/DropDownItem.vue';

export default {
  name: 'DropDownMenu',
  components: { DropDownItem },
  props: {
    /** @type {DropDownItem[]} */
    "items": {
      type: Array,
      default: () => [],
    },
    // Horizontal separator between each button
    "separator": {
      type: Boolean,
      default: false,
    },
    /**
     * Internal use:
     * - allows nested dropdowns to inherit parent options
     * - tells each dropdown item what level of nesting it is in
     * - carries preferences for buttons/dropdown down to them and any children
     * ... and more
     */
    "options": Object,
  },
  methods: {
    itemId(id, index) {
      if (id) return id;
      return `item-${index}-level-${this.$props.options.level ?? '0'}`;
    },
  },
};
</script>

<template>
  <div class="DropDownMenu">
    <template v-for="(item, i) in items">
      <slot name="item" :item="item" :index="i" :options="options">
        <!-- Normal menu item -->
        <DropDownItem
          v-if="!item.title"
          :class="{
            'DropDownItemSeparated': separator,
            [`dropdown-separated-${options.type}`]: true,
          }"
          :key="itemId(item.id, i)"
          :label="item.label"
          :id="itemId(item.id, i)"
          :to="item.to"
          :icon="item.icon"
          :icon-side="item.iconSide"
          :icon-color="item.iconColor"
          :preset="item.preset"
          :disabled="item.disabled"
          :items="item.items"
          :href="item.href"
          :options="options"
          :on-click="item.onClick"
        />
        <p v-else class="dropdown-item-title">{{ item.label }}</p>
      </slot>
    </template>
  </div>
</template>

<style scoped lang="scss">
.DropDownMenu {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  align-items: start;
}

.DropDownItemSeparated:not(:last-child) {
  border-bottom: solid thin;
}
.dropdown-item-title {
  width: 100%;
  padding: 1rem 1rem 0 1rem;
  margin: 0;
  color: var(--v-primary-lighten2);
  font-size: 0.875rem;
}

/**
 * Dropdown preset styles
 */
.dropdown-separated-default:not(:last-child) {
  border-color: var(--v-primary-base);
}
.dropdown-separated-companion:not(:last-child) {
  border-color: var(--moin-color-accent-5);
}
</style>