/**
 * @typedef {string} NlpTypesEnum
 * @enum {NlpTypesEnum}
 */
const NlpTypesEnum = {
  k1: /** @type {'k1'} */ ('k1'), // NOT SUPPORTED if bot doesn't have it already in nlp list
  k2: /** @type {'k2'} */ ('k2'),
  control: /** @type {'control'} */ ('control'),
  dialogflow: /** @type {'dialogflow'} */ ('dialogflow'), // We do not support it yet for new bots
  keywordmatch: /** @type {'keywordmatch'} */ ('keywordmatch'),
  language: /** @type {'language'} */ ('language'), // SHOW only when is in array (not supported)
  referencematch: /** @type {'referencematch'} */ ('referencematch'),
  selfconfidence: /** @type {'selfconfidence'} */ ('selfconfidence'),
  sentences: /** @type {'sentences'} */ ('sentences'),
  smalltalk: /** @type {'smalltalk'} */ ('smalltalk'),
  standardintent: /** @type {'standardintent'} */ ('standardintent'),
  wisdom: /** @type {'wisdom'} */ ('wisdom'),
  regexp: /** @type {'regexp'} */ ('regexp')
};

/** The NLP(s) that we do not allow to Turn ON if they were not added earlier (old logic) */
const NlpNotSupported = [NlpTypesEnum.dialogflow, NlpTypesEnum.language ];
const NlpSupported = Object.values(NlpTypesEnum).filter((t) => !NlpNotSupported.includes(t));

const K2ModelsEnum = {
  gpt: /** @type {'gpt'} */ ('gpt'),
  ollama: /** @type {'ollama'} */ ('ollama'),
}

// Base settings | All other fields should be filled at the BackEnd side
const nlp = {
  'k1': {
    type: NlpTypesEnum.k1,
    confidence: 0.5,
  },
  'k2': {
    type: NlpTypesEnum.k2,
    protocol: false,
    useIndex: true,
    simDocs: 15,
    maxConfidence: 0.9,
    modelId: K2ModelsEnum.gpt,
  },
  'control': {
    type: NlpTypesEnum.control,
    lang: ['de', 'en'],
  },
  // dialogflow - NOT SUPPORTED
  'keywordmatch': {
    type: NlpTypesEnum.keywordmatch,
  },
  // language - show as not supported
  'referencematch': {
    type: NlpTypesEnum.referencematch,
  },
  'selfconfidence': {
    type: NlpTypesEnum.selfconfidence,
    junior: true,
  },
  // sentences - 
  'smalltalk': {
    type: NlpTypesEnum.smalltalk,
    confidence: 0.8,
  },
  'standardintent': {
    type: NlpTypesEnum.standardintent,
    confidence: 0.5,
  },
  'wisdom': {
    type: NlpTypesEnum.wisdom,
    confidence: 0.9,
  },
}

/**
 * Generates NLP base by provided NLP type
 * @param {string} type 
 * @returns {any|null}
 */
const generateNlpBase = (type) => {
  return nlp[type] || null;
}

module.exports = {
  NlpTypesEnum,
  K2ModelsEnum,
  generateNlpBase,
  NlpSupported: NlpSupported.concat(), // Simply return new array | Otherwise => reference
};
