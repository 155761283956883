<template>
  <DemoStep slug="stepContact">
    <v-form ref="form" v-model="valid" lazy-validation>
      <div>
        <v-text-field
          outlined
          v-model="contact.company"
          :label="$t('demo.stepContact.labelCompanyName')"
          :rules="contactCompanyRules"
          required
          :validateOnBlur="valid"
        />
        <v-text-field
          outlined
          v-model="contact.name"
          :label="$t('demo.stepContact.labelName')"
          required
          :validateOnBlur="valid"
        />
        <v-text-field
          outlined
          v-model="contact.email"
          :label="$t('demo.stepContact.labelEmail')"
          :rules="contactEmailRules"
          required
          :validateOnBlur="valid"
        />
        <v-text-field
          outlined
          v-model="contact.phone"
          :label="$t('demo.stepContact.labelPhone')"
          required
          :rules="contactPhoneRules"
          :validateOnBlur="valid"
        />
        <div class="d-flex mb-4">
          <div class="flex-shrink-0 mr-2">
            <Checkbox
              :value="contact.marketing"
              @onClick="contact.marketing = !contact.marketing"
            />
          </div>
          <div
            class="text-body-2 primary--text text--lighten-2"
            v-html="$t('demo.stepContact.infoConsent')"
          ></div>
        </div>
        <div class="d-flex">
          <div class="flex-shrink-0 mr-2">
            <Checkbox
              :value="contact.privacyAccepted"
              @onClick="togglePrivacyAccepted()"
              :error="showPrivacyError"
            />
          </div>
          <div
            class="text-body-2 primary--text text--lighten-2"
            v-html="$t('demo.stepContact.privacyConsent')"
          ></div>
        </div>
        <ErrorMessage
          v-if="showPrivacyError"
          class="ml-7 mt-2"
          :text="$t('demo.stepContact.rulePrivacyConsent')"
        />
      </div>
    </v-form>
    <DemoBtns @onBack="previousStep" hideNextBtn>
      <v-btn color="secondary" depressed @click="next()">
        {{ $t('demo.stepContact.btnNext') }}
        <v-icon right v-if="$vuetify.breakpoint.smAndUp">mdi-check-bold</v-icon>
      </v-btn>
    </DemoBtns>
  </DemoStep>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import DemoStep from '@/components/demo/DemoStep.vue';
import Checkbox from '@/components/common/Checkbox.vue';
import ErrorMessage from '@/components/common/ErrorMessage.vue';
import DemoBtns from '@/components/demo/components/DemoBtns.vue';
import { stepSlugs } from '@/components/demo/stepSlugs';
import { domains } from './blockedEmailDomains';

export default {
  name: 'DemoStepContact',
  components: { DemoStep, Checkbox, ErrorMessage, DemoBtns },
  data() {
    return {
      valid: false,
      showPrivacyError: false,
    };
  },
  computed: {
    ...mapGetters('demo', ['step', 'contact', 'event']),
    contactCompanyRules() {
      return [
        (v) => !!v || this.$t('demo.stepContact.ruleCompanyName'),
        (v) =>
          (v && v.length <= 70) ||
          this.$t('demo.stepContact.ruleCompanyNameLength'),
      ];
    },
    contactNameRules() {
      return [
        (v) =>
          (!!v && v.split(' ').length >= 2) ||
          this.$t('demo.stepContact.ruleName'),
      ];
    },
    contactEmailRules() {
      return [
        // Valid email address
        (v) =>
          (!!v && /^[A-Z0-9._%+-]+@[A-Z0-9.-]{1,128}\.[A-Z]{2,32}$/i.test(v)) ||
          this.$t('demo.stepContact.ruleEMailValid'),
        // Check if email domain is blocked
        (v) => !this.isEmailDomainBlocked(v) || this.$t('demo.stepContact.ruleEMailBlocked'),
      ];
    },
    contactPhoneRules() {
      return [
        // Valid phone number, only + or 0 at the beginning, and digits
        // (v) => (!!v && /^(\+|0)[0-9]{6,}/.test(v)) || this.$t('demo.stepContact.rulePhoneValid'),

        // Simpler rule; at least 6 numbers and space, max 32, but can start with country code plus
        (v) => (!!v && /^\+?[\d ]{6,32}$/.test(v)) || this.$t('demo.stepContact.rulePhoneValid'),
      ];
    },
  },
  methods: {
    ...mapMutations('demo', ['previousStep']),
    updateContact() {
      const contact = { ...this.contact };
      this.$store.commit('demo/setValue', {
        key: 'contact',
        value: contact,
      });
    },
    togglePrivacyAccepted() {
      this.contact.privacyAccepted = !this.contact.privacyAccepted;
      this.showPrivacyError = !this.contact.privacyAccepted;
      this.updateContact();
    },
    /**
     * Check if the email provided is from a blocked domain
     * @param {string} email
     * @returns {boolean}
     */
    isEmailDomainBlocked(email) {
      if (!email.includes('@')) return false;

      const domain = email.split('@')[1].toLowerCase();
      return domains.includes(domain);
    },
    async next() {
      this.valid = await this.$refs.form.validate();

      if (!this.contact.privacyAccepted) {
        this.showPrivacyError = true;
        return;
      } else {
        this.showPrivacyError = false;
      }

      if (!this.valid) return;
      
      const language = this.$vuetify.lang.current;
      const isOk = await this.$store.dispatch('demo/handleStepContact', { language });

      if (isOk) {
        // get query params from store
        const queryParams = this.$store.getters['demo/queryParams'];
        // add query params to url
        let url;
        if (language === 'en') {
          url = new URL('https://www.moin.ai/en/thank-you-create-chatbot');
        } else {
          url = new URL('https://www.moin.ai/danke-chatbot-erstellen');
        }
        Object.keys(queryParams).forEach((key) =>
          url.searchParams.append(key, queryParams[key])
        );
        // redirect to url
        window.location.href = url.href;
      }
    },
  },
};
</script>
