export const stepSlugs = {
  stepCompany: 'stepCompany',
  stepPersona: 'stepPersona',
  stepEditPersona: 'stepEditPersona',
  /** @deprecated */
  stepCompanyLoading: 'stepCompanyLoading',
  stepQuestions: 'stepQuestions',
  stepQuestionsLoading: 'stepQuestionsLoading',
  stepIntents: 'stepIntents',
  stepIntentsLoading: 'stepIntentsLoading',
  stepContact: 'stepContact',
}

export const stepsInOrder = [
  stepSlugs.stepCompany,          // Provide URL to site
  // -> Start generating typical questions for each persona + analyze company
  // -> Start analyzing site technologies
  stepSlugs.stepPersona,          // Select persona for bot
  stepSlugs.stepQuestionsLoading, // Wait for typical questions to finish generating
  stepSlugs.stepQuestions,        // Uncheck typical questions you don't want
  // -> Start generating the features etc.
  stepSlugs.stepEditPersona,      // Refine the persona before finalizing
  // -> Start generating agent introduction
  stepSlugs.stepIntentsLoading,   // Wait for agent intro and features to finish
  stepSlugs.stepIntents,          // See your new chatbot and its features
  stepSlugs.stepContact,          // Provide contact info to wrap up
];

/**
 * How many steps there are in total, excluding the loading steps
 * @type {number}
 */
export const stepsCount = stepsInOrder
  .filter(step => !step.toLowerCase().includes('loading'))
  .length;