<template>
  <DemoStep slug="stepIntents">
    <!-- <DemoIntent v-for="item in intents" :key="item.id" :intent="item" class="mb-2" /> -->

    <!-- DEPRECATED: Editorial intents
    <div class="question-item d-flex flex-column">
      !-- Container for icon and header --
      <div class="header-container d-flex align-items-center">
        <v-icon color="primary lighten-3">mdi-check</v-icon>
        <div class="font-weight-bold text-body-1 primary--text ml-2">
          {{ $t('demoStepIntents.editorialAnswers') }}
        </div>
      </div>
      !-- Description text --
      <div class="text-body-2 grey--text mt-2">
        {{ $t('demoStepIntents.editorialAnswersDescription') }}
        <br /><br />
        {{ $t('demoStepIntents.editorialAnswersTopics') }}
      </div>

      <ul class="product-advisors-list mt-2">
        <li v-for="topic in features.editorialTopics" :key="topic">{{ topic }}</li>
      </ul>
    </div>
    -->
    <div class="question-item d-flex flex-column mt-3">
      <!-- Container for icon and header -->
      <div class="header-container d-flex align-items-center">
        <v-icon color="primary lighten-3">mdi-check</v-icon>
        <div class="font-weight-bold text-body-1 primary--text ml-2">
          {{ $t('demoStepIntents.aiAgent.title') }}
        </div>
      </div>

      <!-- Description text -->
      <div class="text-body-2 grey--text text--darken-3 mt-2">
        {{ $t('demoStepIntents.aiAgent.description') }}

        <br /><br />
        {{ $t('demoStepIntents.aiAgent.contentLabel') }}
      </div>

      <!-- Highlight supported technologies -->
      <ul class="product-advisors-list mt-2">
        <li
          v-for="(action,i) in supportedActions"
          :key="i"
          class="d-flex gap-2 items-center"
        >
          <div style="width: 24px; max-height: 24px; flex-shrink: 0">
            <img
              v-if="action.image"
              :src="action.image"
              class="full-width full-height"
              alt="Service logo"
            />
            <v-icon v-else-if="action.icon">
              {{ action.icon }}
            </v-icon>
          </div>
          <span>{{ action.label}}</span>
        </li>
      </ul>
    </div>

    <div class="question-item d-flex flex-column mt-3">
      <!-- Container for icon and header -->
      <div class="header-container d-flex align-items-center">
        <v-icon color="primary lighten-3">mdi-check</v-icon>
        <div class="font-weight-bold text-body-1 primary--text ml-2">
          {{ $t('demoStepIntents.knowledgeBase') }}
        </div>
      </div>
      <!-- Description text -->
      <div class="text-body-2 grey--text text--darken-3 mt-2">
        {{ $t('demoStepIntents.knowledgeBaseDescription') }}

        <br /><br />
        {{ $t('demoStepIntents.generatedAnswer') }}
      </div>

      <WidgetChatHistory
        min-height="auto"
        max-width="100%"
        style="background-color: transparent"
      >
        <WidgetBotMessage
          :avatar="$t(`demo.personas.${persona.id}.avatar`)"
          :text="agentIntroduction"
          bg-color="#eeeeee"
        />
        <WidgetUserMessage
          :text="features.answer.query"
          arrow
        />
        <WidgetAIMessage
          :avatar="$t(`demo.personas.${persona.id}.avatar`)"
          :text="features.answer.answer"
          arrow
          force-rounded
          disclaimer=""
          :resources-used="[{
              type: 'WEBPAGE',
              source: companyUrl,
            }]"
          bg-color="#eeeeef"
        />
      </WidgetChatHistory>
    </div>

    <div v-if="features.product_advisor" class="question-item d-flex flex-column mt-3 highlight-item">
      <!-- Container for icon and header -->
      <div class="header-container d-flex align-items-center">
        <v-icon color="#8046eb">mdi-check</v-icon>
        <div class="font-weight-bold text-body-1 primary--text ml-2">
          {{ $t('demoStepIntents.productAdvisor') }}
        </div>
      </div>
      <!-- Description text -->
      <div class="text-body-2 grey--text text--darken-3 mt-2">
        <span v-html="$t('demoStepIntents.productAdvisorDescription')"></span>
        <br /><br />
        {{ $t('demoStepIntents.productAdvisorTopics') }}
      </div>

      <ul class="product-advisors-list mt-2">
        <li v-for="advisors in features.product_advisors" :key="advisors">
          {{ advisors }}
        </li>
      </ul>
    </div>

    <div v-if="features.livechat" class="question-item d-flex flex-column mt-3">
      <!-- Container for icon and header -->
      <div class="header-container d-flex align-items-center">
        <v-icon color="primary lighten-3">mdi-check</v-icon>
        <div class="font-weight-bold text-body-1 primary--text ml-2">
          moinAI Livechat
        </div>
      </div>
      <!-- Description text -->
      <div class="text-body-2 grey--text text--darken-3 mt-2" v-html="$t('demoStepIntents.livechatDescription')">
      </div>
    </div>


    <DemoBtns @onBack="back()" @onNext="nextStep" />
  </DemoStep>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import DemoStep from '@/components/demo/DemoStep.vue';
import DemoIntent from '@/components/demo/components/DemoIntent.vue';
import DemoBtns from '@/components/demo/components/DemoBtns.vue';
import { stepSlugs } from '@/components/demo/stepSlugs';
import WidgetBotMessage from '@/components/widget/parts/WidgetBotMessage.vue';
import WidgetUserMessage from '@/components/widget/parts/WidgetUserMessage.vue';
import WidgetAIMessage from '@/components/widget/parts/WidgetAIMessage.vue';
import WidgetChatHistory from '@/components/widget/parts/WidgetChatHistory.vue';

export default {
  name: 'DemoStepIntents',
  components: {
    WidgetChatHistory,
    WidgetAIMessage,
    WidgetUserMessage,
    WidgetBotMessage,
    DemoStep,
    DemoIntent,
    DemoBtns,
  },
  computed: {
    ...mapGetters('demo', ['intents', 'features', 'persona', 'companyUrl', 'technologies', 'getLogo', 'agentIntroduction']),
    /**
     * Technologies tell us which services/fallback categories can be used,
     * then in the translations there can be multiple actions per service/fallback.
     *
     * It either has an icon name or an image URL.
     * @returns {{label: string, icon?: string, image?: string}[]}
     */
    supportedActions() {
      const displayStrings = [];

      // First try to use the technologies we found on the site that we support
      for (const identifier of this.technologies) {
        const actions = this.$t(`demo.technologies.${identifier}.actions`);
        if (!Array.isArray(actions)) continue; // Ensure it exists and is array

        for (const action of actions) {
          displayStrings.push({
            label: action,
            image: this.getLogo(identifier),
          });
        }
      }

      /**
       * Then populate the fallback technologies.
       * We group them by the icon they use, other than that it doesn't really
       * matter which group they are in.
       */
      for (const fallbackGroup of this.$t(`demo.technologies.fallbackTechnologies`)) {
        for (const fallbackAction of fallbackGroup.actions) {
          displayStrings.push({
            label: fallbackAction,
            icon: fallbackGroup.icon,
          });
        }
      }

      /**
       * Supported technologies are prioritized by being first in the list,
       * and then we display fallbacks later. Show only a subset of up to 10 items.
       */
      return displayStrings.slice(0,10);
    }
  },
  methods: {
    ...mapMutations('demo', ['previousStep', 'nextStep']),
    back() {
      this.$store.commit('demo/setValue', {
        key: 'isQuestionsDirty',
        value: false,
      });

      this.previousStep();
    },
  },
};
</script>
<style lang="scss" scoped>
.question-item {
  border-radius: $spacer;
  padding: $spacer * 3 $spacer * 4;
  border: 1px solid #E0E0E0;
  display: flex;
  flex-direction: column; /* Ändert die Ausrichtung der Kinder auf vertikal */
}

.highlight-item {
  border: 1px solid #8046eb;
}

.product-advisors-list {
  list-style: none; /* Entfernt die Aufzählungszeichen */
  padding-left: 0; /* Entfernt den linken Abstand */
  margin-top: 0; /* Optional: Entfernt den oberen Abstand, falls nötig */
}

.product-advisors-list li {
  padding: 4px 0; /* Fügt vertikalen Abstand zwischen den Elementen hinzu */
  border-bottom: 1px solid #e0e0e0; /* Fügt eine Trennlinie zwischen den Elementen hinzu */
}

.product-advisors-list li:last-child {
  border-bottom: none; /* Entfernt die untere Linie vom letzten Element */
}

.example-request {
  background-color: #f9f9f9; /* Leichter Hintergrund für Kontrast */
  border-left: 4px solid #fa7a59; /* Blaue Linie links als Hervorhebung */
  padding: 10px 15px; /* Innenabstand */
  border-radius: 4px; /* Ecken abrunden */
  margin-top: 10px; /* Abstand nach oben */
}

.request, .response {
  padding: 5px 0; /* Vertikaler Abstand */
}


</style>

