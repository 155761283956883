<template>
  <div>
    <apexchart
      v-if="isInitialized"
      height=300
      width="100%"
      type="pie"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
export default {
  name: 'PieChart',
  components: {},
  props: {
    data: {
      type: Array,
      required: true,
      validator(value) {
        return value.every(item => 'label' in item && 'value' in item);
      },
    },
  },
  data() {
    return {
      isInitialized: false,
      chartOptions: {
        chart: {
          toolbar: {
            // Adds burger Button to the Top Right, which allows to "Download SVG/PNG/CSV"
            show: false,
          },
        },
        legend: {
          position: 'right',
        },
        labels: [],
        responsive: [{
          breakpoint: 720,
          options: {
            legend: { position: 'bottom' }
          }
        }]
      },
    };
  },
  computed: {
    series() {
      return this.data.map(item => item.value);
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        this.isInitialized = false;

        this.chartOptions = {
          ...this.chartOptions,
          labels: newData.map(item => item.label)
        };
        
        // hack to render the chart after the data is updated
        this.$nextTick(() => {
          this.isInitialized = newData.length > 0;;
        });
      },
    },
  },
  mounted() {
    setTimeout(() => { this.isInitialized = true; }, 300);
  }
};
</script>

<style scoped>
/* canvas {
  max-width: 100%;
  height: 300px;
} */
</style>