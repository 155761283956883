<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />

    <HubEditorFrame
      ref="hubeditor"
      :title="$t('editor.widget.title')"
      back="/wt"
      :channels="channels"
      save-icon="cloud_upload"
      save-label="editor.common.deploy"
      :draft="isDirty"
      :loading="!!(isLoading || isSaving)"
      save-confirmation-dialog-title="editor.widget.saveConfirmationTitle"
      save-confirmation-dialog-description="editor.widget.saveConfirmationDescription"
      @onChannelChanged="channelChanged"
      @onReset="reset"
      @onSave="save"
    >
      <template v-slot="{ isLoading }">

        <!-- Admin Einstellungen -->
        <v-expansion-panels v-if="isAdmin && !isLoading && configAvailable">
          <v-expansion-panel>
            <v-expansion-panel-header><h3 class="text-h6 primary--text">{{ $t('editor.widget.admin.title') }}</h3></v-expansion-panel-header>
            <v-expansion-panel-content>
              <p v-html="$t('editor.widget.admin.description')"></p>
              <v-row>
                <v-card-title>{{ $t('editor.widget.admin.poweredBy') }}</v-card-title>
                <v-switch
                  v-model="gotConfig.config.poweredBy"
                  :color="gotConfig?.config?.poweredBy ? 'green' : 'grey'"
                  inset
                  :label="$t('editor.widget.admin.poweredByInfo')"
                  @change="(v) => $emit('onChange', v)"
                />
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- Widget activated / deactivated -->
        <v-card v-if="!isLoading" flat rounded="0">
          <v-card-title>
            {{ $t('editor.widget.integration.title') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('editor.widget.integration.description') }}
          </v-card-subtitle>
          <WidgetActivation
            :widgetActive="widgetActive"
            @onChange="(v) => (widgetActive = v)"
          />
          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>
          <WidgetIntegrationCode
            v-if="!isLoading && currentBotId"
            :botId="currentBotId"
            :channelId="channelId"
          />
        </v-card>
        <!-- Widget Editor -->
        <v-card
          v-if="!isLoading && !configAvailable"
          flat
          class="rounded-0 fill-height"
        >
          <v-card-title>
            {{ $t('editor.widget.appearance.title') }}
          </v-card-title>
          <v-card-subtitle>
            <span v-html="$t('editor.widget.appearance.na_description')"></span>
          </v-card-subtitle>
          <v-row>
            <!-- Allgemeine Einstellungen -->
            <v-col cols="6" class="text-center">
              <WidgetPreview
                class="elevation-4"
                headline="Nicht unterstützt"
                title="Chatbot"
                name="Name"
              />
            </v-col>
            <v-col cols="6">
              <v-card-text>
                <v-icon small left color="warning">warning</v-icon>
                {{ $t('editor.widget.notsupported') }}
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          v-if="configAvailable && !isLoading"
          flat
          class="rounded-0 fill-height"
        >
          <v-card-title>
            {{ $t('editor.widget.appearance.title') }}
          </v-card-title>
          <v-card-subtitle>
            <span v-html="$t('editor.widget.appearance.description')"></span>
          </v-card-subtitle>
          <v-row
            @dragover.prevent
            @dragleave="dragleave"
            @dragenter="dragenter"
            @drop="drop"
          >
            <!-- Allgemeine Einstellungen -->
            <v-col cols="12" md="6" class="hidden-sm-and-down">
              <WidgetPreviewNew
                v-if="gotConfig && appeareance"
                class="floating mx-auto"
                :userTxtColor="userTxtColor"
                :message="getThemeColors[usermessage.color].color"
                :avatar="avatar"
                :header="getThemeColors[widgetheader.color].color"
                :headerContrast="widgetheader.contrast"
                :headline="gotConfig.header.name"
                :name="gotConfig.operator.name"
                :title="gotConfig.operator.titel"
                :inputPlaceholder="appeareance.inputPlaceholder"
                :showRatingBar="false"
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-overlay :value="hoveringContent" color="success" opacity="0.8">
                <span>
                  <v-icon class="align-self-top" color="white" x-large>
                    image
                  </v-icon>
                  Avatar Bild hinzufügen
                </span>
              </v-overlay>

              <v-card-title>
                {{ $t('editor.widget.appearance.names.title') }}
              </v-card-title>
              <v-text-field
                v-model="gotConfig.header.name"
                class="pl-4 pr-4"
                outlined
                :label="$t('editor.widget.appearance.names.header')"
              />
              <v-text-field
                v-model="gotConfig.operator.name"
                class="pl-4 pr-4"
                outlined
                :label="$t('editor.widget.appearance.names.chatbot')"
              />
              <v-text-field
                v-model="gotConfig.operator.titel"
                class="pl-4 pr-4"
                outlined
                :label="$t('editor.widget.appearance.names.chatbotTitle')"
              />
              <v-text-field
                v-model="appeareance.inputPlaceholder"
                class="pl-4 pr-4"
                outlined
                :label="$t('editor.widget.appearance.placeholderLabel')"
              />

              <v-file-input
                v-model="avatarFile"
                accept="image/png, image/jpeg, image/bmp, image/gif"
                prepend-icon
                class="pl-4 pr-4"
                outlined
                :label="$t('editor.widget.appearance.avatar.newimage')"
              >
                <template v-slot:append-outer>
                  <div
                    class="avatar mt-n3"
                    style="
                      min-width: 48px;
                      width: 48px;
                      min-height: 48px;
                      height: 48px;
                    "
                  >
                    <img width="48px" :src="avatar" />
                  </div>
                </template>
              </v-file-input>

              <v-card-text class="pt-0 mb-2">
                <v-btn
                  outlined
                  color="primary"
                  @click="generateAvatarDialog = true"
                >
                  {{ $t('editor.widget.appearance.avatar.btnNewAvatar') }}
                  <v-icon right>account_circle</v-icon>
                </v-btn>

                <hub-dialog
                  v-model="generateAvatarDialog"
                  :title="$t('editor.widget.appearance.avatar.titleCreateOwn')"
                  max-width="800px"
                  :primary-label="
                    $t('editor.widget.appearance.avatar.btnSaveAvatar')
                  "
                  async-dialog
                  :secondaryLabel="$t('common.cancel')"
                  @onPrimary="onClosingAvatarEditor"
                  @onSecondary="generateAvatarDialog = null"
                >
                  <avatar-editor
                    ref="avatarEditor"
                    is-in-popup
                    @onCreateAvatarBase64="onCreateAvatarBase64"
                  />
                </hub-dialog>
              </v-card-text>

              <v-card-title>
                {{ $t('editor.widget.appearance.colors.title') }}
              </v-card-title>
              <v-row class="pl-4 pr-4">
                <v-col cols="6">
                  <HubColorInput
                    v-model="themecolors.primary"
                    :label="$t('editor.widget.primarycolor')"
                  />
                </v-col>
                <v-col cols="6">
                  <HubColorInput
                    v-model="themecolors.secondary"
                    :label="$t('editor.widget.secondarycolor')"
                  />
                </v-col>
              </v-row>
              <v-card-title>
                {{ $t('editor.widget.appearance.brightness.title') }}
              </v-card-title>
              <v-btn-toggle
                v-model="themetype"
                class="pl-4"
                mandatory
                borderless
                color="primary lighten-2"
              >
                <v-btn value="regular">
                  <v-icon left color="primary lighten-2">
                    brightness_medium
                  </v-icon>
                  <span>Neutral</span>
                </v-btn>

                <v-btn value="light" disabled>
                  <v-icon left color="primary lighten-2">brightness_low</v-icon>
                  <span>Light</span>
                </v-btn>

                <v-btn value="dark" disabled>
                  <v-icon left color="primary lighten-2">
                    brightness_high
                  </v-icon>
                  <span>Dark</span>
                </v-btn>
              </v-btn-toggle>
              <v-card-text class="text-left mt-0 pt-0">
                {{ $t('editor.widget.appearance.brightness.info') }}
              </v-card-text>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <!-- Customize elements -->
          <v-card-title>{{ $t('editor.widget.elements.title') }}</v-card-title>
          <v-card-subtitle>
            {{ $t('editor.widget.elements.description') }}
          </v-card-subtitle>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="align-self-center hidden-sm-and-down"
            >
              <WidgetButtonPreview
                :color="getThemeColors[widgetbutton.color].color"
                :contrast="widgetbutton.contrast"
                class="floating mx-auto overflow-hidden"
                :alignLeft="appeareance.alignmentOrigin === 'bottom left'"
              />
            </v-col>
            <v-col cols="12" md="6" class="pt-2">
              <v-card-title>
                {{ $t('editor.widget.elements.button.title') }}
              </v-card-title>
              <div>
                <!-- Colors -->
                <div>
                  <v-card-text class="pb-0">
                    {{ $t('editor.common.color') }}
                  </v-card-text>
                  <v-btn-toggle
                    v-model="widgetbutton.color"
                    class="pl-4"
                    mandatory
                    borderless
                    color="primary lighten-2"
                  >
                    <v-btn value="primary">
                      <v-icon left :color="getThemeColors.primary.color">
                        stop_circle
                      </v-icon>
                      <span>{{ $t('editor.widget.primarycolor') }}</span>
                    </v-btn>
                    <v-btn value="secondary">
                      <v-icon left :color="getThemeColors.secondary.color">
                        stop_circle
                      </v-icon>
                      <span>{{ $t('editor.widget.secondarycolor') }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </div>

                <!-- Brightness -->
                <div>
                  <v-card-text class="pb-0">
                    {{ $t('editor.common.brighness') }}
                  </v-card-text>
                  <v-btn-toggle
                    v-model="widgetbutton.contrast"
                    class="pl-4"
                    mandatory
                    borderless
                    color="primary lighten-2"
                  >
                    <v-btn value="dark">
                      <v-icon left color="primary">stop_circle</v-icon>
                      <span>{{ $t('editor.common.dark') }}</span>
                    </v-btn>
                    <v-btn value="light">
                      <v-icon left color="primary">panorama_fish_eye</v-icon>
                      <span>{{ $t('editor.common.light') }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </div>

                <!-- Alignment -->
                <div v-if="appeareance">
                  <v-card-text class="pb-0">
                    {{ $t('editor.common.alignment') }}
                  </v-card-text>
                  <v-btn-toggle
                    :value="appeareance.alignmentOrigin || 'bottom right'"
                    class="pl-4"
                    mandatory
                    borderless
                    color="primary lighten-2"
                  >
                    <v-btn
                      value="bottom left"
                      @click="changeAlignmentOrigin('bottom left')"
                    >
                      <v-icon left color="primary">south_west</v-icon>
                      <span>{{ $t('editor.common.alignLeft') }}</span>
                    </v-btn>
                    <v-btn
                      value="bottom right"
                      @click="changeAlignmentOrigin('bottom right')"
                    >
                      <v-icon left color="primary">south_east</v-icon>
                      <span>{{ $t('editor.common.alignRight') }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
              <div class="pl-4 mt-4">
                <!-- For widget button -->
                <ContrastChecker
                  class="mt-2"
                  :colorA="getThemeColors[widgetbutton.color].color"
                  :colorB="
                    widgetbutton.contrast === 'light' ? '#ffffff' : '#484848'
                  "
                />
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <!-- Header customization -->
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="align-self-center hidden-sm-and-down"
            >
              <WidgetPreviewNew
                v-if="gotConfig && appeareance"
                class="floating mx-auto"
                :avatar="avatar"
                :header="getThemeColors[widgetheader.color].color"
                :headerContrast="widgetheader.contrast"
                :userTxtColor="userTxtColor"
                :headline="gotConfig.header.name"
                :name="gotConfig.operator.name"
                :title="gotConfig.operator.titel"
                :showRatingBar="false"
                :showInput="false"
              >
                <template #history>
                  <div class="py-4" />
                </template>
              </WidgetPreviewNew>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-title>Header</v-card-title>
              <v-row>
                <v-col>
                  <v-card-text class="pb-0">
                    {{ $t('editor.common.color') }}
                  </v-card-text>
                  <v-btn-toggle
                    v-model="widgetheader.color"
                    class="pl-4"
                    mandatory
                    borderless
                    color="primary lighten-2"
                  >
                    <v-btn value="primary">
                      <v-icon left :color="getThemeColors.primary.color">
                        stop_circle
                      </v-icon>
                      <span>{{ $t('editor.widget.primarycolor') }}</span>
                    </v-btn>

                    <v-btn value="secondary">
                      <v-icon left :color="getThemeColors.secondary.color">
                        stop_circle
                      </v-icon>
                      <span>{{ $t('editor.widget.secondarycolor') }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card-text class="pb-0">
                    {{ $t('editor.common.brighness') }}
                  </v-card-text>
                  <v-btn-toggle
                    v-model="widgetheader.contrast"
                    class="pl-4"
                    mandatory
                    borderless
                    color="primary lighten-2"
                  >
                    <v-btn value="dark">
                      <v-icon left color="primary">stop_circle</v-icon>
                      <span>{{ $t('editor.common.dark') }}</span>
                    </v-btn>

                    <v-btn value="light">
                      <v-icon left color="primary">panorama_fish_eye</v-icon>
                      <span>{{ $t('editor.common.light') }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <div class="pl-4 mt-4">
                <!-- For widget header -->
                <ContrastChecker
                  class="mt-2"
                  :colorA="getThemeColors[widgetheader.color].color"
                  :colorB="
                    widgetheader.contrast === 'light' ? '#ffffff' : '#484848'
                  "
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <!-- Star rating (CSAT) -->
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="align-self-center hidden-sm-and-down"
            >
              <WidgetPreviewNew
                v-if="gotConfig && appeareance"
                class="floating mx-auto"
                :avatar="avatar"
                :header="getThemeColors[widgetheader.color].color"
                :headerContrast="widgetheader.contrast"
                :userTxtColor="userTxtColor"
                :headline="gotConfig.header.name"
                :name="gotConfig.operator.name"
                :title="gotConfig.operator.titel"
                :rateLabel="gotConfig.config.rating.label"
                :showInput="false"
              >
                <template #history>
                  <div class="py-4" />
                </template>
              </WidgetPreviewNew>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-card-title>{{ $t('editor.widget.csat.title') }}</v-card-title>
              <!-- "Pages" label -->
              <v-text-field
                v-model="gotConfig.config.rating.label"
                class="pl-4 pr-4"
                outlined
                :label="$t('editor.widget.csat.ratingLabel')"
                :placeholder="$t('editor.widget.csat.ratingLabelPlaceholder')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <!-- Message bubble (user) -->
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="align-self-center hidden-sm-and-down"
            >
              <WidgetChatHistory
                height="auto"
                minHeight="auto"
                class="floating mx-auto"
              >
                <div class="py-2" />
                <WidgetBotMessage :avatar="avatar" text="Hallo, ich bin hier um zu helfen. Was kann ich für dich tun?" />
                <WidgetUserMessage
                  :txtColor="userTxtColor"
                  :bgColor="getThemeColors[usermessage.color].color"
                  text="Wo bekomme ich Informationen über eure neuesten Produkte?"
                />
              </WidgetChatHistory>

              <WidgetPreview
                v-if="false"
                :name="gotConfig.operator.name"
                :title="gotConfig.operator.titel"
                class="elevation-4"
                :userTxtColor="userTxtColor"
                :message="getThemeColors[usermessage.color].color"
                only-message
                :avatar="avatar"
                :header="getThemeColors[widgetheader.color].color"
                :headerContrast="widgetheader.contrast"
                :headline="gotConfig.header.name"
              />
            </v-col>
            <v-col cols="12" md="6" class="pt-2">
              <v-card-title>
                {{ $t('editor.widget.elements.messages.title') }}
              </v-card-title>
              <v-row>
                <v-col class="pt-0">
                  <v-card-text class="pb-0">
                    {{ $t('editor.common.color') }}
                  </v-card-text>
                  <v-btn-toggle
                    v-model="usermessage.color"
                    class="pl-4"
                    mandatory
                    borderless
                    color="primary lighten-2"
                  >
                    <v-btn value="primary">
                      <v-icon left :color="getThemeColors.primary.color">
                        stop_circle
                      </v-icon>
                      <span>{{ $t('editor.widget.primarycolor') }}</span>
                    </v-btn>

                    <v-btn value="secondary">
                      <v-icon left :color="getThemeColors.secondary.color">
                        stop_circle
                      </v-icon>
                      <span>{{ $t('editor.widget.secondarycolor') }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <v-card-text class="pb-0">
                    {{ $t('editor.common.brighness') }}
                  </v-card-text>
                  <v-btn-toggle
                    v-model="usermessage.contrast"
                    class="pl-4"
                    mandatory
                    borderless
                    color="primary lighten-2"
                  >
                    <v-btn value="dark">
                      <v-icon left color="primary">stop_circle</v-icon>
                      <span>{{ $t('editor.common.dark') }}</span>
                    </v-btn>

                    <v-btn value="light">
                      <v-icon left color="primary">panorama_fish_eye</v-icon>
                      <span>{{ $t('editor.common.light') }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <div class="pl-4 mt-4">
                <!-- For widget message bg -->
                <ContrastChecker
                  class="mt-2"
                  :colorA="getThemeColors[usermessage.color].color"
                  :colorB="
                    usermessage.contrast === 'light' ? '#ffffff' : '#484848'
                  "
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <!-- AI Generated indicator -->
          <v-row v-if="isRAG && gotConfig?.config?.aiIndicator">
            <v-col
              cols="12"
              md="6"
              class="align-self-center hidden-sm-and-down"
            >
              <WidgetChatHistory
                height="auto"
                minHeight="auto"
                class="floating mx-auto"
              >
                <WidgetUserMessage
                  text=" Gibt es eine Altersgrenze?"
                  :bgColor="getThemeColors[usermessage.color].color"
                  :txtColor="userTxtColor"
                />
                <WidgetAIMessage
                  :enabled="gotConfig.config.aiIndicator.enabled"
                  :generated="gotConfig.config.aiIndicator.generated"
                  :disclaimer="gotConfig.config.aiIndicator.disclaimer"
                  :sources="gotConfig.config.aiIndicator.sources"
                  :pages="gotConfig.config.aiIndicator.pages"
                  :avatar="avatar"
                  text="Es gibt keine Altersbeschränkung und es ist für alle Altersgruppen geeignet."
                />
                <div class="py-2" />
              </WidgetChatHistory>
            </v-col>
            <v-col cols="12" md="6" class="pt-2">
              <v-card-title>{{ $t('editor.widget.ragResponse.title') }}</v-card-title>

              <!-- "Generated" label -->
              <v-row>
                <v-col>
                  <!-- Toggle on / off -->
                  <div class="px-4">
                    <v-switch
                      v-model="gotConfig.config.aiIndicator.enabled"
                      :color="widgetActive ? 'green' : 'grey'"
                      inset
                      :label="$t('editor.widget.ragResponse.indicatorLabel')"
                    />
                  </div>

                  <!-- AI "Generated" label -->
                  <v-text-field
                    v-model="gotConfig.config.aiIndicator.generated"
                    :disabled="!gotConfig.config.aiIndicator.enabled"
                    class="pl-4 pr-4"
                    outlined
                    :label="$t('editor.widget.ragResponse.aiLabel')"
                  />

                  <!-- "Sources" label -->
                  <v-text-field
                    v-model="gotConfig.config.aiIndicator.sources"
                    :disabled="!gotConfig.config.aiIndicator.enabled"
                    class="pl-4 pr-4"
                    outlined
                    :label="$tc('editor.widget.ragResponse.sourceLabel', 1)"
                  />

                  <!-- "Pages" label -->
                  <v-text-field
                    v-model="gotConfig.config.aiIndicator.pages"
                    :disabled="!gotConfig.config.aiIndicator.enabled"
                    class="pl-4 pr-4"
                    outlined
                    :label="$t('editor.widget.ragResponse.pagesLabel')"
                  />

                  <!-- Disclaimer text -->
                  <v-text-field
                    v-model="gotConfig.config.aiIndicator.disclaimer"
                    :disabled="!gotConfig.config.aiIndicator.enabled"
                    class="pl-4 pr-4"
                    outlined
                    :label="$t('editor.widget.ragResponse.disclaimerLabel')"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-if="isRAG && gotConfig?.config?.aiIndicator">
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <!-- Privacy settings -->
          <div>
            <v-card-title>
              {{ $t('editor.widget.privacy.title') }}
            </v-card-title>
            <v-card-subtitle>
              {{ $t('editor.widget.privacy.subTitle') }}
            </v-card-subtitle>
          </div>

          <WidgetPrivacy
            v-if="gotConfig"
            :avatar="avatar"
            :bgColor="getThemeColors[widgetheader.color].color"
            :widgetTitle="gotConfig.header.name"
            :headerContrast="widgetheader.contrast"
          />

          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <!-- Behavior -->
          <v-card-title>{{ $t('editor.widget.behavior.title') }}</v-card-title>
          <v-card-subtitle>
            {{ $t('editor.widget.behavior.description') }}
          </v-card-subtitle>
          <WidgetRememberUser :config="gotConfig.config" />
        </v-card>
      </template>
    </HubEditorFrame>
    <HubDialog
      v-if="isInitialized"
      v-model="lostDialog"
      primary-icon="delete"
      :primary-label="$t('editor.common.discard')"
      modal
      :title="$t('editor.widget.unsaved.title')"
      @onSecondary="lostCancel"
      @onPrimary="lostOk"
    >
      {{ $t('editor.widget.unsaved.description') }}
    </HubDialog>
  </v-app>
</template>

<script>
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import { mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';
import HubEditorFrame from '@/components/hub/HubEditorFrame.vue';
import HubColorInput from '@/components/hub/HubColorPicker.vue';
import WidgetPreview from '@/components/widget/WidgetPreview.vue';
import WidgetButtonPreview from '@/components/widget/WidgetButtonPreview.vue';
import WidgetActivation from '@/components/widget/WidgetActivation.vue';
import WidgetIntegrationCode from '@/components/widget/WidgetIntegrationCode.vue';
import WidgetRememberUser from '@/components/widget/WidgetRememberUser.vue';
import UploadService from '@/services/upload';
import AvatarEditor from '@/components/tools/AvatarEditor';
import WidgetPrivacyPreview from '@/components/widget/WidgetPrivacyPreview.vue';
import WidgetPrivacyLinks from '@/components/widget/WidgetPrivacyLinks.vue';
import WidgetPrivacy from '@/components/widget/WidgetPrivacy.vue';

import { createHelpers } from 'vuex-map-fields';
import HubDialog from '@/components/hub/HubDialog.vue';
import ContrastChecker from '@/components/common/ContrastChecker.vue';
import ImageFileUtil from '@/utils/imageFile';
import WidgetRAGPReview from '@/components/widget/WidgetRAGPReview.vue';
import WidgetPreviewNew from '@/components/widget/WidgetPreviewNew.vue';
import WidgetChatHistory from '@/components/widget/parts/WidgetChatHistory.vue';
import WidgetBotMessage from '@/components/widget/parts/WidgetBotMessage.vue';
import WidgetUserMessage from '@/components/widget/parts/WidgetUserMessage.vue';
import WidgetAIMessage from '@/components/widget/parts/WidgetAIMessage.vue';

const { mapFields } = createHelpers({
  getterType: 'channelsettings/getField',
  mutationType: 'channelsettings/updateField',
});

export default {
  name: 'Widget',
  components: {
    WidgetAIMessage,
    WidgetPreviewNew,
    WidgetChatHistory,
    WidgetBotMessage,
    WidgetUserMessage,
    WidgetRAGPReview,
    HubMenu,
    Appbar,
    AvatarEditor,
    HubEditorFrame,
    HubColorInput,
    WidgetPreview,
    WidgetButtonPreview,
    WidgetActivation,
    WidgetIntegrationCode,
    WidgetRememberUser,
    WidgetPrivacyPreview,
    WidgetPrivacyLinks,
    HubDialog,
    ContrastChecker,
    WidgetPrivacy,
  },
  props: [],
  data() {
    return {
      colors: {
        primary: { color: '#CCCCCC' },
        secondary: { color: '#FFFFFF' },
      },
      primary: { color: '#CCCCCC' },
      secondary: { color: '#FFFFFF' },
      title: 'Moin!',
      chatbotTitle: 'Moini',
      chatbotSubtitle: 'Chatbot',
      template: 'normal',
      farbe: 'primary',
      channelId: null,
      lostDialog: false,
      nextUrl: null,
      hoverCounter: 0,
      hoveringContent: false,
      avatarImage: undefined,
      avatarFile: null,
      generateAvatarDialog: false,
      widgetActive: false,
      isInitialized: false,
    };
  },
  computed: {
    ...mapFields({
      themetype: 'appeareance.themetype',
      widgetbutton: 'appeareance.button',
      widgetheader: 'appeareance.widgetheader',
      usermessage: 'appeareance.usermessage',
      themecolors: 'appeareance.themecolors',
    }),
    ...mapGetters('bots', [
      'currentBotId',
      'currentBot',
      'currentChannels',
      'getBot',
      'getCurrentChannelById',
    ]),
    ...mapGetters('channelsettings', [
      'gotConfig',
      'getThemeColors',
      'getDirty',
      'isLoading',
      'isSaving',
      'appeareance',
    ]),
    ...mapGetters('auth', ['isAdmin', 'isRAG']),
    userTxtColor() {
      return this.usermessage.contrast === 'light' ? '#FFFFFF' : '#484848';
    },
    isDirty() {
      if (!this.currentBotId) {
        return false;
      }
      return (
        this.getDirty ||
        !!this.avatarImage ||
        (this.getCurrentChannelById(this.channelId) &&
          this.widgetActive !==
            this.getCurrentChannelById(this.channelId).active)
      );
    },
    avatar() {
      if (this.avatarImage) {
        return this.avatarImage;
      } else {
        return this.gotConfig.operator.avatar_url;
      }
    },
    configAvailable() {
      return !!this.gotConfig && !!this.gotConfig.header;
    },
    channels() {
      let i = 0;
      return this.currentChannels
        .filter((channel) => channel.channel === 'widget')
        .map((channel) => {
          const clonedChannel = _.cloneDeep(channel);
          clonedChannel.cId = clonedChannel.channelId;

          if (i === 0) {
            clonedChannel.channelId = null;
          }

          i++;
          return clonedChannel;
        });
    },
  },
  watch: {
    currentBotId() {
      this.initialize();
    },
    avatarFile(file) {
      if (!file || file === '') {
        this.avatarImage = false;
        this.avatarFile = null;
        return;
      }

      ImageFileUtil.createImageURL(
        file,
        (dataURL) => (this.avatarImage = dataURL)
      );
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapMutations('bots', ['setChannelActiveState']),
    async initialize() {
      let whileCount = 40;
      while (!this.currentBotId && !this.channels.length && whileCount > 0) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        whileCount--;
      }
      // redirect if no widget channel available
      if (this.channels.length === 0) {
        return this.$router.push({ name: 'home' });
      }
      // standard channel id
      this.channelId = this.channels[0].cId;
      this.fetchConfig();
      this.isInitialized = true;
    },
    reset() {
      this.avatarImage = false;
      this.avatarFile = null;
      this.fetchConfig();
    },
    channelChanged(channelId) {
      // channel not changed
      if (
        this.channelId === channelId ||
        (channelId === null && this.channelId === this.channels[0].cId)
      ) {
        return;
      }

      // changes available
      if (this.configAvailable && this.isDirty) {
        this.lostDialog = true;
        return;
      }

      if (channelId === null) {
        this.channelId = this.channels[0].cId;
      } else {
        this.channelId = channelId;
      }

      // fetch new configs
      this.fetchConfig();
    },
    fetchConfig() {
      this.$store.dispatch('channelsettings/fetchChannelConfig', {
        channelId: this.channelId,
        botId: this.currentBotId,
      });

      this.widgetActive =
        this.getCurrentChannelById(this.channelId) &&
        this.getCurrentChannelById(this.channelId).active;
    },
    async save() {
      if (this.avatarImage && this.avatarFile) {
        const response = await UploadService.uploadFile(
          this.avatarFile,
          () => {}
        );
        if (response && response.file && response.file.location) {
          this.gotConfig.operator.avatar_url = response.file.location;
        }

        this.avatarImage = false;
        this.avatarFile = null;
      }

      await this.$store.dispatch('channelsettings/changeChannelState', {
        channelId: this.channelId,
        botId: this.currentBotId,
        state: this.widgetActive,
      });

      this.setChannelActiveState({
        channelId: this.channelId,
        botId: this.currentBotId,
        activeState: this.widgetActive,
      });

      if (this.configAvailable) {
        await this.$store.dispatch('channelsettings/saveChannelConfig', {
          channelId: this.channelId,
          botId: this.currentBotId,
        });
      }

      const stagingBotID = this.currentBot.stagingBot;
      if (stagingBotID) {
        const bot = await this.$store.dispatch('bots/getBotById', stagingBotID);
        if (bot) {
          const stagingChannels = bot.channels;
          const preview = stagingChannels.find((c) => c.channel === 'widget');

          if (preview && this.configAvailable) {
            await this.$store.dispatch('channelsettings/saveChannelConfig', {
              channelId: preview.channelId,
              botId: stagingBotID,
            });
          }
        }
      }
    },
    lostOk() {
      if (!this.nextUrl) {
        // channel change
        const id = this.$route.query.channelId;
        if (!id) {
          this.channelId = this.channels[0].cId;
        } else {
          this.channelId = id;
        }

        this.avatarImage = false;
        this.avatarFile = null;
        this.fetchConfig();
        this.widgetActive = this.getChannelById(
          this.currentBotId,
          this.channelId
        ).active;
      } else {
        // url change
        this.$router.push(this.nextUrl);
        this.nextUrl = null;
      }
    },
    lostCancel() {
      if (!this.nextUrl) {
        // channel change
        const query = Object.assign({}, this.$route.query);
        let id = this.channelId;
        if (id === this.channels[0].cId) {
          id = null;
        }

        if (id === null) {
          delete query.channel;
        } else {
          query.channel = id;
        }

        this.$router.replace({ query });
      } else {
        // url change
        this.nextUrl = null;
      }
    },
    dragenter(e) {
      this.hoverCounter++;
      if (
        this.hoverCounter > 0 &&
        e.dataTransfer &&
        e.dataTransfer.items &&
        e.dataTransfer.items.length === 1
      ) {
        if (e.dataTransfer.items[0].kind === 'file') {
          if (e.dataTransfer.items[0].type.startsWith('image')) {
            this.hoveringContent = true;
          } else {
            this.hoveringContent = false;
          }
        } else {
          this.hoveringContent = false;
        }
      } else {
        this.hoveringContent = false;
      }
    },
    dragleave() {
      this.hoverCounter--;
      if (this.hoverCounter === 0) {
        this.hoveringContent = false;
      }
    },
    drop(e) {
      e.preventDefault(); // Keep from leaving the page
      this.hoverCounter = 0; // Content can't be dragged out, so go ahead and reset the counter
      this.hoveringContent = false;
      if (e.dataTransfer.items && e.dataTransfer.items.length === 1) {
        // const rejected = [] // Keeps track of rejected items for reporting at the end
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          if (e.dataTransfer.items[i].kind === 'file') {
            // Directories are not supported. Skip any that are found
            if (e.dataTransfer.items[i].webkitGetAsEntry) {
              const entry = e.dataTransfer.items[i].webkitGetAsEntry();
              if (entry.isDirectory) {
                continue;
              }
            }
            const file = e.dataTransfer.items[i].getAsFile();
            if (file && file.type.startsWith('image')) {
              this.avatarFile = file;
            }
          }
        }
      }
    },
    onClosingAvatarEditor() {
      this.$refs.avatarEditor.getAvatarImage();
      this.generateAvatarDialog = null;
    },
    onCreateAvatarBase64(image) {
      this.avatarFile = ImageFileUtil.b64toBlob(image);
    },
    changeAlignmentOrigin(alignOrigin) {
      this.$store.commit('channelsettings/setAppearanceValue', {
        key: 'alignmentOrigin',
        value: alignOrigin,
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.nextUrl) {
      this.lostDialog = true;
      this.nextUrl = to;
      return next(false);
    }

    return next(true);
  },
};
</script>

<style lang="scss" scoped>
.floating {
  // elevation-4, but customized to be softer + a thin extra border
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.1),
              0 4px 5px 0 rgba(0,0,0,.07),
              0 1px 10px 0 rgba(0,0,0,.06);
  border: solid 3px white;
  border-radius: 12px;
}
.avatar {
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  svg,
  img {
    background: #d3d3d3;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    object-position: center center;
    align-self: flex-end;
    transform: scale(1.1);
    transition: transform 0.15s ease-in-out;
    &:hover {
      transform: scale(1.1);
      transition: transform 0.15s ease-in-out;
    }
    &:active {
      transform: scale(1.1);
      transition: transform 0.15s ease-in-out;
    }
    &.squared {
      border-radius: 10%;
      border-radius: 1px solid #d3d3d3;
    }
  }
}

.bg--active-off {
  background: #f7d6d6 !important;
}
</style>
