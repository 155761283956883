<script>
import { mapGetters } from 'vuex';
import BtnSquare from '@/components/common/BtnSquare.vue';

export default {
  name: 'RAGNewResourceExpertOptions',
  components: { BtnSquare },
  emits: ['input', 'valid'],
  props: {
    /**
     * v-model - Web scraping options
     * @type {RAGResourceScrapeOptions}
     */
    'value': {
      type: Object,
      default: () => ({})
    },
    // Show the label of the add header button
    'showHeaderLabel': {
      type: Boolean,
      default: false,
    },
    // The current settings is for a specific resource, as opposed to being the global scraping options
    'isSpecific': {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isValid: true,
      panelOpen: undefined,
      util: {
        username: '',
        password: '',
      },
      rules: {
        json: [
          (v) => !!v || this.$t('editor.ragTopic.newResourceForm.rules.jsonNotEmpty'),
          (v) => {
            try {
              JSON.parse(v);
              return true;
            } catch (error) {
              return this.$t('editor.ragTopic.newResourceForm.rules.jsonInvalid');
            }
          },
          (v) => {
            try {
              const obj = JSON.parse(v);
              const result = this.validateJSONObject(obj);
              if (result === true) return true;
              return result;
            } catch(_) {
              return this.$t('editor.ragTopic.newResourceForm.rules.jsonInvalid');
            }
          }
        ],
      },
      // Preview your custom settings merged with the global scraping settings
      showMergedResult: false,
    };
  },
  created() {
    // Setze showMergedResult auf true, falls allgemeine Scrape-Optionen in den Bot Settings vorhanden sind
    const settings = this.getBotSettings(this.currentBotId);
    const kbSettings = settings?.kb?.scrapeOpts ?? {};
    if (Object.keys(kbSettings).length > 0 && this.isSpecific) {
      this.showMergedResult = true;
    }
  },
  computed: {
    ...mapGetters('bots', ['getBotSettings', 'currentBotId']),
    valueAsString: {
      get() {
        return JSON.stringify(this.value || {}, null, 2);
      },
      set(string) {
        try {
          const obj = JSON.parse(string);
          this.$emit('input', obj);
        } catch(e) {
          this.isValid = false;
        }
      },
    },
    mergedResult() {
      const settings = this.getBotSettings(this.currentBotId);
      const kbSettings = settings?.kb?.scrapeOpts ?? {};

      /**
       * This is how the final settings are merged in
       * the backend whenever they are about to be used.
       */
      return JSON.stringify({
        ...kbSettings,
        ...this.value,
      }, null, 4);
    },

    /**
     * Return the headers as an array of objects
     * @returns {{key: string, value: string}[]}
     */
    headers: {
      get() {
        if (!this.value?.headers) return [];
        return Object.keys(this.value.headers).map(key => ({ key, value: this.value.headers[key] }));
      },
      set(headers) {
        const obj = {};
        for (const header of headers) {
          // We normalize header field names to lowercase, since headers are considered case-insensitive
          /**
           * MyKey: ValA, MyKey: ValB -> MyKey: ValB.
           *
           * If key exists, abort the update operation until they continue to edit the
           * value so that it is no longer identical. his is to prevent the latter
           * duplicate from overwriting and merging any former ones.
           */
          if (header.key.toLowerCase() in obj) return;
          obj[header.key.toLowerCase()] = header.value;
        }
        this.$emit('input', { ...this.value, headers: obj });
      },
    },
    /**
     * Base-64 encoded Basic Auth username and password
     * @returns {string}
     */
    base64EncodedUtil() {
      return btoa(`${this.util.username}:${this.util.password}`);
    },
  },
  methods: {
    ensureHeaderObject() {
      if (!this.value.headers) {
        return this.$emit('input', { ...this.value, headers: {} });
      }
    },
    /**
     * Adds a new header to the list
     */
    addHeader() {
      if (!this.value.headers) {
        this.$emit('input', { ...this.value, headers: {} });
        return this.$nextTick(this.addHeader);
      }

      // Will cover most cases
      if (!('new-header' in this.value.headers)) {
        return this.$set(this.value.headers, 'new-header', '');
      }

      // Any subsequent un-edited ones
      let i = 1;
      while((`new-header-${i}` in this.value.headers)) i++;
      this.$set(this.value.headers, `new-header-${i}`, '');
    },
    deleteHeader(headerKey) {
      this.$delete(this.value.headers, headerKey);

      /**
       * If no headers left, remove the object.
       * We do shallow-merge in the backend, so we don't want to
       * overwrite with empty headers.
       */
      if (!Object.keys(this.value.headers).length) {
        this.$delete(this.value, 'headers');
      }
    },
    triggerUpdate() {
      // Trigger serialization and emitting
      this.headers = this.headers;
    },
    /**
     * Checks if the given header is a basic auth one
     * @param {{key: string, value: string}} header
     * @returns {boolean}
     */
    isBasicAuth(header) {
      if (!header?.key || !header?.value) return false;
      return header.key.toLowerCase() === 'authorization'
        && header.value.toLowerCase().startsWith('basic');
    },
    validate() {
      this.$refs.expertOptions.validate();
    },
    /**
     * Make a dedicated validation per field, that simulates validation
     * with only this just this value/field being validated.
     *
     * This is specifically for the Headers key-value pairs.
     * @param {'key'|'value'} property
     * @returns {(value: string) =>true|string[]}
     */
    makeRules(property) {
      return [
        (v) => !!v || this.$t('editor.ragTopic.newResourceForm.rules.notEmpty'),
        (v) => {
          const header = {key: 'key', value: 'value'};
          header[property] = v;
          const otherwiseValidData = { headers: { [header.key]: header.value } };

          const result = this.validateJSONObject(otherwiseValidData);
          if (result === true) return true;
          return result;
        }
      ];
    },
    /**
     * Performs validation on the JSON object.
     * Returns a string if something is wrong, or `true` if everything is alright.
     * @param {RAGResourceScrapeOptions} value
     * @returns {true | string}
     */
    validateJSONObject(value) {
      // There may be unknown and new properties we support. For now, validate the ones we know of.

      if (value.headers) {
        if (typeof value.headers !== 'object') {
          return this.$t('editor.ragTopic.newResourceForm.rules.headerKVStrings', {type: typeof value.headers});
        }

        for (const key in value.headers) {
          if (typeof value.headers[key] !== 'string') {
            return this.$t('editor.ragTopic.newResourceForm.rules.headerKVStrings', {type: typeof value.headers[key]});
          }
          if (/^[a-z-_0-9.!%*`'~]{1,128}$/i.test(key) === false) {
            return this.$t('editor.ragTopic.newResourceForm.rules.headerNamePattern');
          }
          if (!value.headers[key].trim().length) {
            return this.$t('editor.ragTopic.newResourceForm.rules.headerValueEmpty');
          }
          if (value.headers[key].length > 4096) {
            return this.$t('editor.ragTopic.newResourceForm.rules.headerValueMaxLength', {max: 4096});
          }
        }
      }

      if (value.onlyMainContent) {
        if (typeof value.onlyMainContent !== "boolean") {
          return this.$t('editor.ragTopic.newResourceForm.rules.mainContentBoolean', {type: typeof value.onlyMainContent});
        }
      }

      if (value.timeout) {
        if (typeof value.timeout !== "number") {
          return this.$t('editor.ragTopic.newResourceForm.rules.timeoutNumber', {type: typeof value.timeout});
        }
        if (!Number.isInteger(value.timeout)) {
          return this.$t('editor.ragTopic.newResourceForm.rules.timeoutInteger');
        }
        if (value.timeout < 0) {
          return this.$t('editor.ragTopic.newResourceForm.rules.timeoutGTMin', {min: 0});
        }
        if (value.timeout > 1000*60*60) {
          return this.$t('editor.ragTopic.newResourceForm.rules.timeoutLTMax', {max: 1000*60*60});
        }
      }

      if (value.actions) {
        if (!Array.isArray(value.actions)) {
          return this.$t('editor.ragTopic.newResourceForm.rules.actionsArray', {type: typeof value.actions});
        }
      }

      return true;
    },
  },
  watch: {
    // Whenever the options change, re-validate them
    value: {
      handler() {
        this.validate();
      },
      deep: true,
    },
    // If the valid state changes, notify parent of it
    isValid(newValue) {
      this.$emit('valid', newValue);
    },
    // If the Base64 value changes, update the header key of the 'authorization' header (if it exists)
    base64EncodedUtil(newValue) {
      // It doesn't loop with the `value.headers.authorization` watched because `base64EncodedUtil` is lazy computed

      // Ignore when no string value or default empty state (':' -> Og==)
      if (!newValue || newValue === 'Og==' || !this.value?.headers) return;

      if (!("authorization" in this.value.headers)) return;
      this.value.headers.authorization = `basic ${newValue}`;
    },
    // If the Authorization header gets a new value, decode the base64 into the util properties
    'value.headers.authorization'(newValue) {
      if (!newValue) return;

      if (!newValue.toLowerCase().startsWith('basic ')) return;
      try {
        const string = atob(newValue.replace('basic ', ''));
        this.util.username = string.split(":")[0];
        this.util.password = string.split(":").slice(1).join(":");
      } catch(_) {}
    }
  }
};
</script>

<template>
  <v-form
    v-model="isValid"
    class="d-flex flex-column gap-4"
    ref="expertOptions"
  >
    <!---- Headers as expansion panels ----->
    <!-- Title -->
    <div class="d-flex gap-2 justify-space-between items-center">
      <h3 class="text-h6">
        {{ $t('editor.ragTopic.newResourceForm.expertMode.headers') }}
      </h3>
      <BtnSquare
        preset="default"
        icon="mdi-plus"
        small
        @click="addHeader"
      >
        <!--
        Avoid defining a $slot if not needed; it would
        alter the paddings when it assumes there is text
        -->
        <template v-if="showHeaderLabel" #default>
          {{ $t('editor.ragTopic.newResourceForm.expertMode.addHeader') }}
        </template>
      </BtnSquare>
    </div>

    <!-- Headers -->
    <p v-if="!headers.length">
      {{ $t('editor.ragTopic.newResourceForm.expertMode.headersEmpty') }}
    </p>
    <v-expansion-panels v-else v-model="panelOpen">
      <v-expansion-panel
        v-for="(header,i) in headers"
        :key="i"
      >
        <v-expansion-panel-header>
          <span class="text-truncate" style="font-family: monospace">
            {{ header.key || `&lt;${$t('editor.ragTopic.newResourceForm.expertMode.empty')}&gt;` }}:
            {{ header.value || `&lt;${$t('editor.ragTopic.newResourceForm.expertMode.empty')}&gt;` }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            :label="$t('editor.ragTopic.newResourceForm.expertMode.headerKey')"
            v-model="header.key"
            :rules="makeRules('key')"
            outlined
            dense
            @input="triggerUpdate"
          />
          <v-text-field
            :label="$t('editor.ragTopic.newResourceForm.expertMode.headerValue')"
            v-model="header.value"
            :rules="makeRules('value')"
            outlined
            dense
            @input="triggerUpdate"
          />

          <!-- Base-64 util when using Basic Auth -->
          <div v-if="isBasicAuth(header)" class="basic-auth-util mb-4">
            <h3 class="mb-2">{{ $t('editor.ragTopic.newResourceForm.expertMode.basicAuth') }}</h3>

            <v-text-field
              :label="$t('common.username')"
              v-model="util.username"
              outlined
              dense
            />
            <v-text-field
              :label="$t('common.password')"
              v-model="util.password"
              outlined
              dense
            />
          </div>

          <BtnSquare
            icon="mdi-trash-can-outline"
            preset="outlined-white"
            @click="deleteHeader(header.key)"
          >
            {{ $t('common.remove') }}
          </BtnSquare>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-divider />

    <div class="d-flex gap-4 justify-space-between items-baseline">
      <h3 class="text-h6">
        {{ $t('editor.ragTopic.newResourceForm.expertMode.jsonEditor') }}
      </h3>

      <!-- Buttons to preview the final merged state -->
      <v-switch
        v-if="isSpecific"
        v-model="showMergedResult"
        :label="$t('editor.ragTopic.newResourceForm.expertMode.previewGlobal')"
        class="mt-0 pt-0"
        persistent-hint
        :hint="$t('editor.ragTopic.newResourceForm.expertMode.previewGlobalHint')"
        inset
        color="green"
      />
    </div>

    <!-- JSON editor for options -->
    <v-textarea
      v-if="!showMergedResult"
      v-model="valueAsString"
      class="mb-2"
      label="JSON"
      outlined
      auto-grow
      :rules="rules.json"
    />
    <v-textarea
      v-else
      v-model="mergedResult"
      class="mb-2"
      label="JSON"
      outlined
      auto-grow
      persistent-hint
      :hint="$t('editor.ragTopic.newResourceForm.expertMode.previewJSONHint')"
      readonly
      :rules="rules.json"
    />
  </v-form>
</template>

<style scoped>
.basic-auth-util {
  border: solid thin lightgrey;
  padding: 1rem;
  border-radius: 6px;
}
</style>