<template>
  <HubBox
    :title="$t('forms.generalEdit.title')"
    boxId="forms-general"
    :isLoading="isLoading"
  >
    <div class="text-body-2">{{ $t('forms.generalEdit.description') }}</div>

    <div class="mt-4">
      <div class="primary--text text--lighten-3 mb-1 text-body-2">
        {{ $t('forms.generalEdit.displayName') }}
      </div>
      <div>{{ form.displayName }}</div>
    </div>

    <div class="mt-6">
      <v-btn outlined color="primary lighten-3" @click="editDialogOpen = true">
        {{ $t('common.edit') }}
        <v-icon right>edit</v-icon>
      </v-btn>
      <BtnSquare
        v-if="isAdmin"
        class="ml-2"
        colorBtn="admin"
        colorIcon="admin"
        icon="mdi-trash-can"
        @click="deleteDialogOpen = true"
      />
    </div>

    <HubDialog
      :value="editDialogOpen"
      :primary-label="$t('common.save')"
      primary-icon="save"
      :secondary-label="$t('common.cancel')"
      secondary-icon="close"
      :title="$t('common.edit')"
      @onPrimary="updateForm()"
      @onSecondary="cancelEdit()"
      modal
    >
      <div>
        <v-row class="mt-4 mb-2">
          <v-col cols="12">
            <v-text-field
              class="mb-2"
              v-model="displayName"
              outlined
              color="secondary darken-2"
              autofocus
              hide-details
            />
            <InfoBox
              :text="$t('forms.generalEdit.hint')"
              :icon="null"
            />
          </v-col>
        </v-row>
      </div>
    </HubDialog>

    <HubDialog
      v-if="isAdmin"
      :value="deleteDialogOpen"
      :primary-label="$t('common.delete')"
      primary-icon="save"
      :secondary-label="$t('common.cancel')"
      secondary-icon="close"
      title="Formular löschen"
      @onPrimary="deleteForm()"
      @onSecondary="cancelDelete()"
      modal
    >
      <div>
        <v-row class="mt-4 mb-4">
          <v-col cols="12">
            <div class="text-copy-1 mb-2">
              Bist du sicher, dass du das Formular löschen möchtest? Das
              Formular wird unwiderruflich gelöscht.
            </div>
          </v-col>
        </v-row>
      </div>
    </HubDialog>
  </HubBox>
</template>

<script>
import { mapGetters } from 'vuex';
import HubBox from '@/components/hub/HubBox.vue';
import InfoBox from '@/components/common/InfoBox.vue';
import HubDialog from '../hub/HubDialog.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import ProductService from '@/services/product';

export default {
  name: 'FormsGeneral',
  components: {
    HubBox,
    InfoBox,
    HubDialog,
    BtnSquare,
  },
  props: {
    form: Object,
  },
  data() {
    return {
      editDialogOpen: false,
      deleteDialogOpen: false,
      displayName: '',
      isLoading: false,
    };
  },
  created() {
    this.displayName = String(this.form?.displayName || '');
  },
  watch: {
    form: {
      handler(newForm) {
        this.form = newForm;
        this.displayName = String(this.form?.displayName || '');
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('forms', ['forms']),
    ...mapGetters('bots', ['currentBotId']),
  },
  methods: {
    async updateForm() {
      this.isLoading = true;
      await ProductService.updateFormDisplayName(
        this.currentBotId,
        this.form.intent,
        this.displayName
      );

      const forms = [...this.forms];
      const formIndex = forms.findIndex(
        (form) => form.intent === this.form.intent
      );
      forms[formIndex].displayName = this.displayName;

      this.$store.commit('forms/setForms', forms);

      this.isLoading = false;
      this.editDialogOpen = false;
    },
    cancelEdit() {
      this.editDialogOpen = false;
    },
    async deleteForm() {
      this.isLoading = true;

      await ProductService.deleteForm(this.currentBotId, this.form.intent);

      const forms = this.forms.filter(
        (form) => form.intent !== this.form.intent
      );
      this.$store.commit('forms/setForms', forms);

      this.isLoading = false;
      this.editDialogOpen = false;
      this.$emit('onChange');
    },
    cancelDelete() {
      this.deleteDialogOpen = false;
    },
  },
};
</script>
