import RAGServiceV2 from '@/services/rag_v2';
import RAGService from '@/services/rag';
import { parseMarkdownArrayToString } from '@/utils/markdown';
import Vue from "vue";
import {
  LegacyMarkdownDocumentType,
  ResourceDraftType,
  MarkdownPath,
  TitlePath,
} from '@/components/knowledgeBase/MDDocumentAdapter';
import { get, set, isEqual, merge as lodashMerge } from 'lodash';

const state = {
  /**
   * Resources for multiple bots
   */
  resources: {},
  /**
   * Drafts for resources/resource content.
   * Contains new and edits to existing resources.
   */
  resourceDrafts: {},
  /**
   * A list of resources that are queued for deletion when you save changes
   * @type {{botId: string, docId: string}[]}
   */
  queuedDeletions: [],
  /**
   * A list of resource connections that are queued for alteration when you save changes
   * @type {Array<{botId: string, intent: string, state: {state: string, forward?: string, active: boolean}}>}
   */
  queuedIntentStates: [],
};

const getters = {
  /**
   * Returns a map of all resources
   * @param state
   * @returns {Record<string, Record<string, object>>}
   */
  resources: state => state.resources,
  /**
   * Returns a map of all draft resources
   * @param state
   * @returns {Record<string, Record<string, object>>}
   */
  resourceDrafts: state => state.resourceDrafts,
  /**
   * Get a resource by its Document ID
   * @param state
   * @returns {(botId: string, docId: string) => Object | null}
   */
  getResource: state => (botId, docId) => {
    return state.resources?.[botId]?.[docId] || null;
  },
  /**
   * Return a list of all resources for a bot
   * @param state
   * @returns {(botId: string, resourceIds?: string[]) => RAGResourceOverview[]} Function will return an iterable list of resource objects.
   * Optionally, it will return only the resources with the given IDs that it can find.
   */
  getResources: state => (botId, resourceIds) => {
    const resources = Object.values(state.resources?.[botId] ?? {});
    if (resourceIds?.length) return resources.filter(resource => resourceIds.includes(resource._id));
    return resources;
  },
  /**
   * Get the Markdown content for an input resource
   * @param state
   * @returns {(botId: string, docId: string) => string | null} Function will return `null` if nothing was found
   */
  getMarkdown: state => (botId, docId) => {
    const resource = state.resources?.[botId]?.[docId];
    if (!resource) return null;
    return get(resource, MarkdownPath[resource.type], null);
  },
  /**
   * Get the Markdown content of a draft resource
   * @returns {(resource: {type: RAGResourceType} & Object) => string | null} Function will return `null` if nothing was found
   */
  getMarkdownOfResource: () => (resource) => {
    return get(resource, MarkdownPath[resource.type + ''], null)
  },
  /**
   * Gets the markdown content draft for a resource, if there is any
   * @param state
   * @returns {(botId: string) => ({_id: string, _type: ResourceDraftType} & Object)[]} Function will return an iterable list of resource objects
   */
  getResourceDrafts: state => (botId) => {
    return Object.values(state.resourceDrafts?.[botId] ?? {});
  },
  /**
   * Gets the markdown content draft for a resource, if there is any
   * @param state
   * @returns {(botId: string, docId: string) => ({_id: string, _type: ResourceDraftType} & Object) | null} Function will return null if nothing was found
   */
  getResourceDraft: state => (botId, docId) => {
    return state.resourceDrafts?.[botId]?.[docId] || null;
  },
  /**
   * Checks if the resource is modified somehow
   * @param state
   * @param getters
   * @returns {(botId: string, docId: string, fields?: string[]) => boolean}
   */
  isResourceModified: (state, getters) => (botId, docId, fields) => {
    if (!fields) fields = [
      'metaData',
      'intents',
      'status',
      'content',
    ];

    const draft = structuredClone(getters.getResourceDraft(botId, docId));
    if (!draft) return false; // Can't possibly be edited then

    const original = structuredClone(getters.getResource(botId, docId));
    if (!original) {

      /**
       * Edge case: no resources are loaded, but the draft for edits to an
       * existing resource is present. In this case, you'd need the original
       * either way to compare to, to know if the draft is modified or not.
       */

      return true; // New doc is always modified
    }

    const originalProps = fields.reduce((acc, field) => {
      acc[field] = get(original, field);
      return acc;
    }, {});
    const draftProps = fields.reduce((acc, field) => {
      acc[field] = get(draft, field);
      return acc;
    }, {});

    return !isEqual(draftProps, originalProps);
  },
};

const actions = {
  /**
   * Fetches all resources for a bot from backend
   * @param commit
   * @param {string} botId
   * @param {?boolean} [merge=true] If `true`, it will merge the new resources with the existing ones
   * @throws {Error} If something went wrong fetching the resources.
   * @returns {Promise<RAGResourceOverview[]>}
   */
  async fetchResources({ commit }, { botId, merge }) {
    const resources = await RAGServiceV2.getAllByBot(botId);
    if (resources !== null) commit('setResources', { botId, resources, merge });
    return resources;
  },
  /**
   * Fetches the Markdown content for a resource from backend
   * @param commit
   * @param state
   * @param {string} botId
   * @param {string} docId Resource document ID. Depends on the resources already being fetched.
   * @param {string} [pageLabel="Page"] The "Page N" label to use when joining multiple pages of markdown
   * @returns {Promise<string | null>} The markdown content (could be empty string), or `null` if resource was not found
   */
  async fetchMarkdown({ commit, state }, { botId, docId, pageLabel }) {
    const resource = state.resources[botId]?.[docId];
    if (!resource || !resource.ressourceId) return null;

    const array = await RAGService.getRAGResourceMarkdown({ botId: botId, resourceId: resource.ressourceId });
    let markdown = parseMarkdownArrayToString(array, {
      pageIdentification: true,
      pageText: pageLabel ?? 'Page',
      /**
       * Separator must be space, else tables split across documents will break.
       * @see https://github.com/knowhereto/moin-hub/issues/1565
       */
      separator: ' '
    });

    if (resource.type === 'QNA' && !!markdown) {
      /**
       * Special handling:
       * QNA will have title inside its body. It must be extracted out into an `answer` property.
       * Question:\n Question here\n\n Answer:\n Answer here...
       */
      const split = markdown.split('\n\n Answer:\n');
      const data = {
        question: split[0].replace('Question:\n', '').trim() ?? '',
        // Re-construct the rest of the array, in the odd-case user literally had `\n\n Answer:\n` in their answer
        answer: split.slice(1).join('\n\n Answer:\n')?.trim() ?? '',
      };

      markdown = data.answer;
      // Set title too since we have it
      if (data.question) {
        set(resource, TitlePath.QNA, data.question);
        set(resource, TitlePath.QNAFallback, data.question);
        commit('setResources', { botId, resources: [resource] });
      }
    }

    commit('setMarkdown', { botId, docId, markdown });
    return markdown;
  },
  /**
   * Publishes a new document, and caches it in the `resources`
   * @param commit
   * @param {string} botId
   * @param {string} channelId
   * @param {RAGResourceDraftDocument} resource
   * @param {{intent: string, box: string}[]} connectToIntents
   * @returns {Promise<{resource: RagPDF | RagWEBPAGE | RagMARKDOWN | RagQNA | RagTABLE, intents: Object[]}>} The new full resource document from API
   */
  async publishNewResource({ commit }, { botId, channelId, resource, connectToIntents }) {
    if (ResourceDraftType.NEW_DRAFT !== resource._type) {
      console.error(`Expected resource to be a ${ResourceDraftType.NEW_DRAFT}, got ${resource._type}.`);
    }

    // TODO re-evaluate if necessary if Mongo stores it too
    if (resource.type === 'QNA') {
      // Special handling - Keep copy of title outside `content`, so it's instantly available
      set(resource, TitlePath.QNAFallback, get(resource, TitlePath.QNA, 'Question & Answer'));
    }

    const result = await RAGServiceV2.addNewResource(botId, channelId, resource, connectToIntents);
    if (result.status !== 'ok') return null;

    result.resource._type = ResourceDraftType.PUBLISHED;
    commit('setResources', { botId, resources: [result.resource] });

    return {
      resource: result.resource,
      intents: result.intents,
    };
  },
  /**
   * Perform an update on an existing document, and caches new state in `resources`
   * @template {RAGResourceDraftDocument | RagPDF | RagWEBPAGE | RagMARKDOWN | RagQNA} InputResource
   * @param commit
   * @param {string} botId
   * @param {InputResource} resource The whole resource
   * @returns {Promise<InputResource>} The resource you passed in
   */
  async updateExistingResource({ commit }, { botId, resource }) {
    if (resource.type === 'QNA') {
      // Special handling - Keep copy of title outside `content`, so it's instantly available
      set(resource, TitlePath.QNAFallback, get(resource, TitlePath.QNA, 'Question & Answer'));
    }

    const publishedVersion = await RAGServiceV2.updateResource(botId, resource._id, resource);
    if (!publishedVersion) return;

    const draftVersion = structuredClone(publishedVersion);
    draftVersion._type = resource._type;

    // Store the published version
    publishedVersion._type = ResourceDraftType.PUBLISHED;
    commit('setResources', { botId, resources: [publishedVersion] });

    // Update the draft
    if ([ResourceDraftType.DRAFT, ResourceDraftType.NEW_DRAFT].includes(draftVersion._type)) {
      commit('updateDraft', { botId, draft: draftVersion });
    }

    return draftVersion;
  },
  /**
   * Update only specific parts of a resource. Does not update local cache.
   * @template {RAGResourceDraftDocument | RagPDF | RagWEBPAGE | RagMARKDOWN | RagQNA} InputResource
   * @param {string} botId
   * @param {string} docId
   * @param {Partial<InputResource>} partialResource The fields and values to update
   * @returns {Promise<void>}
   */
  updateResourcePartial({}, { botId, docId, partialResource }) {
    return RAGServiceV2.updateResource(botId, docId, partialResource);
  },
  /**
   * Assigns intents to a resource, per channel
   * @param commit
   * @param getters
   * @param {string} botId
   * @param {string} docId
   * @param {RagIntent[]} intents
   * @returns {Promise<void>}
   */
  async setAssignedIntents({ commit, getters }, { botId, docId, intents }) {
    await RAGServiceV2.setAssignedIntents(botId, docId, intents);

    const cached = getters.getResource(botId, docId);
    if (cached) {
      cached.intents = intents;
      commit('setResources', { botId, resources: [cached] });
    }
  },
  /**
   * Starts an interval that checks for changes to a resource's status.
   * Once the status is changed, or it has been too long, the interval is cleared.
   * If the status is changed, it will update the resource with the new status.
   * @param commit
   * @param getters
   * @param {string} botId
   * @param {string} docId The ID of the resource to start checking
   * @returns {void}
   */
  startStatusChangeChecker({ commit, getters }, { botId, docId }) {
    const originalStatus = getters.getResource(botId, docId)?.status;
    if (!originalStatus) return;

    // Start background task
    (async () => {
      // Function to execute once it detects a change in status
      const changedCb = ({botId, docId, status}) => {
        const resource = getters.getResource(botId, docId);
        if (resource) {
          resource.status = status;
          commit('setResources', { botId, resources: [resource] });
        }
      };

      await refreshResourceState({
        changedCb,
        botId,
        docId,
        originalStatus,
        maxTries: 12,
        timeoutMultiplier: [2, 2, 2],
      });
    })();
  },
  /**
   * A dedicated function that port's user's existing browser drafts from the old system with Content Store over to this one.
   * Can safely be removed Jan 2025 when pretty much nobody has any old drafts left in their browser.
   * Also remove the `LegacyMarkdownDocumentType` enum when you do.
   *
   * This function is only called in the `AppBotLoader.vue` file.
   *
   * See legacy documentation for how the system worked here:
   * https://github.com/knowhereto/moin-hub/blob/8022f38876a2a8669a4ea29a9806bc0b1cc65318/src/components/knowledgeBase/MarkdownDocuments.md
   */
  migrateLegacyDrafts({ rootState, commit }, { testMode }) {
    /**
     * const state = {
     *   contentDrafts: {
     *     botIdHere: {
     *       '5f7b1b7b7b7b7b7b7b7b7b7b': {
     *         KnowledgeBaseDocumentDraft: {
     *           box: 'KnowledgeBaseDocumentDraft',
     *           _id: '5f7b1b7b7b7b7b7b7b7b7b7b',
     *           // ... rest of the Markdown resource
     *         }
     *       }
     *     }
     *   }
     * }
     */
    const drafts = [LegacyMarkdownDocumentType.DRAFT, LegacyMarkdownDocumentType.NEW_DRAFT];
    const reference = LegacyMarkdownDocumentType.PUBLISHED;
    const typeMap = {
      [LegacyMarkdownDocumentType.NEW_DRAFT]: ResourceDraftType.NEW_DRAFT,
      [LegacyMarkdownDocumentType.DRAFT]: ResourceDraftType.DRAFT,
      [LegacyMarkdownDocumentType.PUBLISHED]: ResourceDraftType.PUBLISHED,
    };
    let migrated = 0;

    for (const botId in rootState.content.contentDrafts) {
      for (const boxId in rootState.content.contentDrafts[botId]) {
        for (const draftType in rootState.content.contentDrafts[botId][boxId]) {
          // This box is a reference used for change comparisons. It can safely be deleted without any further work.
          if (draftType === reference) {
            console.debug(`[KB Migration] Removing reference object ${boxId}/${draftType}.`);
            if (!testMode) Vue.delete(rootState.content.contentDrafts[botId][boxId], draftType);
            if (!(Object.keys(rootState.content.contentDrafts[botId][boxId]).length)) {
              console.debug(`[KB Migration] Removing empty parent object ${boxId}.`);
              Vue.delete(rootState.content.contentDrafts[botId], boxId);
            }
            continue;
          }

          // Non-Markdown Draft draft
          if (!drafts.includes(draftType)) continue;
          console.debug(`[KB Migration] Processing ${boxId}/${draftType}...`);

          const box = rootState.content.contentDrafts[botId][boxId][draftType];

          /**
           * @type {RAGResourceDraftDocument}
           */
          const resourceDraft = {
            _id: box._id || boxId,
            _type: typeMap[draftType] || ResourceDraftType.NEW_DRAFT,
            type: box.type || "MARKDOWN",
            date: box.date || new Date(),
            updatedAt: box.updatedAt || new Date(),
            metaData: box.metaData || {},
            intents: box.intents || [],
            content: {},
          };

          // Extract the content and title for MARKDOWN and QNA
          const markdownContent = box.content?.markdown || box.content?.answer || "";
          const title = box.content?.question || box.metaData?.title || box.metaData?.originalTitle || "";
          // Place the content and title in the new box
          set(resourceDraft, MarkdownPath[resourceDraft.type], markdownContent);
          set(resourceDraft, TitlePath[resourceDraft.type], title);
          // For QNA, set an additional fallback copy, so it can be read outside of content being processed
          if (resourceDraft.type === 'QNA') set(resourceDraft, TitlePath.QNAFallback, title);

          // Store in the new draft container
          commit('updateDraft', {
            botId,
            draft: resourceDraft,
          });
          // Remove it from the old draft container
          if (!testMode) Vue.delete(rootState.content.contentDrafts[botId][boxId], draftType);

          migrated++;
          console.debug(`[KB Migration] Migrated draft ${migrated} ${boxId}/${resourceDraft._id}.\nOld box:`, JSON.stringify(box));

          // Cleanup its parent container if it is now empty. Anything higher level will auto-cleanup on its own.
          if (!(Object.keys(rootState.content.contentDrafts[botId][boxId]).length)) {
            console.debug(`[KB Migration] Removing empty parent object ${boxId}.`);
            Vue.delete(rootState.content.contentDrafts[botId], boxId);
          }
        }
      }
    }

    console.debug(`[KB Migration] Migration completed. Migrated ${migrated} drafts.`);
  },
  /**
   * Create a new action for the bot's RAG system.
   * @param commit
   * @param state
   * @param {string} botId
   * @param {RAGActionPayload} payload
   * @returns {Promise<{ success: boolean, ragActions?: IntentRagActions }>}
   */
  async publishNewAction({ commit, state }, { botId, payload }) {
    const result = await RAGServiceV2.addNewAction(botId, payload);

    if (result.error) {
      commit('hubUi/addAlert', {
        message: result.message || 'An unknown error occurred when trying to create a new action',
        type: 'error',
      }, { root: true });
      return { success: false }
    }

    return {
      success: true,
      ragActions: result.ragActions,
    };
  },
  /**
   * Deletes a specific action from the bot's RAG system.
   * @param commit
   * @param state
   * @param {string} botId The ID of the bot
   * @param {string} intent The intent technical name it is found in
   * @param {string} channelId The ID of the channel the action is in
   * @param {string} id The ID of the action
   * @returns {Promise<{success: boolean, ragActions?: IntentRagActions}>}
   */
  async deleteAction({ commit, state }, { botId, intent, channelId, id }) {
    const result = await RAGServiceV2.deleteAction(botId, intent, id, channelId);

    if (result.error) {
      commit('hubUi/addAlert', {
        message: result.message || 'An unknown error occurred when trying to delete an action',
        type: 'error',
      }, { root: true });
      return { success: false };
    }

    return {
      success: true,
      ragActions: result.ragActions,
    };
  }
};

const mutations = {
  /**
   * Creates a new resource draft, for brand-new documents
   * @template {Object} ResourceDraft
   * @template {string} ResourceDraftId
   *
   * @param state
   * @param {string} botId
   * @param {ResourceDraft} resource
   * @param {?ResourceDraftId} [id] ID of the draft.
   * Created for you if not provided.
   */
  createNewDraft(state, { botId, resource, id }) {
    if (!id) id = Math.random().toString(36).slice(2);
    resource._id = id;
    resource._type = ResourceDraftType.NEW_DRAFT;

    ensureProp(state.resourceDrafts, botId);
    Vue.set(state.resourceDrafts[botId], id, resource);
  },
  /**
   * Converts an existing resource into a draft for editing purposes.
   * Stores a new `resourceDraft`, and keeps the original resource in `resources`
   * for comparisons and restoring.
   * @param state
   * @param {string} botId
   * @param {{_id: string} & Object} resource
   */
  convertToDraft(state, { botId, resource }) {
    ensureProp(state.resourceDrafts, botId);
    const draft = structuredClone(resource);
    draft._type = ResourceDraftType.DRAFT;
    state.resourceDrafts[botId][resource._id] = draft;

    // Make sure the original is also stored in resources
    ensureProp(state.resources, botId);
    state.resources[botId][resource._id] = resource;
  },
  /**
   * Removes a resource draft
   * @param state
   * @param {string} botId
   * @param {string} docId
   */
  removeDraft(state, {botId, docId}) {
    ensureProp(state.resourceDrafts, botId);
    Vue.delete(state.resourceDrafts[botId], docId);
    this.commit('knowledgeBase/autoCleanupDrafts');
  },
  /**
   * Updates an existing draft, or creates a new one if it does not exist yet.
   * For creation, you would ideally use `convertToDraft` or `createNewDraft` instead.
   * @param state
   * @param {string} botId
   * @param {{_id: string, updatedAt?: Date} & Object} draft The new state of the draft
   */
  updateDraft(state, {botId, draft}) {
    ensureProp(state.resourceDrafts, botId);
    Vue.set(draft, 'updatedAt', new Date());
    Vue.set(state.resourceDrafts[botId], draft._id, draft);
  },
  /**
   * Adds new resource(s) to the bot, overwriting any conflicting ones
   * @param state
   * @param {string} botId
   * @param {?boolean} [merge=false] If a resource exists, it will merge the new state into the old instead of replacing the entire object
   * @param {RAGResourceOverview[] | ({_id: string} & Object)[]} resources
   */
  setResources(state, { botId, resources, merge }) {
    ensureProp(state.resources, botId);

    resources.forEach(resource => {
      resource._type = ResourceDraftType.PUBLISHED;

      // If merge is enabled, and resource exists, merge the new state into the old
      if (merge && state.resources[botId][resource._id]) {
        resource = lodashMerge(state.resources[botId][resource._id], resource);
      }

      Vue.set(state.resources[botId], resource._id, resource);
    });
  },
  /**
   * Removes one or more resource(s) from the bot
   * @param state
   * @param {string} botId
   * @param {string[]} docIds Resource Object IDs
   */
  removeResources(state, { botId, docIds })  {
    ensureProp(state.resources, botId);

    docIds.forEach(resourceId => {
      Vue.delete(state.resources[botId], resourceId);
    });

    this.commit('knowledgeBase/autoCleanupDrafts');
  },
  /**
   * Marks resources as to-be-deleted (draft).
   * Puts them into a deletion queue you can later remove them from again.
   * When the deletion process is initiated, it will use this queue.
   * @param state
   * @param {string} botId
   * @param {string[]} docIds Resource Object IDs
   */
  markDeleteResources(state, { botId, docIds }) {
    state.queuedDeletions.push(
      ...docIds.map(docId => ({ botId, docId }))
    );
  },
  /**
   * Un-marks resources as not to be deleted after all (un-draft).
   * Removes them from the deletion queue, so that they don't get deleted
   * when you start processing deletions.
   * @param state
   * @param {string} botId
   * @param {string[]} docIds Resource Object IDs
   */
  unmarkDeleteResource(state, { botId, docIds }) {
    state.queuedDeletions = state.queuedDeletions
      .filter(d => !(d.botId === botId && docIds.includes(d.docId)))
  },
  /**
   * Sets/overwrites the Markdown content for a resource
   * @param state
   * @param {string} botId
   * @param {string} docId The resource document ID
   * @param {string} markdown Markdown content
   */
  setMarkdown(state, { botId, docId, markdown }) {
    // Get the resource
    const resource = state.resources[botId]?.[docId];
    if (!resource) return;

    // Use the path to know where to put its Markdown
    let markdownPath = MarkdownPath[resource.type];
    if (!markdownPath) markdownPath = 'markdown'; // Fallback; ideally, it is always defined in `MarkdownPath`!

    /**
     * Set the Markdown at that path in the resource
     * markdownPath = "some.nested.path"
     * resource: {some: {nested: {path: "Markdown content"}}}
     */
    const parts = markdownPath.split(".");
    let ref = resource;
    for (let i=0; i<parts.length; i++) {
      // Ensure path
      if (parts[i+1]) {
        ensureProp(ref, parts[i]);
        ref = ref[parts[i]];
        continue;
      }

      // Set the content
      Vue.set(ref, parts[i], markdown);
    }
  },

  /**
   * Gets rid of empty localStorage draft containers for bots
   * @param state
   * @private
   */
  autoCleanupDrafts(state) {
    for (const botId in state.resourceDrafts) {
      if (Object.keys(state.resourceDrafts[botId]).length === 0) {
        Vue.delete(state.resourceDrafts, botId);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

/**
 * Simply ensures that the property key exists in the object.
 * Sets an empty object if it does not.
 * @param {Object} obj
 * @param {string} prop
 */
const ensureProp = (obj, prop) => {
  if (!(prop in obj)) Vue.set(obj, prop, {});
};

/**
 * Refreshes the status of a specific resource element by tracking its status.
 *
 * @param {Object} options - The options for refreshing the resource state.
 * @param {({botId: string, docId: string, status: string}) => void} options.changedCb - The callback function to call when the status changes.
 * @param {string} options.botId - The ID of the bot
 * @param {string} options.originalStatus - The original status of the resource, used to compare against.
 * @param {string} options.docId - The Object ID of the resource to refresh.
 * @param {number} [options.maxTries=3] - The maximum number of retries before giving up. Defaults to 3.
 * @param {number} [options.timeout=2500] - The initial timeout in milliseconds before retrying. Defaults to 2500ms.
 * @param {number} [options.timeoutMultiplier=[1]] - The multiplier to apply to the timeout for each retry. Defaults to 1.
 * @param {number} [options._currTry=0] - Internal counter for the current retry attempt (not for user consumption).
 * @returns {Promise<void>} A promise that resolves when the refresh is complete or fails after retries.
 *
 * @example
 * // Example usage:
 * const originalStatus = 'uploading';
 * const changedCb = ({botId, docId, status}) => {
 *   console.log(`Status of ${docId} changed to ${status} in ${botId}!`);
 * }
 * await refreshResourceState({ changedCb, botId, originalStatus, docId: 'id', maxTries: 5, timeout: 2500, timeoutMultiplier: [2,3] });
 *
 * // Explanation:
 * // - maxTries: 5 means the function will retry up to 5 times.
 * // - timeout: 2500ms is the initial delay before the first retry.
 * // - timeoutMultiplier: [2,3] means the timeout will double after first try and x3 after second.
 * //
 * // Total duration calculation:
 * // - 1st try: 2500ms
 * // - 2nd try: 2500ms * 2 = 5000ms
 * // - 3rd try: 5000ms * 3 = 15000ms
 * // - 4th try: 15000ms * 1 = 15000ms // x1 because timeoutMultiplier is out of range
 * // - 5th try: 15000ms * 1 = 15000ms // x1 because timeoutMultiplier is out of range
 * //
 * // Total time = 2500ms + 5000ms + 15000ms + 15000ms + 15000ms = 52500ms (52.5 seconds) | 0min 52.5sec
 */
async function refreshResourceState({ changedCb, botId, originalStatus, docId, maxTries = 3, timeout = 2500, timeoutMultiplier = [1], _currTry = 0 }) {
  console.log("[%s] CHECKING STATUS AGAIN", _currTry);
  ++_currTry;
  console.log(docId, _currTry, maxTries);
  if (!docId || _currTry > maxTries) return;

  let timeoutMs = timeout;

  if (Array.isArray(timeoutMultiplier) && timeoutMultiplier.length > 0) {
    const multiplier = Number(timeoutMultiplier[_currTry - 1]) || 1; // If out of range, then x1;
    timeoutMs = timeout * multiplier;
  }

  await new Promise((resolve) => {
    setTimeout(async () => {

      const data = await RAGServiceV2.getByParams({ botId, id: docId });
      console.log("Status data:", data);
      if (!data || !data.status) return resolve();  // STOP Tracking

      // Keep tracking status if identical
      if (data.status === originalStatus) {
        return resolve(
          refreshResourceState({ changedCb, botId, originalStatus, id, ressourceId, maxTries, timeout: timeoutMs, timeoutMultiplier, _currTry })
        );
      }

      changedCb({
        botId,
        docId,
        status: data.status
      });

      return resolve(); // STOP Tracking
    }, timeoutMs);
  })
}