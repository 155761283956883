<template>
  <div
    class="px-4 pt-8 pb-4 mx-auto moin-fade-in d-flex items-center justify-center"
    style="height: 100%; max-width: 400px; max-height: 1000px"
  >
    <div>
      <DemoMoinAvatarBubble
        v-if="!bubbleAni"
        class="mx-auto mb-4 moin-fade-in"
        :text="knowledge[itemIndex]"
      />
      <DemoMoinAvatar class="mb-8 mx-auto" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DemoMoinAvatar from '@/components/demo/components/DemoMoinAvatar.vue';
import DemoMoinAvatarBubble from '@/components/demo/components/DemoMoinAvatarBubble.vue';

export default {
  name: 'DemoSidebar',
  components: {
    DemoMoinAvatar,
    DemoMoinAvatarBubble,
  },
  computed: {
    ...mapGetters('demo', ['step']),
  },
  created() {
    for (let i = 0; i < 9; i++) {
      this.knowledge.push(this.$t(`demo.sidebar.knowledge.${i}`));
    }
    console.log(this.knowledge);
    this.nextItem();
  },
  data() {
    return {
      knowledge: [],
      itemIndex: -1,
      bubbleAni: false,
    };
  },
  methods: {
    async nextItem() {
      this.bubbleAni = true;
      await this.$nextTick();
      this.bubbleAni = false;
      this.itemIndex = (this.itemIndex + 1) % this.knowledge.length;
      setTimeout(() => {
        this.nextItem();
      }, 10000);
    },
  },
};
</script>
