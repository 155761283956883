import ApiService from './api';
import moment from 'moment';

const ProductService = {
  getIntents: async function(botId) {
    try {
      const response = await ApiService.get('/bot/faq/' + botId);
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getIntentDetails: async function(botId, template, intent) {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/intent/${template}/${intent}/${botId}`
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getForms: async function(botId) {
    try {
      const response = await ApiService.get('/schaltzentrale/forms/' + botId);
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getAllForms: async function(botId) {
    try {
      const response = await ApiService.get(
        '/schaltzentrale/forms/get/' + botId
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  deleteForm: async function(botId, intent) {
    try {
      const response = await ApiService.delete(
        '/schaltzentrale/forms/' + botId + '/' + intent
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  updateFormDisplayName: async function(botId, intent, displayName) {
    try {
      const response = await ApiService.put(
        '/schaltzentrale/forms/' + botId + '/' + intent,
        {
          displayName,
        }
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getContent: async function(botId, template, intent, box, channel) {
    try {
      let url = `/schaltzentrale/intent/content/${botId}/${intent}/${template}`;
      if (box) {
        url += `/box/${box}`;
      } else {
        url += `/box`;
      }

      if (channel) {
        url += `/channel/${channel}`;
      } else {
        url += `/channel`;
      }

      const response = await ApiService.get(url);
      return response.data;
    } catch (error) {
      return {};
    }
  },
  saveContent: async function(botId, box, content) {
    /* console.log('SAVE', botId, box, content); */

    try {
      const response = await ApiService.post(
        '/schaltzentrale/intent/' + botId,
        content
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  changeMeasurement: async function(botId, intent, measurement) {
    const body = {
      intent,
      measurement,
    };

    try {
      const response = await ApiService.post(
        `/schaltzentrale/intent/measurement/` + botId,
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  createChannelContent: async function(botId, template, intent, channel) {
    try {
      const response = await ApiService.post(
        '/schaltzentrale/intent/' + botId + '/create',
        {
          template,
          intent,
          channel,
        }
      );

      return response.data;
    } catch (error) {
      return {};
    }
  },
  updateBotContent: async function(botId, intent, type, actions) {
    try {
      const response = await ApiService.post(
        '/schaltzentrale/intent/' + intent + `/${type}/` + botId,
        actions
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  /**
   * @returns {Promise<{} | {status: string, intent?: Object, message?: string, tags?: string[]}>}
   */
  updateIntent: async function(intent) {
    try {
      const body = {
        uniqueBotId: intent.botId,
        intent: intent.intent,
        displayName: intent.displayName,
        description: intent.description,
        zeroshotLabel: intent.zeroshotLabel,
        standardIntent: intent.standardIntent,
        translation: intent.translation,
      };

      if (intent.confidenceLimit) {
        body.confidenceLimit = intent.confidenceLimit;
      }

      if (intent.tags) {
        body.tags = intent.tags;
      }

      const response = await ApiService.post('/schaltzentrale/intent', body);
      return response.data;
    } catch (error) {
      return {};
    }
  },
  updateSuggestion: async function(intent) {
    try {
      const body = {
        uniqueBotId: intent.botId,
        intent: intent.intent,
        forward: intent.forward,
      };

      const response = await ApiService.post(
        '/schaltzentrale/intent/suggestion',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  analyze: async function(botId, fromBox, toBoxes) {
    try {
      const body = {
        uniqueBotId: botId,
        fromBox,
        toBoxes,
      };

      const response = await ApiService.post(
        '/schaltzentrale/intent/analyze',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  addIntent: async function(
    uniqueBotId,
    samples,
    displayName,
    description,
    suggested,
    forward
  ) {
    try {
      const body = {
        uniqueBotId,
        samples,
        displayName,
        description,
        suggested,
        forward,
      };

      const response = await ApiService.post(
        '/schaltzentrale/intent/new',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  addForm: async function(uniqueBotId, displayName, template = 'BotForm') {
    try {
      const body = {
        uniqueBotId,
        displayName,
        template,
      };

      const response = await ApiService.post('/schaltzentrale/forms', body);
      return response.data;
    } catch (error) {
      return {};
    }
  },
  changeIntentSuggestion: async function(uniqueBotId, name) {
    try {
      const body = {
        uniqueBotId,
        name,
      };

      const response = await ApiService.post(
        '/schaltzentrale/intent/suggest',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getTrainingdata: async function(botId, name) {
    try {
      const response = await ApiService.get(
        '/schaltzentrale/trainingdata/' + botId + '/' + name
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getTrainingdataPeriod: async function(botId, dateRange) {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/trainingdata/${botId}/${dateRange[0].format('YYYY-MM-DD')}/${dateRange[1].format('YYYY-MM-DD')}`
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  addTrainingdata: async function(botId, name, trainingdata) {
    try {
      const body = {
        uniqueBotId: botId,
        name,
        trainingdata,
      };
      const response = await ApiService.post(
        '/schaltzentrale/trainingdata',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  deleteTrainingdata: async function(botId, text) {
    try {
      const body = {
        uniqueBotId: botId,
        text,
      };
      const response = await ApiService.delete(
        '/schaltzentrale/trainingdata',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  updateTrainingdata: async function(variant) {
    try {
      const body = {
        uniqueBotId: variant.botId,
        id: variant._id,
        variant,
      };
      const response = await ApiService.post(
        '/schaltzentrale/trainingdata/update',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  updateQualityCheck: async function(variant) {
    try {
      const body = {
        uniqueBotId: variant.botId,
        id: variant._id,
      };
      const response = await ApiService.post(
        '/schaltzentrale/trainingdata/qualitycheck',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  nlpCheck: async function(botId, text, noReferenceMatch = false, noKeywordMatch = false, channelId = null) {
    try {
      const body = {
        uniqueBotId: botId,
        text,
        noReferenceMatch,
        noKeywordMatch,
        channelId
      };

      const response = await ApiService.post('/schaltzentrale/nlp/check', body);
      return response.data;
    } catch (error) {
      return {};
    }
  },
  nlpFeedback: async function(botId, text, feedback, intent) {
    try {
      const body = {
        uniqueBotId: botId,
        text,
        feedback,
        intent,
      };

      const response = await ApiService.post(
        '/schaltzentrale/nlp/feedback',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getNlpFeedbacks: async function(botId, intent) {
    try {
      // Leave intent empty to fetch all aiFeedbacks related to this bot
      const response = await ApiService.get(`/schaltzentrale/nlp/feedback/${botId}/${intent}`);
      return { success: true, data: response.data || [], };
    } catch (error) {
      return { success: false, error };
    }
  },
  /**
   * Delete/withdraw feedback by the feedback record ID
   * @param {string} botId
   * @param {string} id Object ID to the feedback document
   */
  deleteFeedback: async function (botId, id) {
    try {
      const response = await ApiService.delete(
        `/schaltzentrale/nlp/feedback/${botId}/${id}`,
      );
      const data = response.data;
      return { success: data.success ?? false, ...data };
    } catch (error) {
      return { success: false, error };
    }
  },
  getDeployment: async function(botId) {
    try {
      const response = await ApiService.get(
        '/schaltzentrale/deployment/' + botId
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  deploy: async function(botId) {
    try {
      const body = {
        uniqueBotId: botId,
      };
      const response = await ApiService.post(
        '/schaltzentrale/deployment',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  nlpCluster: async function(botId, texts, cluster) {
    try {
      const body = {
        uniqueBotId: botId,
        texts,
        cluster,
      };

      const response = await ApiService.post(
        '/schaltzentrale/nlp/cluster',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  fakeDeploy: async function(botId, lastChangeDate) {
    try {
      const body = {
        uniqueBotId: botId,
        lastChangeDate,
      };
      const response = await ApiService.post(
        '/schaltzentrale/fakeDeployment',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getNLPModels: async function(botId) {
    try {
      const response = await ApiService.get(
        '/schaltzentrale/nlp/model/' + botId
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  deleteNLPModel: async function(botId, modelid) {
    try {
      const response = await ApiService.delete(
        '/schaltzentrale/nlp/model/' + botId + '/' + modelid
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  updateNLPModel: async function(
    botId,
    modelId,
    live,
    staging,
    modelType = 'k1'
  ) {
    try {
      const body = {
        modelId,
        updateStaging: staging,
        updateLive: live,
        modelType,
        stage: 'prod', // change to dev
      };
      const response = await ApiService.put(
        '/schaltzentrale/bot/' + botId + '/model',
        body
      );
      return response;
    } catch (error) {
      return {};
    }
  },
  uploadNLPModel: async function(botId, modelId) {
    try {
      await ApiService.post(
        `/schaltzentrale/nlp/model/download/${botId}/dev/${modelId}`
      );
      await ApiService.post(
        `/schaltzentrale/nlp/model/download/${botId}/cluster/${modelId}`
      );
      const response = await ApiService.post(
        `/schaltzentrale/nlp/model/download/${botId}/prod/${modelId}`
      );
      return response;
    } catch (error) {
      return {};
    }
  },
  addLicence: async function(botId, licence) {
    try {
      const body = {
        licence,
      };
      const response = await ApiService.put(
        '/schaltzentrale/bot/' + botId + '/licence',
        body
      );
      return response;
    } catch (error) {
      return {};
    }
  },
  deleteLicence: async function(botId, licenceStart) {
    try {
      const body = {
        start: licenceStart,
      };
      const response = await ApiService.delete(
        '/schaltzentrale/bot/' + botId + '/licence',
        body
      );
      return response;
    } catch (error) {
      return {};
    }
  },
  cancelLicence: async function(botId, licenceStart, cancel) {
    try {
      const body = {
        start: licenceStart,
        cancel,
      };
      const response = await ApiService.post(
        '/schaltzentrale/bot/' + botId + '/licence/cancel',
        body
      );
      return response;
    } catch (error) {
      return {};
    }
  },
  confirmLicence: async function(botId, licenceStart, confirm) {
    try {
      const body = {
        start: licenceStart,
        confirm,
      };
      const response = await ApiService.post(
        '/schaltzentrale/bot/' + botId + '/licence/confirm',
        body
      );
      return response;
    } catch (error) {
      return {};
    }
  },
  getLicences: async function() {
    try {
      const response = await ApiService.get('/schaltzentrale/licences');
      return response.data;
    } catch (error) {
      return {};
    }
  },
  validateDefaultRules: async function(botId) {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/validator/default/${botId}`
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  fixValidateDefaultRules: async function(botId, version = '1.0.0') {
    try {
      const body = {
        version,
      };
      const response = await ApiService.post(
        `/schaltzentrale/validator/default/${botId}`,
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getWisdomStats: async function(
    from = moment().add(-30, 'days'),
    to = moment().startOf('days')
  ) {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/stats/wisdom/all/${from.format(
          'YYYY-MM-DD'
        )}/${to.format('YYYY-MM-DD')}?sort=aifeedback`
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getBotsAccuracy: async function(botIds) {
    try {
      const response = await ApiService.post(
        '/schaltzentrale/stats/accuracy/all',
        { bots: botIds }
      );
      return response.data;
    } catch (error) {
      return { result: [] };
    }
  },
  deleteIntent: async function(botId, intent) {
    try {
      const response = await ApiService.delete(
        `/schaltzentrale/intent/${botId}/${intent}`
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getKnowledgeBaseItems: async function(locale = 'de', stage = 'demo') {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/cms/knowledge/${locale}/${stage}`
      );
      return response.data.items;
    } catch (error) {
      return {};
    }
  },
  getExpertItems: async function(locale = 'de', stage = 'demo') {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/cms/expert/${locale}/${stage}`
      );
      return response.data.items;
    } catch (error) {
      return {};
    }
  },
  getContacts: async function() {
    try {
      const response = await ApiService.get('/schaltzentrale/cms/contacts');
      return response.data.items;
    } catch (error) {
      return {};
    }
  },
  getContact: async function(id) {
    if (!id) return null;

    try {
      const response = await ApiService.get(
        `/schaltzentrale/cms/contact/${id}`
      );
      return response.data.item;
    } catch (error) {
      return null;
    }
  },
  // DEPRECATED use addIntentFromSource instead
  addSmalltalk: async function(botId, smalltalk) {
    if (!smalltalk) return null;

    try {
      const response = await ApiService.post(
        `/schaltzentrale/intent/add/smalltalk/${botId}`,
        { intentName: smalltalk }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },
  addIntentFromSource: async function(botId, intent, sourceBot) {
    if (!intent || !sourceBot) return null;

    try {
      const response = await ApiService.post(
        `/schaltzentrale/intent/add/smalltalk/${botId}`,
        { intentName: intent, sourceBot },
        120000
      );
      return response.data;
    } catch (error) {
      return null;
    }
  },
  addNewIntent: async function({
    botId,
    samples,
    displayName,
    scenario,
    description,
    template,
  }) {
    try {
      const body = {
        uniqueBotId: botId,
        samples,
        displayName,
        scenario,
        description,
        template,
      };

      const response = await ApiService.post(
        '/schaltzentrale/intent/new/review',
        body
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  setNlpTemplate: async function(botId, nlpTemplate) {
    try {
      const body = {
        displayName: nlpTemplate.displayName,
        templateBotId: nlpTemplate.botId,
        uniqueBotId: botId,
      };

      const response = await ApiService.post(
        '/schaltzentrale/bot/nlpTemplate',
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  generateTrainingdata: async function ({
    botId,
    intentName,
    iterations = 3,
    sampleSize = 10,
    alternateDescription,
    negativeExamples,
  }) {
    try {
      const body = {
        intent: intentName,
        config: {
          translate: false,
          translateBack: false,
          iterations,
          sampleSize,
          numExampleTopics: 1,
          alternateDescription: alternateDescription || null,
          negativeExamples: negativeExamples?.length ? negativeExamples : null,
        },
      };

      const response = await ApiService.post(
        `/schaltzentrale/vgeneration/${botId}`,
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  copyIntent: async function(targetBotId, body) {
    try {
      const response = await ApiService.post(
        `/schaltzentrale/intent/copy/${targetBotId}`,
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  copyChannel: async function(liveBotId, intentName, channelFrom, channelTo) {
    const body = {
      intentName,
      sourceBot: liveBotId,
      targetIntent: intentName,
      sourceChannelId: channelFrom,
      targetChannelId: channelTo,
  }
    try {
      const response = await ApiService.post(
        `/schaltzentrale/intent/copy/channel/${liveBotId}`,
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  setInReview: async function(botId, intent, inReview = false) {
    try {
      const body = {
        name: intent,
        inReview,
        uniqueBotId: botId,
      };
      const response = await ApiService.post(
        `/schaltzentrale/intent/inreview`,
        body
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  training: async function(botId, type = 'reference') {
    try {
      if (!botId) return false;
      
      const response = await ApiService.post(
        `/schaltzentrale/nlp/model/${botId}/training/${type}`
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  translate: async function(text, language, botId) {
    try {
      const response = await ApiService.post(`/schaltzentrale/translate/` + botId, {
        text,
        language,
      })
      return response.data.translation
    } catch(error) {
      // empty
    }
  },
  /**
   * @typedef {Object} K2CheckResult
   * @property {string} label The intent that was detected
   * @property {number} confidence The confidence of the detected intent
   * @property {string} modelId The model ID used for the check
   * @property {?K2Protocol} [protocol] The protocol details of the check.
   * Populated if you request enriched protocol details (`withProtocol: true`)
   */
  /**
   * An indicator that the user's query (`text`) should NOT match the current found intent.
   * This is the flip-side of {@link K2ProtocolFeedbackPair};
   *
   * You find a negative feedback if the LLM detected the current intent as the solution for `text`,
   * but an expert corrected it to not be.
   *
   * "You might think user asks for <this intent>, but it is actually <another>".
   * What `<another>` is in this case is not made known.
   *
   * @typedef {Object} K2ProtocolNegativeFeedback
   * @property {string} text A text that the user asked.
   * This query should NOT find the currently detected intent.
   * @property {string} label The option picked, e.g. "2". Not really useful.
   */
  /**
   * Partial information from an expert's correction of what the right intent is;
   * "You might think the user asks for A, but it is actually B".
   *
   * The correction is included as examples ({@link K2Example}), but you won't find what the original bad match was.
   * E.g., you will find the `text` property and `posLabel` matches an {@link K2Example}'s `text` and `label` property.
   * @typedef {Object} K2ProtocolFeedbackPair
   * @property {string} text The user's query
   * @property {string} posLabel A reference to the correct intent, e.g. "1".
   * You won't find the intent, but it is implicitly the detected intent.
   * @property {string} negLabel A reference to the wrong intent, e.g. "0". Not really useful.
   * You won't find what the original bad match was, only that the question is NOT supposed to be detected as this intent.
   */
  /**
   * @typedef {Object} K2Protocol
   * @property {string} text The input text
   * @property {K2Prompt[]} prompt The prompt details sent to the LLM
   * @property {string} response The response from the model (a short decision indicator)
   * @property {string} callType The type of call made
   * @property {K2TimeTracking} timeTracking The time tracking details
   * @property {K2Intent[]} presentedTopics The a list of topics that were presented to the LLM as candidate options for detecting what the user wanted
   * @property {K2Intent[]} indexIntents The indexed intents (ELABORATION NEEDED)
   * @property {K2Example[]} examples Randomly picked reference variants and positive AI feedbacks to re-enforce the model, among other things.
   * It can also contain the "result" of a manual correction, in which case the object is slightly different.
   * It can also contain the question the user asked originally, and the question was mapped to the currently detected intent as the right answer.
   *
   * @property {null | K2ProtocolFeedbackPair[]} feedbackPairs An expert's corrections to what is the right detection and what is the wrong detection of intent.
   * A feedback pair from this list explicitly tells you that an expert matched a user query to the currently detected intent.
   *
   * @property {null | K2ProtocolNegativeFeedback[]} negFeedbacks Rated as bad matches.
   * These are included in the current intent to explicitly inform the user that "you've told the AI to not find this
   * intent if they ask X question". Can be null if none, or array of {@link K2ProtocolNegativeFeedback}s.
   *
   * @property {null|string} uniqueUserId The unique user ID o the conversation (usually `null` for the playground)
   * @property {string} channelId The channel ID it was asked in
   * @property {null|string} conversationId The conversation ID (usually null in AI Playground)
   * @property {string|null} botContext Bot-wide or channel-specific bot context.
   * Gives the LLM extra information, e.g. "You are an agent for company X...".
   * Not often used, but is either a human's additional instruction to the LLM or `null`.
   */
  /**
   * @typedef {Object} K2Prompt
   * @property {string|'system'|'user'|'assistant'} role The role in the conversation (e.g., system, user)
   * @property {string} content The content of the prompt
   */
  /**
   * @typedef {Object} K2TimeTracking
   * @property {number} k2IntentIndex The time taken for K2 intent indexing
   * @property {number} k2FeedbackIndex The time taken for K2 feedback indexing
   * @property {number} llmCall The time taken for the LLM call
   */
  /**
   * @typedef {Object} K2Intent
   * @property {string} intent The intent name
   * @property {?string} [displayName] The display name of the intent. In very rare cases can be omitted
   * @property {string} description The description of the intent
   * @property {string[]} k2names The K2 names associated with the intent
   * @property {number} score The score of the intent
   * @property {?string} [text]
   */
  /**
   * @typedef {Object} K2Example
   * @property {string} text An example of a user query (can be AI generated or a real question)
   * @property {?string} [intent] The intent associated with the example.
   * Tells you what the right intent would be for the question (`text`).
   * It is not defined if the example is taken from feedback corrections.
   *
   * @property {?string} [label] For positive corrections only. The label of the example. E.g. "0" or "1".
   * You find the intent by matching this label with the `posLabel` in {@link K2ProtocolFeedbackPair},
   * but it's not really useful.
   *
   * @property {?string} [description] The description of the example/user query. May or may no tbe present.
   * Usually not present if the example is a correction.
   */
  /**
   * Perform a K2 index check using a text
   * @param {string} botId
   * @param {string} channelId
   * @param {string} text Your chat input
   * @param {boolean} [withProtocol] Enrich the response with more protocol details
   * @returns {Promise<{} | {status: 'ok'|'error', result: K2CheckResult}>}
   */
  k2check: async function({botId, channelId, text, withProtocol}) {
    try {
      const response = await ApiService.post(`/schaltzentrale/k2/classify/`, {
        text,
        uniqueBotId: botId,
        channelId,
        staging: true,
        returnProtocol: !!withProtocol,
      });
      return response.data;
    } catch (error) {
      return {};
    }
  }
};

export default ProductService;

export { ProductService };
